import React, { useEffect, useState } from "react";
import { useLanguage } from "../languageContext";
import translations from "../translation.json";
import { format } from "date-fns";
import * as XLSX from "xlsx";
const SingleAppointmentsSection = ({
  isDarkMode,
  appointments,
  getDataByDate,
  eventDates,
}) => {
  const { language } = useLanguage();
  const [selectedDate, setSelectedDate] = useState(null);

  useEffect(() => {
    if (appointments && appointments.length > 0) {
      setSelectedDate(appointments[0]?.appointment_date);
    }
  }, [appointments]);

  const exportToExcel = () => {
    const selectedData =
      appointments &&
      appointments.length > 0 &&
      appointments.map((event) => ({
        Name:
          event?.prospective?.first_name + " " + event?.prospective?.last_name,
        Date: event.appointment_date,
        Time: event?.appointment_time || "Null",
        Representative: event?.appointment_rep_user_name || "Null",
        State: event?.confirmation_status || "Null",
        Result: event.visit_status || "Null",
      }));

    const worksheet = XLSX.utils.json_to_sheet(selectedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Selected Weeks Data");

    XLSX.writeFile(
      workbook,
      `Appointments Data ${format(new Date(), "dd MMMM yyyy")}.xlsx`
    );
  };
  

  return (
    <div className="py-2">
      <div className="row align-items-center justify-content-between py-2">
        <div className="col-8 d-flex gap-3 align-items-center">
          <div className="d-flex align-items-center gap-4 w-100">
            <h2
              id="h1"
              className={`${isDarkMode ? "darkthead" : ""}`}
              style={{ minWidth: "max-content" }}
            >
              {translations.eventView.appointments[language]}
            </h2>
            <div className="input-group" style={{ width: "100%" }}>
              <span
                className="input-group-text"
                style={{
                  backgroundColor: isDarkMode ? "transparent" : "",
                  color: isDarkMode ? "#ffffff" : "#000000",
                  border: isDarkMode
                    ? "1px solid #495057"
                    : "1px solid #dee2e6",
                }}
              >
                Date
              </span>
              <select
                class={`form-select ${isDarkMode ? "custom-select" : ""}`}
                value={selectedDate}
                onChange={(e) => {
                  if (e.target.value !== "") {
                    getDataByDate(
                      { appointmentDate: e.target.value },
                      "appointments"
                    );
                  }
                  setSelectedDate(e.target.value);
                }}
                style={{
                  backgroundColor: isDarkMode ? "transparent" : "",
                  color: isDarkMode ? "#ffffff" : "#000000",
                  border: isDarkMode
                    ? "1px solid #495057"
                    : "1px solid #dee2e6",
                }}
              >
                <option
                  value=""
                  style={{
                    background: isDarkMode ? "#1B1F22" : "",
                    color: isDarkMode ? "#ffffff" : "",
                  }}
                >
                  {translations.dropdown.select[language]}
                </option>
                {eventDates &&
                  eventDates.length > 0 &&
                  eventDates.map((date) => (
                    <option
                      key={date.value}
                      value={date.value}
                      style={{
                        background: isDarkMode ? "#1B1F22" : "",
                        color: isDarkMode ? "#ffffff" : "",
                      }}
                    >
                      {date.label}
                    </option>
                  ))}
              </select>
              {/* <input
                type="date"
                className={`form-control`}
                // style={{
                //   backgroundColor: isDarkMode ? "transparent" : "",
                //   color: isDarkMode ? "#ffffff" : "#000000",
                //   border: isDarkMode
                //     ? "1px solid #495057"
                //     : "1px solid #dee2e6",
                // }}
                // value={new Date().toISOString().split("T")[0]}
                onChange={(e) => {
                  getDataByDate(
                    { appointmentDate: e.target.value },
                    "appointments"
                  );
                }}
              /> */}

              {/* <select className="form-select">
                <option selected="">24/10/2024</option>
                <option>25/10/2024</option>
                <option>26/10/2024</option>
              </select> */}
            </div>
          </div>
        </div>
        <div className="col-4 gap-1 d-flex align-items-center justify-content-end">
          <div className="btn-group" role="group" aria-label="Basic example">
            <button
              type="button"
              className="btn admin-btn btn-primary d-flex align-items-center gap-1"
              // data-bs-toggle="modal"
              // data-bs-target="#modalQuickAdd"
              // fdprocessedid="2rud2c"
              onClick={exportToExcel}
            >
              <i className="bi bi-file-earmark-arrow-down-fill"></i>{" "}
              {translations.commonWords.downloadBtn[language]}
            </button>
          </div>
          {/* <div className="btn-group" role="group" aria-label="Basic example">
            <button
              type="button"
              className="btn admin-btn btn-primary d-flex align-items-center gap-1"
              // data-bs-toggle="modal"
              // data-bs-target="#modalQuickAdd"
              // fdprocessedid="2rud2c"
            >
              <i className="bi bi-send-fill"></i>{" "}
              {translations.commonWords.sendBtn[language]}
            </button>
          </div> */}
        </div>
      </div>
      <div className="d-flex gap-2">
        <div
          className="p-2 rounded-1 w-100"
          style={{
            // boxShadow: "0 0 40px rgba(0, 0, 0, 0.15)",
            minHeight: "330px",
            width: "40%",
            background: isDarkMode ? "" : "rgba(243,243,243)",
          }}
        >
          <div
            className=""
            style={{
              display: "flex",
              flexDirection: "column",
              overflowX: "auto",
              maxHeight: "322px",
              minHeight: "322px",
              //   maxHeight: "calc(100vh - 200px)",
            }}
          >
            <table className="align-items-center">
              <thead
                style={{
                  position: "sticky",
                  top: "0",
                  zIndex: "999",
                  background: isDarkMode ? "#37474f" : "rgba(243,243,243)",
                }}
              >
                <tr>
                  <th>
                    <p className="fw-bold">
                      {translations.commonFields.name[language]}{" "}
                    </p>
                  </th>
                  <th>
                    <p className="fw-bold">Date</p>
                  </th>
                  <th>
                    <p className="fw-bold">
                      {translations.eventView.table.time[language]}{" "}
                    </p>
                  </th>
                  <th>
                    <p className="fw-bold">
                      {
                        translations.commonWords.usersTypes.representative[
                          language
                        ]
                      }{" "}
                    </p>
                  </th>
                  <th>
                    <p className="fw-bold">
                      {translations.quickCreationModal.state[language]}{" "}
                    </p>
                  </th>
                  <th>
                    <p className="fw-bold">
                      {translations.eventView.table.result[language]}{" "}
                    </p>
                  </th>
                </tr>
              </thead>
              <tbody className="">
                {appointments &&
                  appointments.length > 0 &&
                  appointments.map((data, index) => (
                    <tr key={index}>
                      <td
                        style={{
                          background: `${
                            index % 2 === 0
                              ? isDarkMode
                                ? "#2B3035"
                                : "#F8F9FA"
                              : isDarkMode
                              ? "#343A3F"
                              : "#E9ECEF"
                          }`,
                        }}
                      >
                        {data?.prospective?.first_name +
                          " " +
                          data?.prospective?.last_name}{" "}
                      </td>
                      <td
                        className=""
                        style={{
                          background: `${
                            index % 2 === 0
                              ? isDarkMode
                                ? "#2B3035"
                                : "#F8F9FA"
                              : isDarkMode
                              ? "#343A3F"
                              : "#E9ECEF"
                          }`,
                        }}
                      >
                        {data?.appointment_date}{" "}
                      </td>
                      <td
                        className=""
                        style={{
                          background: `${
                            index % 2 === 0
                              ? isDarkMode
                                ? "#2B3035"
                                : "#F8F9FA"
                              : isDarkMode
                              ? "#343A3F"
                              : "#E9ECEF"
                          }`,
                        }}
                      >
                        {data?.appointment_time}{" "}
                      </td>
                      <td
                        className=""
                        style={{
                          background: `${
                            index % 2 === 0
                              ? isDarkMode
                                ? "#2B3035"
                                : "#F8F9FA"
                              : isDarkMode
                              ? "#343A3F"
                              : "#E9ECEF"
                          }`,
                        }}
                      >
                        {data.appointment_rep_user_name}{" "}
                      </td>
                      <td
                        className=""
                        style={{
                          background: `${
                            index % 2 === 0
                              ? isDarkMode
                                ? "#2B3035"
                                : "#F8F9FA"
                              : isDarkMode
                              ? "#343A3F"
                              : "#E9ECEF"
                          }`,
                        }}
                      >
                        {data?.confirmation_status}{" "}
                      </td>
                      <td
                        className=""
                        style={{
                          background: `${
                            index % 2 === 0
                              ? isDarkMode
                                ? "#2B3035"
                                : "#F8F9FA"
                              : isDarkMode
                              ? "#343A3F"
                              : "#E9ECEF"
                          }`,
                        }}
                      >
                        {data?.visit_status}{" "}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleAppointmentsSection;
