import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { ApiUrl } from "./ApiUrl";

const useAllUsers = (token, dealerTrigger) => {
  const [users, setUsers] = useState();
  const userData = JSON.parse(localStorage.getItem("userData"));

  useEffect(() => {
    if (token) {
      const getDealerships = async () => {
        try {
          const res = await fetch(ApiUrl + "/api/user/get-all-users", {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          });

          const data = await res.json();

          if (data.status === "success") {
            const usersData = {
              animaters: data.animaters,
              projectMangers: data.projectMangers,
              representatives: data.representatives,
              agents: data.agents,
              appointmentAnimaters: data.appointmentAnimaters,
              appointmentRepresentatives: data.appointmentRepresentatives,
            };
            setUsers(usersData);
          } else {
            toast.error(data.message);
          }
        } catch (error) {
          console.error("Error fetching users", error);
          // Handle error or show a toast if needed
        }
      };
      if (userData && userData.roles[0].name !== "Animator")
      getDealerships();
    }
  }, [token, dealerTrigger]);

  return users;
};

export default useAllUsers;
