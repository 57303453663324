import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { ApiUrl } from "./ApiUrl";
import { useLanguage } from "./languageContext";
import translations from "./translation.json";
import Select from "react-select";
import * as Yup from "yup";
import { ErrorMessage, Field, Form, Formik, isString } from "formik";
import Loader from "./loader";
import Dropzone from "react-dropzone";

const ModalAddDealer_CreateEvent = ({
  dealerships,
  setDealerTrigger = () => {},
  isEdit,
  editID,
  oldData,
  setOldData,
  setEditTrigger = () => {},
  setIsLoading,
  brands,
  setDealerships = () => {},
  isDarkMode,
  repOptions,
  eventNavigate = false,
}) => {
  const brandOptions =
    brands &&
    brands.length > 0 &&
    brands.map((brand) => ({
      value: brand.id,
      label: brand.name,
    }));

  const token = localStorage.getItem("token");
  const [oldLogo, setoldLogo] = useState(
    oldData && oldData.logo ? oldData.logo : ""
  );

  const { language } = useLanguage();
  const [loading, setLoading] = useState(false);

  var button = document.getElementById("edirDealershipClose");

  const initialData = {
    dealership_id: editID,
    name: oldData && oldData.name ? oldData.name : "",
    country: oldData && oldData.country ? oldData.country : "",
    state: oldData && oldData.state ? oldData.state : "",
    city: oldData && oldData.city ? oldData.city : "",
    address: oldData && oldData.address ? oldData.address : "",
    postal_code: oldData && oldData.postal_code ? oldData.postal_code : "",
    timezone: oldData && oldData.timezone ? oldData.timezone : "",
    phone_number: oldData && oldData.phone_number ? oldData.phone_number : "",
    primary_contact:
      oldData && oldData.primary_contact ? oldData.primary_contact : "",
    group_id: oldData && oldData.group_id ? oldData.group_id : "",
    representative_id:
      oldData && oldData.representative_id ? oldData.representative_id : "",
    brand_id: oldData && oldData.brand_id ? oldData.brand_id : "",
    logo: oldData && oldData.logo ? oldData.logo : "",
    crm_link: oldData && oldData.crm_link ? oldData.crm_link : "",
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(
      translations.quickCreationModal.validationsText.dealershipText[language]
    ),
    // logo: oldData
    //   ? Yup.string().required(
    //       translations.quickCreationModal.validationsText.logo[language]
    //     )
    //   : Yup.mixed()
    //       .test(
    //         "fileType",
    //         `${translations.quickCreationModal.validationsText.logoformat[language]}`,
    //         (value) => {
    //           return (
    //             value &&
    //             ["image/jpeg", "image/webp", "image/png", "image/jpg"].includes(
    //               value.type
    //             )
    //           );
    //         }
    //       )
    //       .required(
    //         translations.quickCreationModal.validationsText.logo[language]
    //       ),
    country: Yup.string().required(
      translations.quickCreationModal.validationsText.country[language]
    ),
    // state: Yup.string().required(
    //   translations.quickCreationModal.validationsText.state[language]
    // ),
    // city: Yup.string().required(
    //   translations.quickCreationModal.validationsText.city[language]
    // ),
    // address: Yup.string().required(
    //   translations.quickCreationModal.validationsText.address[language]
    // ),
    // postal_code: Yup.string().required(
    //   translations.forms.validationTranslations.postal_code[language]
    // ),
    // timezone: Yup.string().required(
    //   translations.quickCreationModal.validationsText.timezone[language]
    // ),
    // phone_number: Yup.string().required(
    //   translations.quickCreationModal.validationsText.phoneNumber[language]
    // ),
    // primary_contact: Yup.string().required(
    //   translations.quickCreationModal.validationsText.primaryContact[language]
    // ),
    // group_id: Yup.string().required(
    //   translations.quickCreationModal.validationsText.Group[language]
    // ),
    representative_id: Yup.string().required(
      translations.forms.validationTranslations.representatives[language]
    ),
    brand_id: Yup.string().required(
      translations.quickCreationModal.validationsText.brandText[language]
    ), //.required('Dealership Name is required'),
    // crm_link: Yup.string().required(
    //   translations.quickCreationModal.validationsText.crmLink[language]
    // ),
  });

  const handleSubmit = async (values, { resetForm }, error) => {
    try {
      const formdata = new FormData();
      Object.keys(values).forEach((key) => {
        formdata.append(key, values[key]);
      });
      setIsLoading(true);
      const res = await fetch(ApiUrl + "/api/user/edit-dealership", {
        method: "POST",
        headers: {
          // "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: formdata,
      });
      const data = await res.json();
      if (data.status === "success") {
        button.click();
        resetForm();
        toast.success("Dealership edited successfully");
        if (!eventNavigate) {
          setEditTrigger((prev) => !prev);
          const updatedDealerships = dealerships.map((item) =>
            item.id === editID
              ? {
                  ...item,
                  name: values.name,
                  brand_id: values.brand_id,
                  brand_name: data.list_of_dealership.brand_name,
                  country: values.country,
                  state: values.state,
                  city: values.city,
                  address: values.address,
                  postal_code: values.postal_code,
                  timezone: values.timezone,
                  phone_number: values.phone_number,
                  primary_contact: values.primary_contact,
                  group_id: values.group_id,
                  representative_id: values.representative_id,
                  logo: data.list_of_dealership.logo,
                  crm_link: values.crm_link,
                }
              : item
          );
          setDealerships(updatedDealerships);
        }
        // setDealerTrigger((prevState) => {
        //   return !prevState;
        // });
      } else {
        toast.error("Something went wrong");
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      toast.error("Something went wrong!");
    }
  };
  // const handleDealership = async (event) => {
  //   event.preventDefault();
  //   if (!isEdit) {
  //     try {
  //       setIsLoading(true);
  //       const res = await fetch(
  //         ApiUrl + "/api/user/" + userType + "-create-dealership",
  //         {
  //           method: "POST",
  //           headers: {
  //             "Content-Type": "application/json",
  //             Authorization: `Bearer ${token}`,
  //           },
  //           body: JSON.stringify({
  //             name: dealership.trim(),
  //           }),
  //         }
  //       );
  //       // console.log('Res:', res);
  //       const data = await res.json();
  //       // console.log('data:', data);
  //       if (data.status === "success") {
  //         setDealership("");
  //         toast.success(data.message);
  //         // createDrive(data.delaer_ship_data.id);
  //         setDealerTrigger((prevState) => {
  //           // console.log("dealerTrigger", prevState);
  //           return !prevState;
  //         });
  //         handleCloseModal();

  //         // setDealerships((dealerships) => {
  //         //   console.log("dealerships in add dealership modal: ", dealerships)
  //         //   return [...dealerships, { "name": dealership }]
  //         // })
  //         // setDealerTrigger((previousState) => !previousState)
  //         // setDealerTrigger2((previousState)=> !previousState)
  //       } else {
  //         toast.error(data.message);
  //       }
  //       setIsLoading(false);
  //     } catch (error) {
  //       toast.error("Something went wrong!");
  //     }
  //   } else {
  //     try {
  //       setIsLoading(true);
  //       const res = await fetch(
  //         ApiUrl + "/api/user/" + userType + "-edit-dealership",
  //         {
  //           method: "POST",
  //           headers: {
  //             "Content-Type": "application/json",
  //             Authorization: `Bearer ${token}`,
  //           },
  //           body: JSON.stringify({
  //             dealership_id: editID,
  //             name: dealership.trim(),
  //           }),
  //         }
  //       );
  //       // console.log('Res:', res);
  //       const data = await res.json();
  //       // console.log('data:', data);
  //       if (data.status === "success") {
  //         setEditDealerName(dealership);
  //         setEditTrigger((prev) => !prev);
  //         // Set the updated dealerships array in the state
  //         // setDealerships((dealerships) => dealerships.map((dealer) => {
  //         //   if (dealer.id === editID) {
  //         //     console.log(editDealerName, " ", editID)
  //         //     return { ...dealer, name: editDealerName };
  //         //   }
  //         //   return dealer;
  //         // }));
  //         setDealership("");
  //         toast.success("Dealer edited successfully");
  //         handleCloseModal();

  //         // setDealerships((dealerships) => {
  //         //   console.log("dealerships in add dealership modal: ", dealerships)
  //         //   return [...dealerships, { "name": dealership }]
  //         // })
  //         // setDealerTrigger((previousState) => !previousState)
  //         // setDealerTrigger2((previousState)=> !previousState)
  //       } else {
  //         toast.error(data.message);
  //       }
  //       setIsLoading(false);
  //     } catch (error) {
  //       toast.error("Something went wrong!edittt");
  //     }
  //   }
  // };

  // const createDrive = async (dealerId) => {
  //   try {
  //     const res = await fetch(ApiUrl+'/api/user/createFolder-on-googleDrive', {
  //       method: 'POST',
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${token}`,

  //       },
  //       body: JSON.stringify({
  //         dealer_id: dealerId,
  //         dealer_name: dealership.trim()
  //       })
  //     })
  //     console.log('Res:', res);
  //     const data = await res.json();
  //     console.log('data:', data);
  //     if (data.status === 'success') {

  //     } else {
  //       toast.error(data.message)
  //     }
  //   } catch (error) {
  //     toast.error(error);
  //     console.log("Creating drive link: ", error)
  //   }
  // }

  return (
    <div id="AddDealerModal" class="modal fade" tabindex="-1">
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
          <Formik
            initialValues={initialData}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            id="creationsuccess"
            enableReinitialize={true}
          >
            {({ errors, values, setValues, resetForm, setFieldValue }) => (
              <Form>
                <div
                  class="modal-header"
                  style={{
                    borderBottom: isDarkMode
                      ? "1px solid #495057"
                      : "1px solid #dee2e6",
                  }}
                >
                  <h3 style={{ color: "#000000" }}>
                    🗂{" "}
                    {isEdit
                      ? translations.commonFields.editBtn[language]
                      : translations.commonFields.addBtn[language]}{" "}
                    {translations.commonWords.dealership[language]}{" "}
                  </h3>
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    onClick={() => button.click()}
                    style={{
                      filter: isDarkMode ? "invert(1)" : "none", // Invert the color in dark mode
                      color: isDarkMode
                        ? "#ffffff"
                        : "rgb(255, 255, 255) !important",
                    }}
                    id="closeModalBtn"
                  ></button>
                </div>
                {oldData && (
                  <div
                    className="modal-body"
                    style={{ height: "450px", overflowY: "scroll" }}
                  >
                    <div className="row">
                      <div className="col-6" style={{ paddingRight: "0px" }}>
                        <label for="inputadddealership" class="form-label">
                          {translations["commonWords"].dealership[language]}
                        </label>
                        <div class="mb-3">
                          <Field
                            name="name"
                            type="text"
                            className="form-control"
                            id="inputadddealership"
                            style={{
                              backgroundColor: isDarkMode ? "transparent" : "",
                              color: isDarkMode ? "#ffffff" : "#000000",
                              border: isDarkMode
                                ? "1px solid #495057"
                                : "1px solid #dee2e6",
                            }}
                            placeholder={
                              translations["commonFields"].name[language]
                            }
                          />
                          <ErrorMessage
                            name="name"
                            component="div"
                            className="error text-danger"
                          />
                        </div>
                      </div>
                      <div className="col-6">
                        <label for="inputadddealership" class="form-label">
                          {translations.quickCreationModal.country[language]}
                        </label>
                        <div className="mb-3">
                          {/* <Field
                          name="country"
                          render={({ field, form }) => (
                            <Select
                              className="selectpicker"
                              aria-labelledby="dealership-name-quick"
                              // isClearable
                              // options={packageOptions}
                              placeholder={
                                translations["dropdown"].select[language]
                              }
                              isSearchable
                              // value={
                              //   (packageOptions &&
                              //     packageOptions.length > 0 &&
                              //     packageOptions.find(
                              //       (option) => option.value === field.value
                              //     )) || {
                              //     value: "",
                              //     label:
                              //       translations["dropdown"].select[language],
                              //   }
                              // }
                              onChange={(selectedOption) =>
                                form.setFieldValue(
                                  field.name,
                                  selectedOption.value
                                )
                              }
                              maxMenuHeight={"120px"}
                              styles={{
                                input: (provided) => ({
                                  ...provided,
                                  color: isDarkMode ? "#ffffff" : "black", // Color of the text when typing
                                }),
                                control: (provided, state) => ({
                                  ...provided,
                                  backgroundColor: isDarkMode
                                    ? "transparent"
                                    : "white",
                                  border: state.isFocused
                                    ? "1px solid #80bdff"
                                    : isDarkMode
                                    ? "1px solid #495057"
                                    : "1px solid #dee2e6", // Adjust the border color for focus
                                  boxShadow: state.isFocused
                                    ? "0 0 0 0.2rem rgba(0, 123, 255, 0.25)"
                                    : "none", // Bootstrap's box shadow for focus
                                  borderRadius: ".45rem",
                                }),
                                valueContainer: (provided) => ({
                                  ...provided,
                                  padding: "0.18rem 0.55rem", // Bootstrap's padding for the value container
                                }),
                                indicatorSeparator: (provided) => ({
                                  ...provided,
                                  display: "none", // Hide the indicator separator
                                }),
                                dropdownIndicator: (provided) => ({
                                  ...provided,
                                  padding: "0.375rem 0.75rem", // Bootstrap's padding for the dropdown indicator
                                  color: isDarkMode ? "#ffffff" : "#868686",
                                }),
                                menu: (provided) => ({
                                  ...provided,
                                  color: isDarkMode ? "#ffffff" : "black",
                                  backgroundColor: isDarkMode
                                    ? "#1f2023"
                                    : "white",
                                  margin: "0", // Remove default margin
                                }),
                                placeholder: (provided) => ({
                                  ...provided,
                                  color: isDarkMode ? "#ffffff" : "#333333", // Specify your desired color for the placeholder
                                  // Additional styles for placeholder
                                }),
                                singleValue: (provided) => ({
                                  ...provided,
                                  color: isDarkMode ? "#ffffff" : "black",
                                }),
                                option: (provided, state) => ({
                                  ...provided,
                                  color: isDarkMode ? "#ffffff" : "black",
                                  backgroundColor: state.isHovered
                                    ? "#80bdff"
                                    : isDarkMode
                                    ? "#1f2023"
                                    : "white",
                                  ":hover": {
                                    backgroundColor: isDarkMode
                                      ? "#34DBB4"
                                      : "#34DBB4",
                                  },
                                }),
                              }}
                            />
                          )}
                        /> */}
                          <Field
                            name="country"
                            type="text"
                            className="form-control"
                            id="inputadddealership"
                            style={{
                              backgroundColor: isDarkMode ? "transparent" : "",
                              color: isDarkMode ? "#ffffff" : "#000000",
                              border: isDarkMode
                                ? "1px solid #495057"
                                : "1px solid #dee2e6",
                            }}
                            placeholder={
                              translations.quickCreationModal.country[language]
                            }
                          />
                          <ErrorMessage
                            name="country"
                            component="div"
                            className="error text-danger"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6" style={{ paddingRight: "0px" }}>
                        <label for="inputadddealership" class="form-label">
                          {translations.quickCreationModal.state[language]}
                        </label>
                        <div className="mb-3">
                          {/* <Field
                          name="state"
                          render={({ field, form }) => (
                            <Select
                              className="selectpicker"
                              aria-labelledby="dealership-name-quick"
                              // isClearable
                              // options={packageOptions}
                              placeholder={
                                translations["dropdown"].select[language]
                              }
                              isSearchable
                              // value={
                              //   (packageOptions &&
                              //     packageOptions.length > 0 &&
                              //     packageOptions.find(
                              //       (option) => option.value === field.value
                              //     )) || {
                              //     value: "",
                              //     label:
                              //       translations["dropdown"].select[language],
                              //   }
                              // }
                              onChange={(selectedOption) =>
                                form.setFieldValue(
                                  field.name,
                                  selectedOption.value
                                )
                              }
                              maxMenuHeight={"120px"}
                              styles={{
                                input: (provided) => ({
                                  ...provided,
                                  color: isDarkMode ? "#ffffff" : "black", // Color of the text when typing
                                }),
                                control: (provided, state) => ({
                                  ...provided,
                                  backgroundColor: isDarkMode
                                    ? "transparent"
                                    : "white",
                                  border: state.isFocused
                                    ? "1px solid #80bdff"
                                    : isDarkMode
                                    ? "1px solid #495057"
                                    : "1px solid #dee2e6", // Adjust the border color for focus
                                  boxShadow: state.isFocused
                                    ? "0 0 0 0.2rem rgba(0, 123, 255, 0.25)"
                                    : "none", // Bootstrap's box shadow for focus
                                  borderRadius: ".45rem",
                                }),
                                valueContainer: (provided) => ({
                                  ...provided,
                                  padding: "0.18rem 0.55rem", // Bootstrap's padding for the value container
                                }),
                                indicatorSeparator: (provided) => ({
                                  ...provided,
                                  display: "none", // Hide the indicator separator
                                }),
                                dropdownIndicator: (provided) => ({
                                  ...provided,
                                  padding: "0.375rem 0.75rem", // Bootstrap's padding for the dropdown indicator
                                  color: isDarkMode ? "#ffffff" : "#868686",
                                }),
                                menu: (provided) => ({
                                  ...provided,
                                  color: isDarkMode ? "#ffffff" : "black",
                                  backgroundColor: isDarkMode
                                    ? "#1f2023"
                                    : "white",
                                  margin: "0", // Remove default margin
                                }),
                                placeholder: (provided) => ({
                                  ...provided,
                                  color: isDarkMode ? "#ffffff" : "#333333", // Specify your desired color for the placeholder
                                  // Additional styles for placeholder
                                }),
                                singleValue: (provided) => ({
                                  ...provided,
                                  color: isDarkMode ? "#ffffff" : "black",
                                }),
                                option: (provided, state) => ({
                                  ...provided,
                                  color: isDarkMode ? "#ffffff" : "black",
                                  backgroundColor: state.isHovered
                                    ? "#80bdff"
                                    : isDarkMode
                                    ? "#1f2023"
                                    : "white",
                                  ":hover": {
                                    backgroundColor: isDarkMode
                                      ? "#34DBB4"
                                      : "#34DBB4",
                                  },
                                }),
                              }}
                            />
                          )}
                        /> */}
                          <Field
                            name="state"
                            type="text"
                            className="form-control"
                            id="inputadddealership"
                            style={{
                              backgroundColor: isDarkMode ? "transparent" : "",
                              color: isDarkMode ? "#ffffff" : "#000000",
                              border: isDarkMode
                                ? "1px solid #495057"
                                : "1px solid #dee2e6",
                            }}
                            placeholder={
                              translations.quickCreationModal.state[language]
                            }
                          />
                          <ErrorMessage
                            name="state"
                            component="div"
                            className="error text-danger"
                          />
                        </div>
                      </div>
                      <div className="col-6">
                        <label for="inputadddealership" class="form-label">
                          {translations.quickCreationModal.city[language]}
                        </label>
                        <div className="mb-3">
                          {/* <Field
                          name="city"
                          render={({ field, form }) => (
                            <Select
                              className="selectpicker"
                              aria-labelledby="dealership-name-quick"
                              // isClearable
                              // options={packageOptions}
                              placeholder={
                                translations["dropdown"].select[language]
                              }
                              isSearchable
                              // value={
                              //   (packageOptions &&
                              //     packageOptions.length > 0 &&
                              //     packageOptions.find(
                              //       (option) => option.value === field.value
                              //     )) || {
                              //     value: "",
                              //     label:
                              //       translations["dropdown"].select[language],
                              //   }
                              // }
                              onChange={(selectedOption) =>
                                form.setFieldValue(
                                  field.name,
                                  selectedOption.value
                                )
                              }
                              maxMenuHeight={"120px"}
                              styles={{
                                input: (provided) => ({
                                  ...provided,
                                  color: isDarkMode ? "#ffffff" : "black", // Color of the text when typing
                                }),
                                control: (provided, state) => ({
                                  ...provided,
                                  backgroundColor: isDarkMode
                                    ? "transparent"
                                    : "white",
                                  border: state.isFocused
                                    ? "1px solid #80bdff"
                                    : isDarkMode
                                    ? "1px solid #495057"
                                    : "1px solid #dee2e6", // Adjust the border color for focus
                                  boxShadow: state.isFocused
                                    ? "0 0 0 0.2rem rgba(0, 123, 255, 0.25)"
                                    : "none", // Bootstrap's box shadow for focus
                                  borderRadius: ".45rem",
                                }),
                                valueContainer: (provided) => ({
                                  ...provided,
                                  padding: "0.18rem 0.55rem", // Bootstrap's padding for the value container
                                }),
                                indicatorSeparator: (provided) => ({
                                  ...provided,
                                  display: "none", // Hide the indicator separator
                                }),
                                dropdownIndicator: (provided) => ({
                                  ...provided,
                                  padding: "0.375rem 0.75rem", // Bootstrap's padding for the dropdown indicator
                                  color: isDarkMode ? "#ffffff" : "#868686",
                                }),
                                menu: (provided) => ({
                                  ...provided,
                                  color: isDarkMode ? "#ffffff" : "black",
                                  backgroundColor: isDarkMode
                                    ? "#1f2023"
                                    : "white",
                                  margin: "0", // Remove default margin
                                }),
                                placeholder: (provided) => ({
                                  ...provided,
                                  color: isDarkMode ? "#ffffff" : "#333333", // Specify your desired color for the placeholder
                                  // Additional styles for placeholder
                                }),
                                singleValue: (provided) => ({
                                  ...provided,
                                  color: isDarkMode ? "#ffffff" : "black",
                                }),
                                option: (provided, state) => ({
                                  ...provided,
                                  color: isDarkMode ? "#ffffff" : "black",
                                  backgroundColor: state.isHovered
                                    ? "#80bdff"
                                    : isDarkMode
                                    ? "#1f2023"
                                    : "white",
                                  ":hover": {
                                    backgroundColor: isDarkMode
                                      ? "#34DBB4"
                                      : "#34DBB4",
                                  },
                                }),
                              }}
                            />
                          )}
                        /> */}
                          <Field
                            name="city"
                            type="text"
                            className="form-control"
                            id="inputadddealership"
                            style={{
                              backgroundColor: isDarkMode ? "transparent" : "",
                              color: isDarkMode ? "#ffffff" : "#000000",
                              border: isDarkMode
                                ? "1px solid #495057"
                                : "1px solid #dee2e6",
                            }}
                            placeholder={
                              translations.quickCreationModal.city[language]
                            }
                          />
                          <ErrorMessage
                            name="city"
                            component="div"
                            className="error text-danger"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6" style={{ paddingRight: "0px" }}>
                        <label for="inputadddealership" class="form-label">
                          {translations.quickCreationModal.address[language]}
                        </label>
                        <div className="mb-3">
                          {/* <Field
                          name="city"
                          render={({ field, form }) => (
                            <Select
                              className="selectpicker"
                              aria-labelledby="dealership-name-quick"
                              // isClearable
                              // options={packageOptions}
                              placeholder={
                                translations["dropdown"].select[language]
                              }
                              isSearchable
                              // value={
                              //   (packageOptions &&
                              //     packageOptions.length > 0 &&
                              //     packageOptions.find(
                              //       (option) => option.value === field.value
                              //     )) || {
                              //     value: "",
                              //     label:
                              //       translations["dropdown"].select[language],
                              //   }
                              // }
                              onChange={(selectedOption) =>
                                form.setFieldValue(
                                  field.name,
                                  selectedOption.value
                                )
                              }
                              maxMenuHeight={"120px"}
                              styles={{
                                input: (provided) => ({
                                  ...provided,
                                  color: isDarkMode ? "#ffffff" : "black", // Color of the text when typing
                                }),
                                control: (provided, state) => ({
                                  ...provided,
                                  backgroundColor: isDarkMode
                                    ? "transparent"
                                    : "white",
                                  border: state.isFocused
                                    ? "1px solid #80bdff"
                                    : isDarkMode
                                    ? "1px solid #495057"
                                    : "1px solid #dee2e6", // Adjust the border color for focus
                                  boxShadow: state.isFocused
                                    ? "0 0 0 0.2rem rgba(0, 123, 255, 0.25)"
                                    : "none", // Bootstrap's box shadow for focus
                                  borderRadius: ".45rem",
                                }),
                                valueContainer: (provided) => ({
                                  ...provided,
                                  padding: "0.18rem 0.55rem", // Bootstrap's padding for the value container
                                }),
                                indicatorSeparator: (provided) => ({
                                  ...provided,
                                  display: "none", // Hide the indicator separator
                                }),
                                dropdownIndicator: (provided) => ({
                                  ...provided,
                                  padding: "0.375rem 0.75rem", // Bootstrap's padding for the dropdown indicator
                                  color: isDarkMode ? "#ffffff" : "#868686",
                                }),
                                menu: (provided) => ({
                                  ...provided,
                                  color: isDarkMode ? "#ffffff" : "black",
                                  backgroundColor: isDarkMode
                                    ? "#1f2023"
                                    : "white",
                                  margin: "0", // Remove default margin
                                }),
                                placeholder: (provided) => ({
                                  ...provided,
                                  color: isDarkMode ? "#ffffff" : "#333333", // Specify your desired color for the placeholder
                                  // Additional styles for placeholder
                                }),
                                singleValue: (provided) => ({
                                  ...provided,
                                  color: isDarkMode ? "#ffffff" : "black",
                                }),
                                option: (provided, state) => ({
                                  ...provided,
                                  color: isDarkMode ? "#ffffff" : "black",
                                  backgroundColor: state.isHovered
                                    ? "#80bdff"
                                    : isDarkMode
                                    ? "#1f2023"
                                    : "white",
                                  ":hover": {
                                    backgroundColor: isDarkMode
                                      ? "#34DBB4"
                                      : "#34DBB4",
                                  },
                                }),
                              }}
                            />
                          )}
                        /> */}
                          <Field
                            name="address"
                            type="text"
                            className="form-control"
                            id="inputadddealership"
                            style={{
                              backgroundColor: isDarkMode ? "transparent" : "",
                              color: isDarkMode ? "#ffffff" : "#000000",
                              border: isDarkMode
                                ? "1px solid #495057"
                                : "1px solid #dee2e6",
                            }}
                            placeholder={
                              translations.quickCreationModal.address[language]
                            }
                          />
                          <ErrorMessage
                            name="address"
                            component="div"
                            className="error text-danger"
                          />
                        </div>
                      </div>
                      <div className="col-6">
                        <label for="inputadddealership" class="form-label">
                          {
                            translations.forms.formFields.conquestSection
                              .postalCode[language]
                          }
                        </label>
                        <div className="mb-3">
                          {/* <Field
                          name="state"
                          render={({ field, form }) => (
                            <Select
                              className="selectpicker"
                              aria-labelledby="dealership-name-quick"
                              // isClearable
                              // options={packageOptions}
                              placeholder={
                                translations["dropdown"].select[language]
                              }
                              isSearchable
                              // value={
                              //   (packageOptions &&
                              //     packageOptions.length > 0 &&
                              //     packageOptions.find(
                              //       (option) => option.value === field.value
                              //     )) || {
                              //     value: "",
                              //     label:
                              //       translations["dropdown"].select[language],
                              //   }
                              // }
                              onChange={(selectedOption) =>
                                form.setFieldValue(
                                  field.name,
                                  selectedOption.value
                                )
                              }
                              maxMenuHeight={"120px"}
                              styles={{
                                input: (provided) => ({
                                  ...provided,
                                  color: isDarkMode ? "#ffffff" : "black", // Color of the text when typing
                                }),
                                control: (provided, state) => ({
                                  ...provided,
                                  backgroundColor: isDarkMode
                                    ? "transparent"
                                    : "white",
                                  border: state.isFocused
                                    ? "1px solid #80bdff"
                                    : isDarkMode
                                    ? "1px solid #495057"
                                    : "1px solid #dee2e6", // Adjust the border color for focus
                                  boxShadow: state.isFocused
                                    ? "0 0 0 0.2rem rgba(0, 123, 255, 0.25)"
                                    : "none", // Bootstrap's box shadow for focus
                                  borderRadius: ".45rem",
                                }),
                                valueContainer: (provided) => ({
                                  ...provided,
                                  padding: "0.18rem 0.55rem", // Bootstrap's padding for the value container
                                }),
                                indicatorSeparator: (provided) => ({
                                  ...provided,
                                  display: "none", // Hide the indicator separator
                                }),
                                dropdownIndicator: (provided) => ({
                                  ...provided,
                                  padding: "0.375rem 0.75rem", // Bootstrap's padding for the dropdown indicator
                                  color: isDarkMode ? "#ffffff" : "#868686",
                                }),
                                menu: (provided) => ({
                                  ...provided,
                                  color: isDarkMode ? "#ffffff" : "black",
                                  backgroundColor: isDarkMode
                                    ? "#1f2023"
                                    : "white",
                                  margin: "0", // Remove default margin
                                }),
                                placeholder: (provided) => ({
                                  ...provided,
                                  color: isDarkMode ? "#ffffff" : "#333333", // Specify your desired color for the placeholder
                                  // Additional styles for placeholder
                                }),
                                singleValue: (provided) => ({
                                  ...provided,
                                  color: isDarkMode ? "#ffffff" : "black",
                                }),
                                option: (provided, state) => ({
                                  ...provided,
                                  color: isDarkMode ? "#ffffff" : "black",
                                  backgroundColor: state.isHovered
                                    ? "#80bdff"
                                    : isDarkMode
                                    ? "#1f2023"
                                    : "white",
                                  ":hover": {
                                    backgroundColor: isDarkMode
                                      ? "#34DBB4"
                                      : "#34DBB4",
                                  },
                                }),
                              }}
                            />
                          )}
                        /> */}
                          <Field
                            name="postal_code"
                            type="text"
                            className="form-control"
                            id="inputadddealership"
                            style={{
                              backgroundColor: isDarkMode ? "transparent" : "",
                              color: isDarkMode ? "#ffffff" : "#000000",
                              border: isDarkMode
                                ? "1px solid #495057"
                                : "1px solid #dee2e6",
                            }}
                            placeholder={
                              translations.forms.formFields.conquestSection
                                .postalCode[language]
                            }
                          />
                          <ErrorMessage
                            name="postal_code"
                            component="div"
                            className="error text-danger"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6" style={{ paddingRight: "0px" }}>
                        <label for="inputadddealership" class="form-label">
                          {translations.quickCreationModal.timezone[language]}
                        </label>
                        <div className="mb-3">
                          {/* <Field
                          name="city"
                          render={({ field, form }) => (
                            <Select
                              className="selectpicker"
                              aria-labelledby="dealership-name-quick"
                              // isClearable
                              // options={packageOptions}
                              placeholder={
                                translations["dropdown"].select[language]
                              }
                              isSearchable
                              // value={
                              //   (packageOptions &&
                              //     packageOptions.length > 0 &&
                              //     packageOptions.find(
                              //       (option) => option.value === field.value
                              //     )) || {
                              //     value: "",
                              //     label:
                              //       translations["dropdown"].select[language],
                              //   }
                              // }
                              onChange={(selectedOption) =>
                                form.setFieldValue(
                                  field.name,
                                  selectedOption.value
                                )
                              }
                              maxMenuHeight={"120px"}
                              styles={{
                                input: (provided) => ({
                                  ...provided,
                                  color: isDarkMode ? "#ffffff" : "black", // Color of the text when typing
                                }),
                                control: (provided, state) => ({
                                  ...provided,
                                  backgroundColor: isDarkMode
                                    ? "transparent"
                                    : "white",
                                  border: state.isFocused
                                    ? "1px solid #80bdff"
                                    : isDarkMode
                                    ? "1px solid #495057"
                                    : "1px solid #dee2e6", // Adjust the border color for focus
                                  boxShadow: state.isFocused
                                    ? "0 0 0 0.2rem rgba(0, 123, 255, 0.25)"
                                    : "none", // Bootstrap's box shadow for focus
                                  borderRadius: ".45rem",
                                }),
                                valueContainer: (provided) => ({
                                  ...provided,
                                  padding: "0.18rem 0.55rem", // Bootstrap's padding for the value container
                                }),
                                indicatorSeparator: (provided) => ({
                                  ...provided,
                                  display: "none", // Hide the indicator separator
                                }),
                                dropdownIndicator: (provided) => ({
                                  ...provided,
                                  padding: "0.375rem 0.75rem", // Bootstrap's padding for the dropdown indicator
                                  color: isDarkMode ? "#ffffff" : "#868686",
                                }),
                                menu: (provided) => ({
                                  ...provided,
                                  color: isDarkMode ? "#ffffff" : "black",
                                  backgroundColor: isDarkMode
                                    ? "#1f2023"
                                    : "white",
                                  margin: "0", // Remove default margin
                                }),
                                placeholder: (provided) => ({
                                  ...provided,
                                  color: isDarkMode ? "#ffffff" : "#333333", // Specify your desired color for the placeholder
                                  // Additional styles for placeholder
                                }),
                                singleValue: (provided) => ({
                                  ...provided,
                                  color: isDarkMode ? "#ffffff" : "black",
                                }),
                                option: (provided, state) => ({
                                  ...provided,
                                  color: isDarkMode ? "#ffffff" : "black",
                                  backgroundColor: state.isHovered
                                    ? "#80bdff"
                                    : isDarkMode
                                    ? "#1f2023"
                                    : "white",
                                  ":hover": {
                                    backgroundColor: isDarkMode
                                      ? "#34DBB4"
                                      : "#34DBB4",
                                  },
                                }),
                              }}
                            />
                          )}
                        /> */}
                          <Field
                            name="timezone"
                            type="text"
                            className="form-control"
                            id="inputadddealership"
                            style={{
                              backgroundColor: isDarkMode ? "transparent" : "",
                              color: isDarkMode ? "#ffffff" : "#000000",
                              border: isDarkMode
                                ? "1px solid #495057"
                                : "1px solid #dee2e6",
                            }}
                            placeholder={
                              translations.quickCreationModal.timezone[language]
                            }
                          />
                          <ErrorMessage
                            name="timezone"
                            component="div"
                            className="error text-danger"
                          />
                        </div>
                      </div>
                      <div className="col-6">
                        <label for="inputadddealership" class="form-label">
                          {
                            translations.quickCreationModal.phoneNumber[
                              language
                            ]
                          }
                        </label>
                        <div className="mb-3">
                          {/* <Field
                          name="state"
                          render={({ field, form }) => (
                            <Select
                              className="selectpicker"
                              aria-labelledby="dealership-name-quick"
                              // isClearable
                              // options={packageOptions}
                              placeholder={
                                translations["dropdown"].select[language]
                              }
                              isSearchable
                              // value={
                              //   (packageOptions &&
                              //     packageOptions.length > 0 &&
                              //     packageOptions.find(
                              //       (option) => option.value === field.value
                              //     )) || {
                              //     value: "",
                              //     label:
                              //       translations["dropdown"].select[language],
                              //   }
                              // }
                              onChange={(selectedOption) =>
                                form.setFieldValue(
                                  field.name,
                                  selectedOption.value
                                )
                              }
                              maxMenuHeight={"120px"}
                              styles={{
                                input: (provided) => ({
                                  ...provided,
                                  color: isDarkMode ? "#ffffff" : "black", // Color of the text when typing
                                }),
                                control: (provided, state) => ({
                                  ...provided,
                                  backgroundColor: isDarkMode
                                    ? "transparent"
                                    : "white",
                                  border: state.isFocused
                                    ? "1px solid #80bdff"
                                    : isDarkMode
                                    ? "1px solid #495057"
                                    : "1px solid #dee2e6", // Adjust the border color for focus
                                  boxShadow: state.isFocused
                                    ? "0 0 0 0.2rem rgba(0, 123, 255, 0.25)"
                                    : "none", // Bootstrap's box shadow for focus
                                  borderRadius: ".45rem",
                                }),
                                valueContainer: (provided) => ({
                                  ...provided,
                                  padding: "0.18rem 0.55rem", // Bootstrap's padding for the value container
                                }),
                                indicatorSeparator: (provided) => ({
                                  ...provided,
                                  display: "none", // Hide the indicator separator
                                }),
                                dropdownIndicator: (provided) => ({
                                  ...provided,
                                  padding: "0.375rem 0.75rem", // Bootstrap's padding for the dropdown indicator
                                  color: isDarkMode ? "#ffffff" : "#868686",
                                }),
                                menu: (provided) => ({
                                  ...provided,
                                  color: isDarkMode ? "#ffffff" : "black",
                                  backgroundColor: isDarkMode
                                    ? "#1f2023"
                                    : "white",
                                  margin: "0", // Remove default margin
                                }),
                                placeholder: (provided) => ({
                                  ...provided,
                                  color: isDarkMode ? "#ffffff" : "#333333", // Specify your desired color for the placeholder
                                  // Additional styles for placeholder
                                }),
                                singleValue: (provided) => ({
                                  ...provided,
                                  color: isDarkMode ? "#ffffff" : "black",
                                }),
                                option: (provided, state) => ({
                                  ...provided,
                                  color: isDarkMode ? "#ffffff" : "black",
                                  backgroundColor: state.isHovered
                                    ? "#80bdff"
                                    : isDarkMode
                                    ? "#1f2023"
                                    : "white",
                                  ":hover": {
                                    backgroundColor: isDarkMode
                                      ? "#34DBB4"
                                      : "#34DBB4",
                                  },
                                }),
                              }}
                            />
                          )}
                        /> */}
                          <Field
                            name="phone_number"
                            type="text"
                            className="form-control"
                            id="inputadddealership"
                            style={{
                              backgroundColor: isDarkMode ? "transparent" : "",
                              color: isDarkMode ? "#ffffff" : "#000000",
                              border: isDarkMode
                                ? "1px solid #495057"
                                : "1px solid #dee2e6",
                            }}
                            placeholder={
                              translations.quickCreationModal.phoneNumber[
                                language
                              ]
                            }
                          />
                          <ErrorMessage
                            name="phone_number"
                            component="div"
                            className="error text-danger"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6" style={{ paddingRight: "0px" }}>
                        <label class="form-label">
                          {" "}
                          {translations.quickCreationModal.brand[language]}
                        </label>
                        <div className="mb-3">
                          <div class="">
                            <div>
                              <Field
                                name="brand_id"
                                render={({ field, form }) => (
                                  <Select
                                    className="selectpicker"
                                    aria-labelledby="dealership-name-quick"
                                    // isClearable
                                    options={brandOptions}
                                    placeholder={
                                      translations["dropdown"].select[language]
                                    }
                                    isSearchable
                                    value={
                                      (brandOptions &&
                                        brandOptions.length > 0 &&
                                        brandOptions.find(
                                          (option) =>
                                            option.value === field.value
                                        )) || {
                                        value: "",
                                        label:
                                          translations["dropdown"].select[
                                            language
                                          ],
                                      }
                                    }
                                    onChange={(selectedOption) => {
                                      form.setFieldValue(
                                        field.name,
                                        selectedOption.value
                                      );
                                    }}
                                    maxMenuHeight={"120px"}
                                    styles={{
                                      input: (provided) => ({
                                        ...provided,
                                        color: isDarkMode ? "#ffffff" : "black", // Color of the text when typing
                                      }),
                                      control: (provided, state) => ({
                                        ...provided,
                                        backgroundColor: isDarkMode
                                          ? "transparent"
                                          : "white",
                                        border: state.isFocused
                                          ? "1px solid #80bdff"
                                          : isDarkMode
                                          ? "1px solid #495057"
                                          : "1px solid #dee2e6", // Adjust the border color for focus
                                        boxShadow: state.isFocused
                                          ? "0 0 0 0.2rem rgba(0, 123, 255, 0.25)"
                                          : "none", // Bootstrap's box shadow for focus
                                        borderRadius: ".45rem",
                                      }),
                                      valueContainer: (provided) => ({
                                        ...provided,
                                        padding: "0.18rem 0.55rem", // Bootstrap's padding for the value container
                                      }),
                                      indicatorSeparator: (provided) => ({
                                        ...provided,
                                        display: "none", // Hide the indicator separator
                                      }),
                                      dropdownIndicator: (provided) => ({
                                        ...provided,
                                        padding: "0.375rem 0.75rem", // Bootstrap's padding for the dropdown indicator
                                        color: isDarkMode
                                          ? "#ffffff"
                                          : "#868686",
                                      }),
                                      menu: (provided) => ({
                                        ...provided,
                                        color: isDarkMode ? "#ffffff" : "black",
                                        backgroundColor: isDarkMode
                                          ? "#1f2023"
                                          : "white",
                                        margin: "0", // Remove default margin
                                      }),
                                      placeholder: (provided) => ({
                                        ...provided,
                                        color: isDarkMode
                                          ? "#ffffff"
                                          : "#333333", // Specify your desired color for the placeholder
                                        // Additional styles for placeholder
                                      }),
                                      singleValue: (provided) => ({
                                        ...provided,
                                        color: isDarkMode ? "#ffffff" : "black",
                                      }),
                                      option: (provided, state) => ({
                                        ...provided,
                                        color: isDarkMode ? "#ffffff" : "black",
                                        backgroundColor: state.isHovered
                                          ? "#80bdff"
                                          : isDarkMode
                                          ? "#1f2023"
                                          : "white",
                                        ":hover": {
                                          backgroundColor: isDarkMode
                                            ? "#48cae4"
                                            : "#48cae4",
                                        },
                                      }),
                                    }}
                                  />
                                )}
                              />
                              <ErrorMessage
                                name="brand_id"
                                component="div"
                                className="error text-danger"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-6">
                        <label for="inputadddealership" class="form-label">
                          {translations.quickCreationModal.group[language]}
                        </label>
                        <div className="mb-3">
                          {/* <Field
                          name="state"
                          render={({ field, form }) => (
                            <Select
                              className="selectpicker"
                              aria-labelledby="dealership-name-quick"
                              // isClearable
                              // options={packageOptions}
                              placeholder={
                                translations["dropdown"].select[language]
                              }
                              isSearchable
                              // value={
                              //   (packageOptions &&
                              //     packageOptions.length > 0 &&
                              //     packageOptions.find(
                              //       (option) => option.value === field.value
                              //     )) || {
                              //     value: "",
                              //     label:
                              //       translations["dropdown"].select[language],
                              //   }
                              // }
                              onChange={(selectedOption) =>
                                form.setFieldValue(
                                  field.name,
                                  selectedOption.value
                                )
                              }
                              maxMenuHeight={"120px"}
                              styles={{
                                input: (provided) => ({
                                  ...provided,
                                  color: isDarkMode ? "#ffffff" : "black", // Color of the text when typing
                                }),
                                control: (provided, state) => ({
                                  ...provided,
                                  backgroundColor: isDarkMode
                                    ? "transparent"
                                    : "white",
                                  border: state.isFocused
                                    ? "1px solid #80bdff"
                                    : isDarkMode
                                    ? "1px solid #495057"
                                    : "1px solid #dee2e6", // Adjust the border color for focus
                                  boxShadow: state.isFocused
                                    ? "0 0 0 0.2rem rgba(0, 123, 255, 0.25)"
                                    : "none", // Bootstrap's box shadow for focus
                                  borderRadius: ".45rem",
                                }),
                                valueContainer: (provided) => ({
                                  ...provided,
                                  padding: "0.18rem 0.55rem", // Bootstrap's padding for the value container
                                }),
                                indicatorSeparator: (provided) => ({
                                  ...provided,
                                  display: "none", // Hide the indicator separator
                                }),
                                dropdownIndicator: (provided) => ({
                                  ...provided,
                                  padding: "0.375rem 0.75rem", // Bootstrap's padding for the dropdown indicator
                                  color: isDarkMode ? "#ffffff" : "#868686",
                                }),
                                menu: (provided) => ({
                                  ...provided,
                                  color: isDarkMode ? "#ffffff" : "black",
                                  backgroundColor: isDarkMode
                                    ? "#1f2023"
                                    : "white",
                                  margin: "0", // Remove default margin
                                }),
                                placeholder: (provided) => ({
                                  ...provided,
                                  color: isDarkMode ? "#ffffff" : "#333333", // Specify your desired color for the placeholder
                                  // Additional styles for placeholder
                                }),
                                singleValue: (provided) => ({
                                  ...provided,
                                  color: isDarkMode ? "#ffffff" : "black",
                                }),
                                option: (provided, state) => ({
                                  ...provided,
                                  color: isDarkMode ? "#ffffff" : "black",
                                  backgroundColor: state.isHovered
                                    ? "#80bdff"
                                    : isDarkMode
                                    ? "#1f2023"
                                    : "white",
                                  ":hover": {
                                    backgroundColor: isDarkMode
                                      ? "#34DBB4"
                                      : "#34DBB4",
                                  },
                                }),
                              }}
                            />
                          )}
                        /> */}
                          <Field
                            name="group_id"
                            type="text"
                            className="form-control"
                            id="inputadddealership"
                            style={{
                              backgroundColor: isDarkMode ? "transparent" : "",
                              color: isDarkMode ? "#ffffff" : "#000000",
                              border: isDarkMode
                                ? "1px solid #495057"
                                : "1px solid #dee2e6",
                            }}
                            placeholder={
                              translations.quickCreationModal.group[language]
                            }
                          />
                          <ErrorMessage
                            name="group_id"
                            component="div"
                            className="error text-danger"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6" style={{ paddingRight: "0px" }}>
                        <label class="form-label">
                          {" "}
                          {
                            translations.forms.formFields.representative[
                              language
                            ]
                          }
                        </label>
                        <div className="mb-3">
                          <Field
                            name="representative_id"
                            render={({ field, form }) => (
                              <Select
                                className="selectpicker"
                                aria-labelledby="dealership-name-quick"
                                // isClearable
                                options={repOptions}
                                placeholder={
                                  translations["dropdown"].select[language]
                                }
                                isSearchable
                                value={
                                  (repOptions &&
                                    repOptions.length > 0 &&
                                    repOptions.find(
                                      (option) => option.value === field.value
                                    )) || {
                                    value: "",
                                    label:
                                      translations["dropdown"].select[language],
                                  }
                                }
                                onChange={(selectedOption) =>
                                  form.setFieldValue(
                                    field.name,
                                    selectedOption.value
                                  )
                                }
                                maxMenuHeight={"120px"}
                                styles={{
                                  input: (provided) => ({
                                    ...provided,
                                    color: isDarkMode ? "#ffffff" : "black", // Color of the text when typing
                                  }),
                                  control: (provided, state) => ({
                                    ...provided,
                                    backgroundColor: isDarkMode
                                      ? "transparent"
                                      : "white",
                                    border: state.isFocused
                                      ? "1px solid #80bdff"
                                      : isDarkMode
                                      ? "1px solid #495057"
                                      : "1px solid #dee2e6", // Adjust the border color for focus
                                    boxShadow: state.isFocused
                                      ? "0 0 0 0.2rem rgba(0, 123, 255, 0.25)"
                                      : "none", // Bootstrap's box shadow for focus
                                    borderRadius: ".45rem",
                                  }),
                                  valueContainer: (provided) => ({
                                    ...provided,
                                    padding: "0.18rem 0.55rem", // Bootstrap's padding for the value container
                                  }),
                                  indicatorSeparator: (provided) => ({
                                    ...provided,
                                    display: "none", // Hide the indicator separator
                                  }),
                                  dropdownIndicator: (provided) => ({
                                    ...provided,
                                    padding: "0.375rem 0.75rem", // Bootstrap's padding for the dropdown indicator
                                    color: isDarkMode ? "#ffffff" : "#868686",
                                  }),
                                  menu: (provided) => ({
                                    ...provided,
                                    color: isDarkMode ? "#ffffff" : "black",
                                    backgroundColor: isDarkMode
                                      ? "#1f2023"
                                      : "white",
                                    margin: "0", // Remove default margin
                                  }),
                                  placeholder: (provided) => ({
                                    ...provided,
                                    color: isDarkMode ? "#ffffff" : "#333333", // Specify your desired color for the placeholder
                                    // Additional styles for placeholder
                                  }),
                                  singleValue: (provided) => ({
                                    ...provided,
                                    color: isDarkMode ? "#ffffff" : "black",
                                  }),
                                  option: (provided, state) => ({
                                    ...provided,
                                    color: isDarkMode ? "#ffffff" : "black",
                                    backgroundColor: state.isHovered
                                      ? "#80bdff"
                                      : isDarkMode
                                      ? "#1f2023"
                                      : "white",
                                    ":hover": {
                                      backgroundColor: isDarkMode
                                        ? "#48cae4"
                                        : "#48cae4",
                                    },
                                  }),
                                }}
                              />
                            )}
                          />
                          <ErrorMessage
                            name="representative_id"
                            component="div"
                            className="error text-danger"
                          />
                        </div>
                      </div>
                      <div className="col-6">
                        <label for="inputadddealership" class="form-label">
                          {
                            translations.quickCreationModal.primaryContact[
                              language
                            ]
                          }
                        </label>
                        <div className="mb-3">
                          {/* <Field
                          name="state"
                          render={({ field, form }) => (
                            <Select
                              className="selectpicker"
                              aria-labelledby="dealership-name-quick"
                              // isClearable
                              // options={packageOptions}
                              placeholder={
                                translations["dropdown"].select[language]
                              }
                              isSearchable
                              // value={
                              //   (packageOptions &&
                              //     packageOptions.length > 0 &&
                              //     packageOptions.find(
                              //       (option) => option.value === field.value
                              //     )) || {
                              //     value: "",
                              //     label:
                              //       translations["dropdown"].select[language],
                              //   }
                              // }
                              onChange={(selectedOption) =>
                                form.setFieldValue(
                                  field.name,
                                  selectedOption.value
                                )
                              }
                              maxMenuHeight={"120px"}
                              styles={{
                                input: (provided) => ({
                                  ...provided,
                                  color: isDarkMode ? "#ffffff" : "black", // Color of the text when typing
                                }),
                                control: (provided, state) => ({
                                  ...provided,
                                  backgroundColor: isDarkMode
                                    ? "transparent"
                                    : "white",
                                  border: state.isFocused
                                    ? "1px solid #80bdff"
                                    : isDarkMode
                                    ? "1px solid #495057"
                                    : "1px solid #dee2e6", // Adjust the border color for focus
                                  boxShadow: state.isFocused
                                    ? "0 0 0 0.2rem rgba(0, 123, 255, 0.25)"
                                    : "none", // Bootstrap's box shadow for focus
                                  borderRadius: ".45rem",
                                }),
                                valueContainer: (provided) => ({
                                  ...provided,
                                  padding: "0.18rem 0.55rem", // Bootstrap's padding for the value container
                                }),
                                indicatorSeparator: (provided) => ({
                                  ...provided,
                                  display: "none", // Hide the indicator separator
                                }),
                                dropdownIndicator: (provided) => ({
                                  ...provided,
                                  padding: "0.375rem 0.75rem", // Bootstrap's padding for the dropdown indicator
                                  color: isDarkMode ? "#ffffff" : "#868686",
                                }),
                                menu: (provided) => ({
                                  ...provided,
                                  color: isDarkMode ? "#ffffff" : "black",
                                  backgroundColor: isDarkMode
                                    ? "#1f2023"
                                    : "white",
                                  margin: "0", // Remove default margin
                                }),
                                placeholder: (provided) => ({
                                  ...provided,
                                  color: isDarkMode ? "#ffffff" : "#333333", // Specify your desired color for the placeholder
                                  // Additional styles for placeholder
                                }),
                                singleValue: (provided) => ({
                                  ...provided,
                                  color: isDarkMode ? "#ffffff" : "black",
                                }),
                                option: (provided, state) => ({
                                  ...provided,
                                  color: isDarkMode ? "#ffffff" : "black",
                                  backgroundColor: state.isHovered
                                    ? "#80bdff"
                                    : isDarkMode
                                    ? "#1f2023"
                                    : "white",
                                  ":hover": {
                                    backgroundColor: isDarkMode
                                      ? "#34DBB4"
                                      : "#34DBB4",
                                  },
                                }),
                              }}
                            />
                          )}
                        /> */}
                          <Field
                            name="primary_contact"
                            type="text"
                            className="form-control"
                            id="inputadddealership"
                            style={{
                              backgroundColor: isDarkMode ? "transparent" : "",
                              color: isDarkMode ? "#ffffff" : "#000000",
                              border: isDarkMode
                                ? "1px solid #495057"
                                : "1px solid #dee2e6",
                            }}
                            placeholder={
                              translations.quickCreationModal.primaryContact[
                                language
                              ]
                            }
                          />
                          <ErrorMessage
                            name="primary_contact"
                            component="div"
                            className="error text-danger"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6">
                        <label for="inputadddealership" class="form-label">
                          {translations.quickCreationModal.crm[language]}
                        </label>
                        <div className="mb-3">
                          {/* <Field
                          name="state"
                          render={({ field, form }) => (
                            <Select
                              className="selectpicker"
                              aria-labelledby="dealership-name-quick"
                              // isClearable
                              // options={packageOptions}
                              placeholder={
                                translations["dropdown"].select[language]
                              }
                              isSearchable
                              // value={
                              //   (packageOptions &&
                              //     packageOptions.length > 0 &&
                              //     packageOptions.find(
                              //       (option) => option.value === field.value
                              //     )) || {
                              //     value: "",
                              //     label:
                              //       translations["dropdown"].select[language],
                              //   }
                              // }
                              onChange={(selectedOption) =>
                                form.setFieldValue(
                                  field.name,
                                  selectedOption.value
                                )
                              }
                              maxMenuHeight={"120px"}
                              styles={{
                                input: (provided) => ({
                                  ...provided,
                                  color: isDarkMode ? "#ffffff" : "black", // Color of the text when typing
                                }),
                                control: (provided, state) => ({
                                  ...provided,
                                  backgroundColor: isDarkMode
                                    ? "transparent"
                                    : "white",
                                  border: state.isFocused
                                    ? "1px solid #80bdff"
                                    : isDarkMode
                                    ? "1px solid #495057"
                                    : "1px solid #dee2e6", // Adjust the border color for focus
                                  boxShadow: state.isFocused
                                    ? "0 0 0 0.2rem rgba(0, 123, 255, 0.25)"
                                    : "none", // Bootstrap's box shadow for focus
                                  borderRadius: ".45rem",
                                }),
                                valueContainer: (provided) => ({
                                  ...provided,
                                  padding: "0.18rem 0.55rem", // Bootstrap's padding for the value container
                                }),
                                indicatorSeparator: (provided) => ({
                                  ...provided,
                                  display: "none", // Hide the indicator separator
                                }),
                                dropdownIndicator: (provided) => ({
                                  ...provided,
                                  padding: "0.375rem 0.75rem", // Bootstrap's padding for the dropdown indicator
                                  color: isDarkMode ? "#ffffff" : "#868686",
                                }),
                                menu: (provided) => ({
                                  ...provided,
                                  color: isDarkMode ? "#ffffff" : "black",
                                  backgroundColor: isDarkMode
                                    ? "#1f2023"
                                    : "white",
                                  margin: "0", // Remove default margin
                                }),
                                placeholder: (provided) => ({
                                  ...provided,
                                  color: isDarkMode ? "#ffffff" : "#333333", // Specify your desired color for the placeholder
                                  // Additional styles for placeholder
                                }),
                                singleValue: (provided) => ({
                                  ...provided,
                                  color: isDarkMode ? "#ffffff" : "black",
                                }),
                                option: (provided, state) => ({
                                  ...provided,
                                  color: isDarkMode ? "#ffffff" : "black",
                                  backgroundColor: state.isHovered
                                    ? "#80bdff"
                                    : isDarkMode
                                    ? "#1f2023"
                                    : "white",
                                  ":hover": {
                                    backgroundColor: isDarkMode
                                      ? "#34DBB4"
                                      : "#34DBB4",
                                  },
                                }),
                              }}
                            />
                          )}
                        /> */}
                          {/* <Field
                            name="crm_link"
                            type="text"
                            className="form-control"
                            id="inputadddealership"
                            style={{
                              backgroundColor: isDarkMode ? "transparent" : "",
                              color: isDarkMode ? "#ffffff" : "#000000",
                              border: isDarkMode
                                ? "1px solid #495057"
                                : "1px solid #dee2e6",
                            }}
                            placeholder={
                              translations.quickCreationModal.crm[language]
                            }
                          /> */}
                          <button
                            type="button"
                            className="admin-btn btn btn-primary"
                            id="add-dealership"
                            disabled={!values.crm_link}
                            onClick={() => {
                              if (values.crm_link) {
                                const url = `https://crm.zoho.com/crm/org27297444/tab/Accounts/${values.crm_link}`;
                                window.open(url, "_blank");
                              }
                            }}
                          >
                            CRM
                          </button>
                          <ErrorMessage
                            name="crm_link"
                            component="div"
                            className="error text-danger"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <Dropzone
                          // disabled={!editable}
                          style={{
                            backgroundColor: "#ced4da66",
                          }}
                          multiple={false}
                          accept=".png, .jpeg, .jpg, .webp"
                          onDrop={(acceptedFiles) => {
                            if (acceptedFiles.length > 0) {
                              setValues({ ...values, logo: acceptedFiles[0] });
                              setoldLogo("");
                            }
                          }}
                        >
                          {({ getRootProps, getInputProps }) => (
                            <div className="client-form-row w-100">
                              <div className="w-100">
                                <label
                                  htmlFor="logo"
                                  className="pb-3"
                                  style={{
                                    color: isDarkMode ? "#33dbb4" : "",
                                    fontSize: "20px",
                                    fontWeight: 600,
                                  }} /*class="form-label"*/
                                >
                                  {
                                    translations.quickCreationModal.logo[
                                      language
                                    ]
                                  }
                                </label>

                                <div
                                  {...getRootProps()}
                                  role="presentation"
                                  tabIndex="0"
                                  style={{
                                    background: "rgb(231, 236, 239)",
                                    // backgroundImage: `url(${URL.createObjectURL(values.logo)})`,
                                    // backgroundSize: "cover",
                                    // backgroundPosition: "center",
                                    padding: "25px 0px",
                                    borderRadius: "0.375rem",
                                    height: "180px",
                                    display: "flex",
                                    alignContent: "center",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    cursor: "pointer",
                                  }}
                                >
                                  <input
                                    {...getInputProps()}
                                    type="file"
                                    accept=".png, .jpeg, .jpg, .webp"
                                  />
                                  <div className="client-file-input">
                                    <p
                                      style={{
                                        textAlign: "center",
                                        fontSize: "15px",
                                        fontWeight: 700,
                                        marginBottom: "30px",
                                        marginTop: "20px",
                                      }}
                                    >
                                      {
                                        translations.quickCreationModal.logo2[
                                          language
                                        ]
                                      }
                                    </p>
                                    <div className="pb-5">
                                      <i
                                        className="bi bi-file-earmark-arrow-up-fill"
                                        style={{
                                          border: "10px dashed #babec1",
                                          padding: "15px 100px",
                                          color: "#babec1",
                                          borderRadius: "0.375rem",
                                          fontSize: "40pt",
                                          marginTop: "10px",
                                        }}
                                      ></i>
                                    </div>
                                  </div>
                                </div>
                                {values.logo && (
                                  <div>
                                    <div className="d-flex gap-3 align-items-center ">
                                      <div
                                        className="d-flex gap-1 py-3 align-items-center"
                                        style={{ position: "relative" }}
                                      >
                                        <i
                                          className="bi bi-x-circle"
                                          onClick={() =>
                                            setValues({ ...values, logo: "" })
                                          }
                                          style={{
                                            position: "absolute",
                                            top: "16px",
                                            right: "4px",
                                            color: "red",
                                            cursor: "pointer",
                                            fontSize: "12px",
                                          }}
                                        ></i>
                                        <img
                                          className=""
                                          width={"65px"}
                                          height={"60px"}
                                          src={
                                            isString(values.logo)
                                              ? ApiUrl + "/logos/" + values.logo
                                              : URL.createObjectURL(values.logo)
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                )}
                                <ErrorMessage
                                  name="logo"
                                  component="div"
                                  className="error text-danger"
                                />
                              </div>
                            </div>
                          )}
                        </Dropzone>
                      </div>
                    </div>
                  </div>
                )}
                <div
                  class="modal-footer"
                  id="modalfooter"
                  style={{
                    borderTop: isDarkMode
                      ? "1px solid #495057"
                      : "1px solid #dee2e6",
                  }}
                >
                  <button
                    type="submit"
                    class="admin-btn btn btn-primary"
                    id="add-dealership"

                    // data-bs-toggle="modal"
                    // data-bs-target="#modalQuickAdd"
                  >
                    {isEdit
                      ? loading
                        ? translations.commonFields.savingBtn[language]
                        : translations.commonFields.saveBtn[language]
                      : translations.commonFields.addBtn[language]}
                  </button>
                  <button
                    id="edirDealershipClose"
                    type="button"
                    class="btn btn-secondary"
                    data-bs-dismiss="modal"
                    onClick={() => {
                      resetForm();
                      setOldData(null);
                      setValues({
                        name: "",
                        country: "",
                        state: "",
                        city: "",
                        address: "",
                        postal_code: "",
                        timezone: "",
                        phone_number: "",
                        primary_contact: "",
                        group_id: "",
                        representative_id: "",
                        brand_id: "",
                        logo: "",
                        crm_link: "",
                      });
                    }}
                  >
                    {translations["commonFields"].closeBtn[language]}
                  </button>
                  {/* <br />
                  <div
                    class="alert alert-danger d-flex align-items-center invisible"
                    id="clientFormAlert"
                    role="alert"
                  >
                    <div id="clientFormAlertText"></div>
                  </div> */}
                </div>
              </Form>
            )}
          </Formik>
        </div>
        {/* <ModalAdminQuickAdd/> */}
      </div>
    </div>
  );
};

export default ModalAddDealer_CreateEvent;
