import React, { useEffect, useState } from "react";
import { useLanguage } from "../languageContext";
import translations from "../translation.json";
import AppointmentsSection from "./appointmentsSection";
import SingleAppointmentsSection from "./singleAppointmentsSection.";
import FollowUp from "./followup";
import { ApiUrl } from "../ApiUrl";
import { toast } from "react-toastify";
import { format, max, min, parseISO } from "date-fns";

const EvenView = ({
  isDarkMode,
  setDisplayComponent,
  setPrevDisplay,
  setIsLoading,
}) => {
  const { language } = useLanguage();
  const [eventId, setEventId] = useState(null);
  const [dashboardData, setDashboardData] = useState("");
  const [eventDates, setEventDates] = useState([]);
  const token = localStorage.getItem("token");

  useEffect(() => {
    // Get the current URL
    var currentUrl = window.location.href;
    // Check if '=' is found and extract the text after it
    const equalsIndex = currentUrl.indexOf("=");
    if (equalsIndex !== -1) {
      const secondEqualsIndex = currentUrl.indexOf("=", equalsIndex + 1);

      if (secondEqualsIndex !== -1) {
        const lastParameterValue = currentUrl.substring(secondEqualsIndex + 1);
        setEventId(lastParameterValue);
      }
    }
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const res = await fetch(ApiUrl + "/api/user/graph-dashboard", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            event_id: eventId,
            // graphDate: new Date(),
            // appointmentDate: new Date(),
            // followUpDate: new Date(),
          }),
        });
        const data = await res.json();
        if (data.status === "success") {
          // const newData = data.private_events.data;
          setDashboardData(data);
        } else {
          toast.error(data.message);
        }
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        toast.error("Something went wrong");
      } finally {
      }
    };
    const getDates = async () => {
      try {
        setIsLoading(true);
        const res = await fetch(ApiUrl + `/api/user/fillter-dates/${eventId}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await res.json();
        if (data.status === "success") {
          const newData =
            data.appointment_dates &&
            data.appointment_dates.length > 0 &&
            data.appointment_dates.map((item) => {
              return { label: item, value: item };
            });
          setEventDates(newData);
        } else {
          toast.error(data.message);
        }
      } catch (err) {
        toast.error("Something went wrong");
      } finally {
        setIsLoading(false);
      }
    };
    if (token && eventId) {
      fetchData();
      getDates();
    }
  }, [eventId]);

  async function getDataByDate(bodyData, sectionType) {
    try {
      setIsLoading(true);
      const res = await fetch(ApiUrl + "/api/user/graph-dashboard", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          event_id: eventId,
          [Object.keys(bodyData)[0]]: bodyData[Object.keys(bodyData)[0]],
        }),
      });
      const data = await res.json();
      if (data.status === "success") {
        // console.log(data[sectionType]);

        // const newData = data;
        setDashboardData((prev) => ({
          ...prev,
          [sectionType]: data[sectionType],
          graphDate: data?.graphDate
        }));
      } else {
        toast.error(data.message);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      toast.error("Something went wrong");
    } finally {
    }
  }

  return (
    <div>
      <div className="container maincont">
        <div id="app" className="appcountainer2">
          <div className="row align-items-center justify-content-between">
            <div className="col-7 d-flex gap-3 align-items-center">
              <div>
                <h2 id="h1" className={`${isDarkMode ? "darkthead" : ""}`}>
                  {translations.eventView.buttons.dashboard[language]}{" - "}
                  {/* {translations.eventView.title[language]}:{" "} */}
                  {dashboardData?.dealershipDetails?.name}:{" "}
                  {/* {eventDates && eventDates.length > 0
                    ? format(
                        min(eventDates.map((date) => parseISO(date.value))), // Convert to Date objects
                        "dd/MM/yyyy"
                      )
                    : "N/A"}{" "} */} {dashboardData?.dealershipDetails?.start_date}{" "}
                  {translations.commonWords.to[language]}{" "}
                  {/* {eventDates && eventDates.length > 0
                    ? format(
                        max(eventDates.map((date) => parseISO(date.value))), // Convert to Date objects
                        "dd/MM/yyyy"
                      )
                    : "N/A"} */}{dashboardData?.dealershipDetails?.end_date}
                </h2>
              </div>
            </div>
            <div className="col-5 gap-1 d-flex align-items-center justify-content-end">
              <div
                className="btn-group"
                role="group"
                aria-label="Basic example"
              >
                <button
                  type="button"
                  className="d-flex align-items-center gap-1"
                  // data-bs-toggle="modal"
                  // data-bs-target="#modalQuickAdd"
                  // fdprocessedid="2rud2c"
                  style={{
                    background: "#05cc9e",
                    // color: "white",
                    paddingBlock: "0.375rem",
                    paddingInline: "0.75rem",
                    borderRadius: "0.375rem",
                    borderColor: "#05cc9e",
                    borderStyle: "solid",
                  }}
                  onClick={() => {
                    setDisplayComponent("event-view");

                    window.history.pushState(
                      {
                        id: "gallery",
                        randomData: window.Math.random(),
                      },
                      "title",
                      "/event-portal?page=event-view&event_id=" + eventId
                    );
                  }}
                >
                  <i className="bi bi-speedometer"></i>{" "}
                  {translations.eventView.buttons.dashboard[language]}
                </button>
              </div>
              <div
                className="btn-group"
                role="group"
                aria-label="Basic example"
              >
                <button
                  type="button"
                  className="btn admin-btn btn-primary d-flex align-items-center gap-1"
                  onClick={() => {
                    setDisplayComponent("appointments");

                    window.history.pushState(
                      {
                        id: "gallery",
                        randomData: window.Math.random(),
                      },
                      "title",
                      `/event-portal?page=appointments&eventId=${eventId}`
                    );
                  }}
                >
                  <i className="bi bi-calendar2-range"></i>{" "}
                  {translations.eventView.buttons.calender[language]}
                </button>
              </div>
              <div
                className="btn-group"
                role="group"
                aria-label="Basic example"
              >
                <button
                  type="button"
                  className="btn admin-btn btn-primary d-flex align-items-center gap-1"
                  // data-bs-toggle="modal"
                  // data-bs-target="#modalQuickAdd"
                  // fdprocessedid="2rud2c"
                  onClick={() => {
                    setDisplayComponent("clients");

                    window.history.pushState(
                      {
                        id: "gallery",
                        randomData: window.Math.random(),
                      },
                      "title",
                      `/event-portal?page=clients&eventId=${eventId}`
                    );
                  }}
                >
                  <i className="bi bi-people"></i>{" "}
                  {translations.forms.formFields.clients[language]}
                </button>
              </div>
              <div
                className="btn-group"
                role="group"
                aria-label="Basic example"
              >
                <button
                  type="button"
                  className="btn admin-btn btn-primary d-flex align-items-center gap-1"
                  // data-bs-toggle="modal"
                  // data-bs-target="#modalQuickAdd"
                  // fdprocessedid="2rud2c"
                  onClick={() => {
                    setDisplayComponent("copy-client");

                    window.history.pushState(
                      {
                        id: "gallery",
                        randomData: window.Math.random(),
                      },
                      "title",
                      `/event-portal?page=copy-client&eventId=${eventId}`
                    );
                  }}
                >
                  <i className="bi bi-clipboard-plus"></i>{" "}
                  {translations.forms.formFields.copyClient[language]}
                </button>
              </div>
              <div
                className="btn-group"
                role="group"
                aria-label="Basic example"
              >
                <button
                  type="button"
                  className="btn admin-btn btn-primary d-flex align-items-center gap-1"
                  // data-bs-toggle="modal"
                  // data-bs-target="#modalQuickAdd"
                  // fdprocessedid="2rud2c"
                  onClick={() => {
                    setDisplayComponent("eventReport");

                    window.history.pushState(
                      {
                        id: "gallery",
                        randomData: window.Math.random(),
                      },
                      "title",
                      "/event-portal?page=eventReport&event_id=" + eventId
                    );
                  }}
                >
                  <i className="bi bi-file-earmark-bar-graph"></i>{" "}
                  {translations.viewEventReport.title[language]}
                </button>
              </div>
            </div>
          </div>
          <div>
            <AppointmentsSection
              isDarkMode={isDarkMode}
              dashboardData={dashboardData}
              getDataByDate={getDataByDate}
              eventDates={eventDates}
            />
          </div>
          <div className="row">
            <div className="col-6">
              <SingleAppointmentsSection
                isDarkMode={isDarkMode}
                appointments={dashboardData?.appointments}
                getDataByDate={getDataByDate}
                eventDates={eventDates}
              />
            </div>
            <div className="col-6">
              <FollowUp
                isDarkMode={isDarkMode}
                appointments={dashboardData?.followUpAppointments}
                getDataByDate={getDataByDate}
                eventDates={eventDates}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EvenView;
