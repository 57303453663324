import { Route, Routes, useNavigate } from "react-router-dom";
import ClientForm from "./ClientForm";
import Login from "./Login";
import EventPortal from "./EventPortal";
import Test from "./test";
import EditClientForm from "./EditClientForm";
import ResetPassword from "./resetPassword";
import ConfirmEmail from "./ConfirmEmail";
import { useEffect, useState } from "react";
import { ApiUrl } from "./ApiUrl";
import "./App.css";
import SetPassword from "./SetPassword";
import { LanguageProvider } from "./languageContext";
import Footer from "./footer";
import UserProfile from "./userProfile";
import ClientDashboard from "./ClientDashboard";
import ViewClientSubmission from "./ViewClientSubmission";
import { toast } from "react-toastify";

function App() {
  const navigate = useNavigate();
  const userData = JSON.parse(localStorage.getItem("userData"));
  const [isTokenExpired, setIsTokenExpired] = useState();
  const token = localStorage.getItem("token");
  const [isDarkMode, setIsDarkMode] = useState(
    userData && userData.theme_mode !== "light" ? true : false
  );

  // const [isDarkMode, setIsDarkMode] = useState(false);

  // const [darkTrigger, setDarkTrigger] = useState(false);

  useEffect(() => {
    if (userData) {
      userData.theme_mode = isDarkMode ? "dark" : "light";

      // Saving the updated userData back to localStorage
      localStorage.setItem("userData", JSON.stringify(userData));
    }

    // console.log(isDarkMode)
    if (isDarkMode) {
      document.documentElement.style.setProperty(
        "--color-background",
        "#1b1f22"
      );
      document.documentElement.style.setProperty("--color-text", "#fff");
      document.documentElement.style.setProperty(
        "--color-background-second",
        "#212529"
      );
      document.documentElement.style.setProperty("--bs-light-rgb", "#000");
      document.documentElement.style.setProperty("--bs-modal-bg", "#1f2023");
      // document.documentElement.style.setProperty("--color-text", "#34DBB4");

      document.documentElement.style.setProperty(
        "--color-background-client-alert",
        "#1f2023"
      ); //#0e1319
      document.documentElement.style.setProperty(
        "--color-background-client-border",
        "#b6d4fe"
      );
      document.documentElement.style.setProperty(
        "--time-column-bg",
        "#000000"
      );
    } else {
      document.documentElement.style.setProperty("--color-background", "#fff");
      document.documentElement.style.setProperty("--color-text", "#000");
      document.documentElement.style.setProperty(
        "--color-background-second",
        "#F5F5FB"
      );
      document.documentElement.style.setProperty("--bs-light-rgb", "#F5F5FB");
      document.documentElement.style.setProperty("--bs-modal-bg", "#fff");

      document.documentElement.style.setProperty(
        "--color-background-client-alert",
        "#cfe2ff"
      );
      document.documentElement.style.setProperty(
        "--color-background-client-border",
        "#0000002d"
      );
      document.documentElement.style.setProperty(
        "--time-column-bg",
        "#ffffff"
      );
    }

    const changeMode = async () => {
      try {
        const res = await fetch(ApiUrl + "/api/user/change_theme", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            mode: isDarkMode ? "dark" : "light",
          }),
        });
        const data = await res.json();

        if (data.status === "success") {
          console.log("");
        } else {
          toast.error("Authentication Failed !");
        }
      } catch (error) {
        toast.error("Something went wrong!");
      }
    };

    if (token) {
      changeMode();
    }
  }, [isDarkMode]);

  // const isTokenExpired = (token) => {
  //   const decodedToken = jwtDecode(token);
  //   const currentTime = Date.now() / 1000;
  //   return decodedToken.exp < currentTime;
  // };
  // useEffect(() => {
  //   const token = localStorage.getItem("token");
  //   if (token && isTokenExpired(token)) {
  //     localStorage.removeItem("token");
  //     localStorage.removeItem("userType");
  //     navigate("/");
  //   }
  // }, []);

  // if token is expired this useEffect will remove token
  useEffect(() => {
    const fetchUser = async () => {
      if (token) {
        try {
          const res = await fetch(ApiUrl + "/api/user/loggeduser", {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          });
          const data = await res.json();
          if (data.status === "success") {
            const userdata = localStorage.getItem("userData");
            const parsedData = JSON.parse(userdata);
            if (parsedData.user_language !== data.user.user_language) {
              localStorage.setItem(
                "userData",
                JSON.stringify({
                  ...parsedData,
                  user_language: data.user.user_language,
                })
              );
            }
            setIsTokenExpired(false);
            // localStorage.setItem('userType', data.user.user_type)
            // if (data.user.user_type === 'admin') {
            //   navigate('/event-portal')
            // } else {
            //   navigate('/client-form')
            // }
          } else {
            setIsTokenExpired(true);
            localStorage.removeItem("token");
            localStorage.removeItem("userType");
            localStorage.removeItem("userData");
            // navigate("/");
          }
          // return data;
        } catch (error) {
          localStorage.removeItem("token");
          localStorage.removeItem("userType");
          localStorage.removeItem("userData");
          navigate("/");
        }
      }
    };
    fetchUser();
    
  }, []);

  return (
    <LanguageProvider>
      {((localStorage.getItem("token") && !isTokenExpired) ||
        !localStorage.getItem("token")) && (
        <>
          <div className="App">
            <header
              className="App-header"
              style={{ overflow: "hidden" }}
              // style={{
              //   minHeight: "90vh",
              // }}
            >
              <Routes>
                <Route
                  path="/client-form"
                  element={
                    <ClientForm
                      setIsDarkMode={setIsDarkMode}
                      isDarkMode={isDarkMode}
                    />
                  }
                />
                {/* <Route
                  path="/client-dashboard"
                  element={
                    <ClientDashboard
                      setIsDarkMode={setIsDarkMode}
                      isDarkMode={isDarkMode}
                    />
                  }
                /> */}
                <Route path="/" element={<Login isDarkMode={isDarkMode}/>} />

                <Route
                  path="/user-profile"
                  element={
                    <UserProfile
                      setIsDarkMode={setIsDarkMode}
                      isDarkMode={isDarkMode}
                    />
                  }
                />
                {/* <Route
                  path="/client-submission"
                  element={
                    <ViewClientSubmission
                      setIsDarkMode={setIsDarkMode}
                      isDarkMode={isDarkMode}
                    />
                  }
                /> */}
                <Route
                  path="/event-portal"
                  element={
                    <EventPortal
                      setIsDarkMode={setIsDarkMode}
                      isDarkMode={isDarkMode}
                    />
                  }
                />
                <Route path="/test" element={<Test />} />
                <Route path="confirm-email" element={<ConfirmEmail />} />
                <Route path="/reset-password" element={<ResetPassword />} />
                <Route
                  path="/set-password"
                  element={<SetPassword isDarkMode={isDarkMode} />}
                />
              </Routes>
            </header>
            <Footer isDarkMode={isDarkMode} />
          </div>
        </>
      )}
    </LanguageProvider>
  );
}

export default App;
