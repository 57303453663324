import { useEffect, useState } from "react";
import logo from "./assets/Nexus_logo@3x.png";
import logoDark from "./assets/Nexus_logo@3x_Dark.png";
// import logo from "./assets/logo.png";
// import people from "./assets/adminnav-people.png";
import ModalAdminQuickAdd from "./ModalAdminQuickAdd";
import ChangePasswordModal from "./ChangePasswordModal";
import { toast } from "react-toastify";
import { ApiUrl } from "./ApiUrl";
import DarkModeToggle from "react-dark-mode-toggle";
import { useLanguage } from "./languageContext";
import LanguageToggle from "./languageToggle";
import translations from "./translation.json";
import { externalUsers, internalUsers } from "./constants/userTypes";
// import Select from "react-select";
import { MultiSelect } from "react-multi-select-component";

const AdminNav = ({
  setDisplayComponent,
  dealerships,
  setDealerTrigger,
  setEventTrigger,
  setToken,
  token,
  setIsDarkMode,
  isDarkMode,
  setPrevDisplay,
  setIsLoading,
  events,
  setEvents,
  setDealerships2,
  dealerships3,
  setDealerships3,
  dealerships2,
  getDealershipFilterData,
  showDealerDropDown,
  displayComponent,
  getDealershipFilterDataDealersPage,
  getUsersByDealership,
  selectedOption,
  setSelectedOption,
  dealershipLabel,
  setDealershipLabel,
}) => {
  const userType = localStorage.getItem("userType");
  const userData = JSON.parse(localStorage.getItem("userData"));
  const { language } = useLanguage();
  const currentYear = new Date().getFullYear();
  const pageTranslations = translations["topAndsidebar"];

  const handleLogout = async () => {
    setIsLoading(true);
    try {
      const res = await fetch(ApiUrl + "/api/user/logout", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await res.json();
      if (data.status === "success") {
        localStorage.removeItem("token");
        setToken(null);
        localStorage.removeItem("userType");
        localStorage.removeItem("userData");
        setIsDarkMode(false);
        toast.success(data.message);
      } else {
        toast.error(data.message);
      }
      setIsLoading(false);
    } catch (error) {
      toast.error("Something went wrong!");
      localStorage.removeItem("token");
      setToken(null);
      setIsDarkMode(false);
      localStorage.removeItem("userType");
      localStorage.removeItem("userData");
    }
  };

  useEffect(() => {
    // Reset the dropdown whenever the URL changes
    const selectedValues = selectedOption?.map((option) => option.value);
    if (displayComponent === "manage-users" && selectedValues?.length > 0) {
      getUsersByDealership(selectedValues || []);
    }
    if (displayComponent === "dealership" && selectedValues?.length > 0) {
      if (userType && internalUsers.includes(userType)) {
        getDealershipFilterDataDealersPage(selectedValues || []);
      }
    }
    if (displayComponent === "events" && selectedValues?.length > 0) {
      getDealershipFilterData(selectedValues || []);
    }
    if (displayComponent === "events"){
      setDealershipLabel(null)
    }
    // setSelectedOption(null);
  }, [displayComponent]);
  

  return (
    <div
      className="admin-nav"
      style={{ backgroundColor: isDarkMode ? "#2b3035" : "" }}
    >
      <nav
        id="mainav"
        className="navbar navbar-light bg-light sticky-top"
        style={{ marginBottom: 10, padding: "4px 0" }}
      >
        <div className="container-fluid">
          <div
            className="navbar-brand"
            onClick={() => {
              setDisplayComponent("events");
              window.history.pushState(
                { id: "gallery", randomData: window.Math.random() },
                "title",
                "/event-portal?page=events"
              );
            }}
            style={{ cursor: "pointer" }}
          >
            <img
              id="search-link-logo"
              className="p-1"
              src={isDarkMode ? logoDark : logo}
              alt="Logo"
              height="38"
            />{" "}
            {/* | Nexus <span style={{ fontSize: 15 }}>V6</span> */}
          </div>

          <div className="d-flex gap-2 align-items-center  ">
            {!(
              displayComponent === "forms" ||
              displayComponent === "user-profile" ||
              displayComponent === "create-event" ||
              displayComponent === "create-privateEvent" ||
              displayComponent === "edit-event" ||
              displayComponent === "edit-form" ||
              displayComponent === "create-user" ||
              displayComponent === "edit-user" ||
              displayComponent === "edit-client" ||
              displayComponent === "view-submissions" ||
              displayComponent === "dealership" ||
              displayComponent === "manage-users" ||
              displayComponent === "view-sales-report" ||
              displayComponent === "status-report" ||
              displayComponent === "black-list" ||
              displayComponent === "agent-bonus-report" ||
              displayComponent === "bonus-confirmation-report"
            ) && (
              <div
                style={{ width: "250px" }}
                className={isDarkMode ? "statusDropdownDark" : "statusDropdown"}
              >
                {/* <Select
                  className="selectpicker w-100"
                  aria-labelledby="dealership-name-quick"
                  isClearable
                  options={dealerships2}
                  placeholder={translations["dropdown"].select[language]}
                  isSearchable={true}
                  // value={
                  //   (dealerships2 &&
                  //     dealerships2.length > 0 &&
                  //     dealerships2.find(
                  //       (option) => option.value === filterOption
                  //     )) || {
                  //     value: "",
                  //     label: translations["dropdown"].select[language],
                  //   }
                  // }
                  value={selectedOption} // Controlled value
                  onChange={(selectedOption) => {
                    setSelectedOption(selectedOption);
                    getDealershipFilterData(
                      selectedOption ? selectedOption.value : ""
                    );
                    getDealershipFilterDataDealersPage("");
                    getUsersByDealership("");
                  }}
                  maxMenuHeight={"130px"}
                  styles={{
                    input: (provided) => ({
                      ...provided,
                      color: isDarkMode ? "#ffffff" : "black", // Color of the text when typing
                    }),
                    control: (provided, state) => ({
                      ...provided,
                      backgroundColor: isDarkMode ? "transparent" : "white",
                      border: state.isFocused
                        ? "1px solid #80bdff"
                        : isDarkMode
                        ? "1px solid #495057"
                        : "1px solid #dee2e6", // Adjust the border color for focus
                      boxShadow: state.isFocused
                        ? "0 0 0 0.2rem rgba(0, 123, 255, 0.25)"
                        : "none", // Bootstrap's box shadow for focus
                      borderRadius: ".45rem",
                    }),
                    valueContainer: (provided) => ({
                      ...provided,
                      padding: "0.18rem 0.55rem", // Bootstrap's padding for the value container
                    }),
                    indicatorSeparator: (provided) => ({
                      ...provided,
                      display: "none", // Hide the indicator separator
                    }),
                    dropdownIndicator: (provided) => ({
                      ...provided,
                      padding: "0.375rem 0.75rem", // Bootstrap's padding for the dropdown indicator
                      color: isDarkMode ? "#ffffff" : "#868686",
                    }),
                    clearIndicator: (provided) => ({
                      ...provided,
                      color: isDarkMode ? "#ffffff" : "#868686", // Set clear icon color
                      // ":hover": {
                      //   color: isDarkMode ? "#34DBB4" : "#34DBB4", // Set hover color for clear icon
                      // },
                      cursor: "pointer",
                    }),
                    menu: (provided) => ({
                      ...provided,
                      color: isDarkMode ? "#ffffff" : "black",
                      backgroundColor: isDarkMode ? "#1f2023" : "white",
                      margin: "0", // Remove default margin
                      zIndex: "9999",
                    }),
                    placeholder: (provided) => ({
                      ...provided,
                      color: isDarkMode ? "#ffffff" : "#333333", // Specify your desired color for the placeholder
                      // Additional styles for placeholder
                    }),
                    singleValue: (provided) => ({
                      ...provided,
                      color: isDarkMode ? "#ffffff" : "",
                    }),
                    option: (provided, state) => ({
                      ...provided,
                      color: isDarkMode ? "#ffffff" : "black",
                      backgroundColor: state.isHovered
                        ? "#80bdff"
                        : isDarkMode
                        ? "#1f2023"
                        : "white",
                      ":hover": {
                        backgroundColor: isDarkMode ? "#34DBB4" : "#34DBB4",
                      },
                    }),
                  }}
                /> */}
                <MultiSelect
                  options={dealerships2}
                  className={`selectpicker w-100 ${
                    isDarkMode ? "reactMultiDark" : ""
                  }`}
                  value={selectedOption}
                  onChange={(selectedOptions) => {
                    setSelectedOption(selectedOptions); // Update state with selected options
                    setDealershipLabel(selectedOption?.label || null)
                    // Extract values from selectedOptions
                    const selectedValues = selectedOptions?.map(
                      (option) => option.value
                    );

                    // Pass the array of selected values to the required functions
                    getDealershipFilterData(selectedValues);
                    if (userType && internalUsers.includes(userType)) {
                      getDealershipFilterDataDealersPage([]); // Pass an empty array for resetting
                    }
                    getUsersByDealership([]); // Pass an empty array for resetting
                  }}
                  // disableSearch
                  
                  // labelledBy={dealershipLabel ? dealershipLabel : translations["dropdown"].select[language]}
                  overrideStrings={{
                    selectSomeItems: dealershipLabel
                      ? dealershipLabel
                      : translations["dropdown"].select[language]
                  }}
                />
              </div>
            )}
            {(displayComponent === "dealership" ||
              displayComponent === "manage-users") && (
              <div style={{ width: "250px" }}>
                <MultiSelect
                  options={dealerships3}
                  className={`selectpicker w-100 ${
                    isDarkMode ? "reactMultiDark" : ""
                  }`}
                  value={selectedOption}
                  onChange={(selectedOptions) => {
                    setSelectedOption(selectedOptions); // Update state with selected options

                    // Extract values from selectedOptions
                    const selectedValues = selectedOptions?.map(
                      (option) => option.value
                    );

                    if (displayComponent === "dealership") {
                      getDealershipFilterData([]);
                      if (userType && internalUsers.includes(userType)) {
                        getDealershipFilterDataDealersPage(
                          selectedValues || []
                        );
                      }
                      getUsersByDealership([]);
                    } else if (displayComponent === "manage-users") {
                      getDealershipFilterData([]);
                      getUsersByDealership(selectedValues || []);
                      if (userType && internalUsers.includes(userType)) {
                        getDealershipFilterDataDealersPage([]);
                      }
                    } else {
                      getDealershipFilterData(selectedValues || []);
                      if (userType && internalUsers.includes(userType)) {
                        getDealershipFilterDataDealersPage([]);
                      }
                      getUsersByDealership([]);
                    }
                  }}
                  // disableSearch
                  labelledBy={translations["dropdown"].select[language]}
                />
                {/* <Select
                  className="selectpicker w-100"
                  aria-labelledby="dealership-name-quick"
                  isClearable
                  options={dealerships3}
                  placeholder={translations["dropdown"].select[language]}
                  isSearchable={true}
                  // value={
                  //   (dealerships2 &&
                  //     dealerships2.length > 0 &&
                  //     dealerships2.find(
                  //       (option) => option.value === filterOption
                  //     )) || {
                  //     value: "",
                  //     label: translations["dropdown"].select[language],
                  //   }
                  // }
                  value={selectedOption} // Controlled value
                  onChange={(selectedOption) => {
                    setSelectedOption(selectedOption);
                    if (displayComponent === "dealership") {
                      getDealershipFilterData("");
                      getDealershipFilterDataDealersPage(
                        selectedOption ? selectedOption.value : ""
                      );
                      getUsersByDealership("");
                    } else if (displayComponent === "manage-users") {
                      getDealershipFilterData("");
                      getUsersByDealership(
                        selectedOption ? selectedOption.value : ""
                      );
                      getDealershipFilterDataDealersPage("");
                    } else {
                      getDealershipFilterData(
                        selectedOption ? selectedOption.value : ""
                      );
                      getDealershipFilterDataDealersPage("");
                      getUsersByDealership("");
                    }
                  }}
                  maxMenuHeight={"130px"}
                  styles={{
                    input: (provided) => ({
                      ...provided,
                      color: isDarkMode ? "#ffffff" : "black", // Color of the text when typing
                    }),
                    control: (provided, state) => ({
                      ...provided,
                      backgroundColor: isDarkMode ? "transparent" : "white",
                      border: state.isFocused
                        ? "1px solid #80bdff"
                        : isDarkMode
                        ? "1px solid #495057"
                        : "1px solid #dee2e6", // Adjust the border color for focus
                      boxShadow: state.isFocused
                        ? "0 0 0 0.2rem rgba(0, 123, 255, 0.25)"
                        : "none", // Bootstrap's box shadow for focus
                      borderRadius: ".45rem",
                    }),
                    valueContainer: (provided) => ({
                      ...provided,
                      padding: "0.18rem 0.55rem", // Bootstrap's padding for the value container
                    }),
                    indicatorSeparator: (provided) => ({
                      ...provided,
                      display: "none", // Hide the indicator separator
                    }),
                    dropdownIndicator: (provided) => ({
                      ...provided,
                      padding: "0.375rem 0.75rem", // Bootstrap's padding for the dropdown indicator
                      color: isDarkMode ? "#ffffff" : "#868686",
                    }),
                    clearIndicator: (provided) => ({
                      ...provided,
                      color: isDarkMode ? "#ffffff" : "#868686", // Set clear icon color
                      // ":hover": {
                      //   color: isDarkMode ? "#34DBB4" : "#34DBB4", // Set hover color for clear icon
                      // },
                      cursor: "pointer",
                    }),
                    menu: (provided) => ({
                      ...provided,
                      color: isDarkMode ? "#ffffff" : "black",
                      backgroundColor: isDarkMode ? "#1f2023" : "white",
                      margin: "0", // Remove default margin
                      zIndex: "9999",
                    }),
                    placeholder: (provided) => ({
                      ...provided,
                      color: isDarkMode ? "#ffffff" : "#333333", // Specify your desired color for the placeholder
                      // Additional styles for placeholder
                    }),
                    singleValue: (provided) => ({
                      ...provided,
                      color: isDarkMode ? "#ffffff" : "",
                    }),
                    option: (provided, state) => ({
                      ...provided,
                      color: isDarkMode ? "#ffffff" : "black",
                      backgroundColor: state.isHovered
                        ? "#80bdff"
                        : isDarkMode
                        ? "#1f2023"
                        : "white",
                      ":hover": {
                        backgroundColor: isDarkMode ? "#34DBB4" : "#34DBB4",
                      },
                    }),
                  }}
                /> */}
              </div>
            )}
            <DarkModeToggle
              onChange={setIsDarkMode}
              checked={isDarkMode}
              size={70}
              className="mx-2"
            />
            {/* <LanguageToggle /> */}
            <div className="btn-group" role="group" aria-label="Basic example">
              <button
                className="btn admin-btn btn-primary d-flex align-items-center "
                onClick={() => {
                  setDisplayComponent((prev) => {
                    setPrevDisplay(prev);
                    return "user-profile";
                  });
                  window.history.pushState(
                    { id: "userProfile", randomData: window.Math.random() },
                    "title",
                    "?page=user-profile"
                  );
                }}
              >
                <i className="bi bi-person-circle me-2"></i>
                {translations.topAndsidebar.profile[language]}
              </button>
              <button
                style={{ marginBottom: 0 }}
                className="btn btn-light bi bi-list navbar-toggler"
                data-bs-target="#offcanvasmain"
                data-bs-toggle="offcanvas"
                aria-bs-controls="offcanvasmain"
                type="button"
              ></button>
            </div>
          </div>
        </div>
      </nav>
      <div
        style={{
          overflow: "hidden",
          paddingTop: 0,
          bottom: "auto",
        }}
        className={`offcanvas offcanvas-end offcanvas-sm h-100 ${
          isDarkMode ? "bg-dark" : "bg-body-tertiary"
        } `}
        tabindex="-1"
        id="offcanvasmain"
        data-bs-backdrop="true"
        aria-bs-labelledby="offcanvasmainLabel"
      >
        {/* <div className="offcanvas-header" style={{ paddingBottom: 0 }}>
          <a className="navbar-brand text-center" id="offcanvasExampleLabel">
            <img className="logo" src={logo} alt="Logo" height="35" />
          </a>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div> */}
        {/* <hr style={{ marginTop: 0 }} /> */}
        <div className="offcanvas-body p-4 flex-column">
          <div className="d-flex justify-content-between align-items-center ">
            <a className="navbar-brand text-center" id="offcanvasExampleLabel">
              <img
                id="search-link-logo"
                className=" p-1 w-100"
                src={isDarkMode ? logoDark : logo}
                alt="Logo"
                height="38"
              />
            </a>
            <button
              type="button"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
              style={{
                background: "transparent",
                color: isDarkMode ? "white" : "black",
                border: "none",
                fontWeight: "400",
                fontSize: "18px",
              }}
            >
              X
            </button>
          </div>
          <ul className="nav flex-column mt-4 gap-2 ">
            <li className="">
              <div className="d-grid gap-2">
                <button
                  type="button"
                  className=" admin-btn nav-link d-flex align-items-center gap-1"
                  id="search-link"
                  fdprocessedid="dyv9n"
                  data-bs-dismiss="offcanvas"
                  onClick={() => {
                    setDisplayComponent((prev) => {
                      setPrevDisplay(prev);
                      return "events";
                    });
                    window.history.pushState(
                      { id: "gallery", randomData: window.Math.random() },
                      "title",
                      "?page=events"
                    );
                  }}
                  // style={{backgroundColor:"none"}}
                >
                  <i className="bi bi-search"></i>{" "}
                  {pageTranslations.events[language]}
                </button>
              </div>
            </li>

            {/* <li className="">
              <div className="d-grid gap-2">
                <button
                  type="button"
                  className=" admin-btn nav-link d-flex align-items-center gap-1"
                  id="search-link"
                  fdprocessedid="dyv9n"
                  data-bs-dismiss="offcanvas"
                  onClick={() => {
                    setDisplayComponent((prev) => {
                      setPrevDisplay(prev);
                      return "forms";
                    });
                    window.history.pushState(
                      { id: "gallery", randomData: window.Math.random() },
                      "title",
                      "?page=forms"
                    );
                  }}
                  // style={{backgroundColor:"none"}}
                >
                  <i className="bi bi-file-earmark-diff"></i>{" "}
                  {pageTranslations.forms[language]}
                </button>
              </div>
            </li> */}

            {/* <li className="">
              <div className="d-grid gap-2">
                <button
                  type="button"
                  className="admin-btn nav-link d-flex align-items-center gap-1"
                  id="create-form-link"
                  fdprocessedid="v57r2v"
                  data-bs-dismiss="offcanvas"
                  onClick={() => {
                    setDisplayComponent((prev) => {
                      setPrevDisplay(prev);
                      return "create-event";
                    });
                    window.history.pushState(
                      { id: "gallery", randomData: window.Math.random() },
                      "title",
                      "/event-portal?page=create-event"
                    );
                  }}
                >
                  <i className="bi bi-file-earmark-plus"></i>{" "}
                  {pageTranslations.createevent[language]}
                </button>
              </div>
            </li> */}
            {userData &&
              userData.roles[0].permissions.some(
                (item) => item.name === "All Dealerships"
              ) && (
                <li className="">
                  <div className="d-grid gap-2">
                    <button
                      type="button"
                      className="admin-btn nav-link d-flex align-items-center gap-1"
                      id="add-dlr-link"
                      fdprocessedid="4u2e3"
                      Concession
                      data-bs-dismiss="offcanvas"
                      onClick={() => {
                        setDisplayComponent((prev) => {
                          setPrevDisplay(prev);
                          return "dealership";
                        });
                        window.history.pushState(
                          // { id: "gallery", randomData: window.Math.random() },
                          { prevState: "prev" },
                          "title",
                          "/event-portal?page=dealership"
                        );
                      }}
                    >
                      <i className="bi bi-shop-window"></i>{" "}
                      {pageTranslations.dealership[language]}{" "}
                      {/*<span className="badge bg-danger rounded-pill">NEW</span>*/}
                    </button>
                  </div>
                </li>
              )}
            {userData &&
              userData.roles[0].permissions.some(
                (item) => item.name === "All Users"
              ) && (
                <li className="">
                  <div className="d-grid gap-2">
                    {/* <Link to='/create-user' style={{ textDecoration: "none" }}> */}
                    <button
                      type="button"
                      className=" admin-btn nav-link d-flex align-items-center gap-1"
                      id="create-user-link"
                      fdprocessedid="dyv9n"
                      data-bs-dismiss="offcanvas"
                      onClick={() => {
                        setDisplayComponent((prev) => {
                          setPrevDisplay(prev);
                          return "manage-users";
                        });
                        window.history.pushState(
                          { prevState: "prev" },
                          "title",
                          "/event-portal?page=manage-users"
                        );
                      }}
                    >
                      <i className="bi bi-people"></i>{" "}
                      {pageTranslations.manageUsers[language]}
                    </button>
                    {/* </Link> */}
                  </div>
                </li>
              )}
            {false && (
              <>
                {userData &&
                  userData.roles[0].permissions.some(
                    (item) => item.name === "Event Report"
                  ) && (
                    <li className="">
                      <div className="d-grid gap-2">
                        <button
                          type="button"
                          className="admin-btn nav-link d-flex align-items-center gap-1"
                          id="add-dlr-link"
                          fdprocessedid="4u2e3"
                          Concession
                          data-bs-dismiss="offcanvas"
                          onClick={() => {
                            setDisplayComponent((prev) => {
                              setPrevDisplay(prev);
                              return "view-event-report";
                            });
                            window.history.pushState(
                              {
                                id: "gallery",
                                randomData: window.Math.random(),
                              },
                              "title",
                              "/event-portal?page=view-event-report"
                              // "/event-portal?page=view-event-report&event_id=35"
                            );
                          }}
                        >
                          <i className="bi bi-file-earmark-bar-graph"></i>{" "}
                          {pageTranslations.eventReports[language]}{" "}
                          {/*<span className="badge bg-danger rounded-pill">NEW</span>*/}
                        </button>
                      </div>
                    </li>
                  )}
                {userData &&
                  userData.roles[0].permissions.some(
                    (item) => item.name === "Contract And Billing"
                  ) && (
                    <li className="">
                      <div className="d-grid gap-2">
                        <button
                          type="button"
                          className="admin-btn nav-link d-flex align-items-center gap-1"
                          id="add-dlr-link"
                          fdprocessedid="4u2e3"
                          Concession
                          data-bs-dismiss="offcanvas"
                          onClick={() => {
                            setDisplayComponent((prev) => {
                              setPrevDisplay(prev);
                              return "view-billing-report";
                            });
                            window.history.pushState(
                              {
                                id: "gallery",
                                randomData: window.Math.random(),
                              },
                              "title",
                              "/event-portal?page=view-billing-report"
                            );
                          }}
                        >
                          <i className="bi bi-file-earmark-bar-graph"></i>{" "}
                          {pageTranslations.billingReport[language]}{" "}
                          {/*<span className="badge bg-danger rounded-pill">NEW</span>*/}
                        </button>
                      </div>
                    </li>
                  )}{" "}
              </>
            )}
            {userData &&
              userData.roles[0].permissions.some(
                (item) => item.name === "Sales Report"
              ) && (
                <li className="">
                  <div className="d-grid gap-2">
                    <button
                      type="button"
                      className="admin-btn nav-link d-flex align-items-center gap-1"
                      id="add-dlr-link"
                      fdprocessedid="4u2e3"
                      Concession
                      data-bs-dismiss="offcanvas"
                      onClick={() => {
                        setDisplayComponent((prev) => {
                          setPrevDisplay(prev);
                          return "view-sales-report";
                        });
                        window.history.pushState(
                          {
                            id: "gallery",
                            randomData: window.Math.random(),
                          },
                          "title",
                          "/event-portal?page=view-sales-report"
                        );
                      }}
                    >
                      <i className="bi bi-receipt"></i>{" "}
                      {pageTranslations.salesReport[language]}{" "}
                      {/*<span className="badge bg-danger rounded-pill">NEW</span>*/}
                    </button>
                  </div>
                </li>
              )}

            {userType &&
              internalUsers.includes(userType) &&
              userType !== "Animator" &&
              userType !== "Agent" && (
                <li className="">
                  <div className="d-grid gap-2">
                    <button
                      type="button"
                      className="admin-btn nav-link d-flex align-items-center gap-1"
                      id="add-dlr-link"
                      fdprocessedid="4u2e3"
                      Concession
                      data-bs-dismiss="offcanvas"
                      onClick={() => {
                        setDisplayComponent((prev) => {
                          setPrevDisplay(prev);
                          return "status-report";
                        });
                        window.history.pushState(
                          {
                            id: "gallery",
                            randomData: window.Math.random(),
                          },
                          "title",
                          "/event-portal?page=status-report"
                        );
                      }}
                    >
                      <i className="bi bi-table"></i>{" "}
                      {pageTranslations.statusReport[language]}{" "}
                      {/*<span className="badge bg-danger rounded-pill">NEW</span>*/}
                    </button>
                  </div>
                </li>
              )}
            {userType &&
              internalUsers.includes(userType) &&
              userType !== "Animator" &&
              userType !== "Agent" && (
                <li className="">
                  <div className="d-grid gap-2">
                    <button
                      type="button"
                      className="admin-btn nav-link d-flex align-items-center gap-1"
                      id="add-dlr-link"
                      fdprocessedid="4u2e3"
                      Concession
                      data-bs-dismiss="offcanvas"
                      onClick={() => {
                        setDisplayComponent((prev) => {
                          setPrevDisplay(prev);
                          return "black-list";
                        });
                        window.history.pushState(
                          {
                            id: "gallery",
                            randomData: window.Math.random(),
                          },
                          "title",
                          "/event-portal?page=black-list"
                        );
                      }}
                    >
                      <i className="bi bi-card-list"></i>{" "}
                      {pageTranslations.blackList[language]}{" "}
                      {/*<span className="badge bg-danger rounded-pill">NEW</span>*/}
                    </button>
                  </div>
                </li>
              )}
            {userType &&
              internalUsers.includes(userType) &&
              userType !== "Animator" &&
              userType !== "Agent" &&
              userType !== "Project Manager" &&
              userType !== "Representative" && (
                <li className="">
                  <div className="d-grid gap-2">
                    <button
                      type="button"
                      className="admin-btn nav-link d-flex align-items-center gap-1"
                      id="add-dlr-link"
                      fdprocessedid="4u2e3"
                      Concession
                      data-bs-dismiss="offcanvas"
                      onClick={() => {
                        setDisplayComponent((prev) => {
                          setPrevDisplay(prev);
                          return "agent-bonus-report";
                        });
                        window.history.pushState(
                          {
                            id: "gallery",
                            randomData: window.Math.random(),
                          },
                          "title",
                          "/event-portal?page=agent-bonus-report"
                        );
                      }}
                    >
                      <i className="bi bi-list-task"></i>{" "}
                      {pageTranslations.agentBonusReport[language]}{" "}
                      {/*<span className="badge bg-danger rounded-pill">NEW</span>*/}
                    </button>
                  </div>
                </li>
              )}
            {userType &&
              internalUsers.includes(userType) &&
              userType !== "Animator" &&
              userType !== "Agent" &&
              userType !== "Project Manager" &&
              userType !== "Representative" && (
                <li className="">
                  <div className="d-grid gap-2">
                    <button
                      type="button"
                      className="admin-btn nav-link d-flex align-items-center gap-1"
                      id="add-dlr-link"
                      fdprocessedid="4u2e3"
                      Concession
                      data-bs-dismiss="offcanvas"
                      onClick={() => {
                        setDisplayComponent((prev) => {
                          setPrevDisplay(prev);
                          return "bonus-confirmation-report";
                        });
                        window.history.pushState(
                          {
                            id: "gallery",
                            randomData: window.Math.random(),
                          },
                          "title",
                          "/event-portal?page=bonus-confirmation-report"
                        );
                      }}
                    >
                      <i className="bi bi-list-columns-reverse"></i>{" "}
                      {pageTranslations.bonusConfirmationReport[language]}{" "}
                      {/*<span className="badge bg-danger rounded-pill">NEW</span>*/}
                    </button>
                  </div>
                </li>
              )}
            {/* <li className="nav-item">
              <div className="d-grid gap-2">
                <button
                  type="button"
                  className="admin-btn nav-link"
                  id="manage-notif-link"
                  fdprocessedid="3bt1kb"
                  data-bs-dismiss="offcanvas"
                  onClick={() => {
                    setDisplayComponent((prev) => {
                      setPrevDisplay(prev);
                      return "notifications";
                    });
                    window.history.pushState(
                      {
                        id: "notif management",
                        randomData: window.Math.random(),
                      },
                      "title",
                      "/event-portal?page=notifications"
                    );
                  }}
                >
                  <i className="bi bi-bell"></i>{" "}
                  {pageTranslations.manageNotification[language]}{" "}
                  <span className="badge bg-danger rounded-pill">NEW</span>
                </button>
              </div>
            </li> */}

            {userType && externalUsers.includes(userType) && (
              <li className="">
                <div className="d-grid gap-2">
                  {/* <Link to='/create-user' style={{ textDecoration: "none" }}> */}
                  <button
                    type="button"
                    className=" admin-btn nav-link d-flex align-items-center gap-1"
                    id="create-user-link"
                    fdprocessedid="dyv9n"
                    data-bs-dismiss="offcanvas"
                    onClick={() => {
                      setDisplayComponent((prev) => {
                        setPrevDisplay(prev);
                        return "view-submissons";
                      });
                      window.history.pushState(
                        { prevState: "prev" },
                        "title",
                        "/event-portal?page=view-submissons"
                      );
                    }}
                  >
                    <i className="bi bi-people"></i>{" "}
                    {pageTranslations.viewClientSubmissions[language]}
                  </button>
                  {/* </Link> */}
                </div>
              </li>
            )}
            {/* <li className="">
              <div className="d-grid gap-2">
                

                <button
                  type="button"
                  className="btn admin-btn nav-link d-flex align-items-center gap-1"
                  id="manage-notif-link"
                  data-bs-toggle="modal"
                  data-bs-target="#changePasswordModal"
                  fdprocessedid="3bt1kb"
                  data-bs-dismiss="offcanvas"
                ><i class="bi bi-lock"></i>
                  {pageTranslations.changePassword[language]}
                </button>
                
              </div>
            </li> */}
            <li className="">
              <div className="d-grid gap-2">
                <hr
                  className=""
                  style={{
                    border: isDarkMode
                      ? "1px solid #ffffff"
                      : "1px solid #000000",
                    marginBlock: "2px",
                  }}
                />
                <button
                  type="button"
                  className="btn text-black nav-link d-flex align-items-center gap-1"
                  id="manage-notif-link"
                  fdprocessedid="3bt1kb"
                  data-bs-dismiss="offcanvas"
                  onClick={handleLogout}
                  style={{
                    backgroundColor: "#DC3444",
                    fontSize: "1rem",
                    borderRadius: "0.375rem",
                    padding: "0.375rem 0.75rem",
                    textDecoration: "none",
                  }}
                >
                  <i className="bi bi-box-arrow-left"></i>{" "}
                  {pageTranslations.logout[language]}{" "}
                </button>
              </div>
            </li>
          </ul>
          {/* <div
            style={{
              width: 350,
              paddingTop: 30,
              margin: "0 auto",
              opacity: 0.3,
              filter: "alpha(opacity=30), blur(5px)",
            }}
          >
            <img style={{ width: 350 }} src={people} alt="People working" />
          </div> */}
        </div>
        <div className="offcanvas-footer text-black text-center w-75  mx-auto">
          <p className="fw-lighter smalltext">
            {translations.footerTxt1[language]}© {currentYear} FIDGI
            Communications. {translations.footerTxt2[language]}
          </p>
        </div>
      </div>
      <ModalAdminQuickAdd
        setDisplayComponent={setDisplayComponent}
        setEventTrigger={setEventTrigger}
        dealerships={dealerships}
        setDealerTrigger={setDealerTrigger}
        isDarkMode={isDarkMode}
      />
      <ChangePasswordModal setIsLoading={setIsLoading} />
    </div>
  );
};

export default AdminNav;
