import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useLanguage } from "../../../languageContext";
import translations from "../../../translation.json";
import { toast } from "react-toastify";
import { ApiUrl } from "../../../ApiUrl";
import Select from "react-select";
import { format } from "date-fns";
import Send2Rep from "./send2Rep";
import { useNavigate } from "react-router-dom";

const EditAppointmenModal = ({
  isDarkMode,
  appointmentData,
  seAppointmentData,
  eventId,
  setIsLoading,
  appointments,
  setAppointments,
  setDisplayComponent,
  appointments2,
  setAppointments2,
}) => {
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const { language } = useLanguage();
  const [repOptions, setRepOptions] = useState([]);
  const [prospectData, setProspectData] = useState(null);
  const [selectedRep, setSelectedRep] = useState({});

  var button = document.getElementById("closeEditAppointmentModal");

  const confirmationStatusOptions = [
    {
      label:
        translations.createProspective.confirmationSatusOptions.opt1[language],
      value: "CONFIRMED",
    },
    {
      label:
        translations.createProspective.confirmationSatusOptions.opt2[language],
      value: "WANTCB",
    },
    {
      label:
        translations.createProspective.confirmationSatusOptions.opt3[language],
      value: "MSG LEFT",
    },
    {
      label:
        translations.createProspective.confirmationSatusOptions.opt4[language],
      value: "NO ANSWER",
    },
    {
      label:
        translations.createProspective.confirmationSatusOptions.opt5[language],
      value: "SAVED",
    },
    {
      label:
        translations.createProspective.confirmationSatusOptions.opt6[language],
      value: "CANCEL",
    },
  ];
  const visitStatusOptions = [
    {
      label: translations.createProspective.visitStatusOptions.opt1[language],
      value: translations.createProspective.visitStatusOptions.opt1["en"],
    },
    {
      label: translations.createProspective.visitStatusOptions.opt2[language],
      value: translations.createProspective.visitStatusOptions.opt2["en"],
    },
    {
      label: translations.createProspective.visitStatusOptions.opt3[language],
      value: translations.createProspective.visitStatusOptions.opt3["en"],
    },
    {
      label: translations.createProspective.visitStatusOptions.opt4[language],
      value: translations.createProspective.visitStatusOptions.opt4["en"],
    },
    {
      label: translations.createProspective.visitStatusOptions.opt5[language],
      value: translations.createProspective.visitStatusOptions.opt5["en"],
    },
    {
      label: translations.createProspective.visitStatusOptions.opt6[language],
      value: translations.createProspective.visitStatusOptions.opt6["en"],
    },
  ];
  const noShowOptions = [
    {
      label: translations.createProspective.noShowStatusOptions.opt1[language],
      value: translations.createProspective.noShowStatusOptions.opt1["en"],
    },
    {
      label: translations.createProspective.noShowStatusOptions.opt2[language],
      value: translations.createProspective.noShowStatusOptions.opt2["en"],
    },
    {
      label: translations.createProspective.noShowStatusOptions.opt3[language],
      value: translations.createProspective.noShowStatusOptions.opt3["en"],
    },
    {
      label: translations.createProspective.noShowStatusOptions.opt4[language],
      value: translations.createProspective.noShowStatusOptions.opt4["en"],
    },
    {
      label: translations.createProspective.noShowStatusOptions.opt5[language],
      value: translations.createProspective.noShowStatusOptions.opt5["en"],
    },
    {
      label: translations.createProspective.noShowStatusOptions.opt6[language],
      value: translations.createProspective.noShowStatusOptions.opt6["en"],
    },
  ];
  // const initialData = {};

  useEffect(() => {
    const getExternalUsers = async () => {
      // setIsLoading(true);
      try {
        const res = await fetch(ApiUrl + "/api/user/external-user/" + eventId, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await res.json();
        if (data.status === "success") {
          const repData = data?.externalUsers?.length
            ? data?.externalUsers?.map((list) => ({
                label: list.appointment_rep_user_name,
                value: list.appointment_rep_user_name,
              }))
            : [];
          setRepOptions(repData);
        } else {
          toast.error(data.message);
        }
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        toast.error("Something went wrong");
      } finally {
      }
    };
    const getProspectData = async () => {
      // setIsLoading(true);
      try {
        const res = await fetch(
          ApiUrl +
            "/api/user/prospectives/" +
            appointmentData.appointment?.prospective?.id,

          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = await res.json();
        if (data.status === "success") {
          setProspectData(data?.prospective);
        } else {
          toast.error(data.message);
        }
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        toast.error("Something went wrong");
      } finally {
      }
    };
    if (eventId && appointmentData.appointment?.prospective?.id) {
      getExternalUsers();
      getProspectData();
    }
  }, [eventId, appointmentData]);

  const initialData = {
    activix_id: prospectData ? prospectData?.activix_id : "",
    dialer_id: prospectData ? prospectData?.dialer_id : "",
    list_id: prospectData ? prospectData?.private_event.list_id : "",
    private_event_id: prospectData ? prospectData?.private_event_id : eventId,
    confirmation_agent_name: prospectData
      ? prospectData?.appointments[0]?.confirmation_agent_name
      : "",
    prospect_type: prospectData ? prospectData?.prospect_type : "",
    first_name: prospectData ? prospectData?.first_name : "",
    last_name: prospectData ? prospectData?.last_name : "",
    phone: prospectData ? prospectData?.phone : "",
    make: prospectData ? prospectData?.make : "",
    model: prospectData ? prospectData?.model : "",
    year: prospectData ? prospectData?.year : "",
    appointment_date: prospectData
      ? prospectData?.appointments[0]?.appointment_date
      : "",
    appointment_entry_date: prospectData
      ? prospectData?.appointments[0]?.appointment_entry_date
      : "",
    appointment_rep_user_name: prospectData
      ? prospectData?.appointments[0]?.appointment_rep_user_name
      : "",
    appointment_agent_name: prospectData
      ? prospectData?.appointments[0]?.appointment_agent_name
      : "",
    appointment_time: prospectData
      ? prospectData.appointments[0]?.appointment_time
      : "",
    appointment_comment: prospectData
      ? prospectData?.appointments[0]?.appointment_comment
      : "",
    // appointment_status:
    //   editMode && prospectData
    //     ? prospectData.appointments[0]?.appointment_status
    //     : "",
    appointment_status_notes: prospectData
      ? prospectData?.appointments[0]?.appointment_status_notes
      : "",
    confirmation_status: prospectData
      ? prospectData?.appointments[0]?.confirmation_status
      : "",
    confirmation_notes: prospectData
      ? prospectData?.appointments[0]?.confirmation_notes
      : "",
    prospect_source: prospectData ? prospectData?.prospect_source : "",
    greetings: prospectData ? prospectData?.greetings : "",
    mobile_phone: prospectData ? prospectData?.mobile_phone : "",
    office_phone: prospectData ? prospectData?.office_phone : "",
    address: prospectData ? prospectData?.address : "",
    city: prospectData ? prospectData?.city : "",
    province: prospectData ? prospectData?.province : "",
    postal_code: prospectData ? prospectData.postal_code : "",
    email: prospectData ? prospectData?.email : "",
    language: prospectData ? prospectData?.language : "",
    purchase_date: prospectData ? prospectData?.purchase_date : "",
    last_service_date: prospectData ? prospectData?.last_service_date : "",
    km: prospectData ? prospectData?.km : "",
    vin: prospectData ? prospectData?.vin : "",
    call_status: prospectData ? prospectData?.call_status : "",
    confirmation_status_entry_date: prospectData
      ? prospectData?.appointments[0]?.confirmation_status_entry_date
      : "",
    visit_status: prospectData
      ? prospectData?.appointments[0]?.visit_status
      : "",
    visit_status_entry_date: prospectData
      ? prospectData?.appointments[0]?.visit_status_entry_date
      : "",
    visit_notes: prospectData ? prospectData?.appointments[0]?.visit_notes : "",
    visit_status_user_name: prospectData
      ? prospectData?.appointments[0]?.visit_status_user_name
      : "",
    no_show_status: prospectData
      ? prospectData.appointments[0]?.no_show_status
      : "",
    no_show_status_entry_date: prospectData
      ? prospectData?.appointments[0]?.no_show_status_entry_date
      : "",
    no_show_notes: prospectData
      ? prospectData?.appointments[0]?.no_show_notes
      : "",
    no_show_user_name: prospectData
      ? prospectData?.appointments[0]?.no_show_user_name
      : "",
    appointment: prospectData ? prospectData?.appointment : "",
    og_rep: prospectData ? prospectData?.og_rep : "",
    // appointment_id: editMode && prospectData ? prospectData.appointments[0]?.id : "",
  };
  // console.log(appointments);

  // console.log(appointmentData);

  // const initialData = {
  //   dialer_id:
  //     appointmentData && appointmentData ? appointmentData.dialer_id : "",
  //   activix_id:
  //     appointmentData && appointmentData ? appointmentData.activix_id : "",
  //   private_event_id: eventId,
  //   prospect_type:
  //     appointmentData && appointmentData
  //       ? appointmentData.appointments[0]?.prospect_type
  //       : "",
  //   first_name:
  //     appointmentData && appointmentData
  //       ? appointmentData.appointments[0]?.first_name
  //       : "",
  //   last_name:
  //     appointmentData && appointmentData
  //       ? appointmentData.appointments[0]?.last_name
  //       : "",
  //   phone:
  //     appointmentData && appointmentData
  //       ? appointmentData.appointments[0]?.phone
  //       : "",
  //   make:
  //     appointmentData && appointmentData
  //       ? appointmentData.appointments[0]?.make
  //       : "",
  //   model:
  //     appointmentData && appointmentData
  //       ? appointmentData.appointments[0]?.model
  //       : "",
  //   year:
  //     appointmentData && appointmentData
  //       ? appointmentData.appointments[0]?.year
  //       : "",
  //   appointment_date:
  //     appointmentData && appointmentData
  //       ? appointmentData.appointments[0]?.appointment_date
  //       : "",
  //   appointment_time:
  //     appointmentData && appointmentData
  //       ? appointmentData.appointments[0]?.appointment_time
  //       : "",
  //   appointment_comment:
  //     appointmentData && appointmentData
  //       ? appointmentData.appointments[0]?.appointment_comment
  //       : "",
  //   appointment_status:
  //     appointmentData && appointmentData
  //       ? appointmentData.appointments[0]?.appointment_status
  //       : "",
  //   appointment_status_notes:
  //     appointmentData && appointmentData
  //       ? appointmentData.appointments[0]?.appointment_status_notes
  //       : "",
  //   confirmation_status:
  //     appointmentData && appointmentData
  //       ? appointmentData.appointments[0]?.confirmation_status
  //       : "",
  //   confirmation_notes:
  //     appointmentData && appointmentData
  //       ? appointmentData.appointments[0]?.confirmation_notes
  //       : "",
  //   prospect_source:
  //     appointmentData && appointmentData ? appointmentData.prospect_source : "",
  //   greetings:
  //     appointmentData && appointmentData ? appointmentData.greetings : "",
  //   mobile_phone:
  //     appointmentData && appointmentData ? appointmentData.mobile_phone : "",
  //   office_phone: appointmentData ? appointmentData.office_phone : "",
  //   address: appointmentData ? appointmentData.address : "",
  //   city: appointmentData ? appointmentData.city : "",
  //   province: appointmentData ? appointmentData.province : "",
  //   postal_code: appointmentData ? appointmentData.postal_code : "",
  //   email: appointmentData ? appointmentData.email : "",
  //   purchase_date: appointmentData ? appointmentData.purchase_date : "",
  //   last_service_date: appointmentData ? appointmentData.last_service_date : "",
  //   km: appointmentData ? appointmentData.km : "",
  //   vin: appointmentData ? appointmentData.vin : "",
  //   call_status: appointmentData ? appointmentData.call_status : "",
  //   confirmation_status_entry_date: appointmentData
  //     ? appointmentData.confirmation_status_entry_date
  //     : "",
  //   visit_status: appointmentData ? appointmentData.visit_status : "",
  //   visit_status_entry_date: appointmentData
  //     ? appointmentData.visit_status_entry_date
  //     : "",
  //   visit_notes: appointmentData ? appointmentData.visit_notes : "",
  //   no_show_status: appointmentData ? appointmentData.no_show_status : "",
  //   no_show_status_entry_date: appointmentData
  //     ? appointmentData.no_show_status_entry_date
  //     : "",
  //   no_show_notes: appointmentData ? appointmentData.no_show_notes : "",
  // };

  const changeDropdown = async (statusData) => {
    setIsLoading(true);
    try {
      const url = `${ApiUrl}/api/user/appointment-change-status`;
      const bodyData = {
        ...statusData,
        // dialer_id: prospectData.dialer_id,
        id: prospectData.appointments[0]?.id,
      };
      const method = "POST";
      const res = await fetch(url, {
        method,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(bodyData),
      });
      // console.log("res", res)
      const data = await res.json();
      // console.log("data", data)
      if (data.status === "success") {
        if (appointments2) {
          const newData =
            appointments2 &&
            appointments2.map((data) =>
              data.id === appointmentData.appointment.id
                ? {
                    ...data,

                    ...statusData, // Merge the new values from statusData
                  }
                : data
            );
          const filteredData = newData.filter(
            (appointment) =>
              appointment?.data?.appointment?.confirmation_status?.toLowerCase() !==
              "cancel"
          );

          // Update the state
          setAppointments2(filteredData);
        } else {
          const newData =
            appointments &&
            appointments.map((data) =>
              data.id === appointmentData.appointment.id
                ? {
                    ...data,
                    data: {
                      ...data.data,
                      appointment: {
                        ...data.data.appointment,
                        ...statusData, // Merge the new values from statusData
                      },
                    },
                  }
                : data
            );

          // Filter out appointments where confirmation_status is "cancel"
          const filteredData = newData.filter(
            (appointment) =>
              appointment?.data?.appointment?.confirmation_status?.toLowerCase() !==
              "cancel"
          );

          // Update the state
          setAppointments(filteredData);
        }

        toast.success(data.message);
        // button.click();
        // navigate("/event-portal");
      } else {
        toast.error(data.message);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      toast.error("Something went wrong!");
    }
  };
  const handleSubmit = async (values, { resetForm }, error) => {
    setIsLoading(true);
    try {
      const url = `${ApiUrl}/api/user/prospectives${`/${prospectData?.id}`}`;
      const bodyData = {
        ...values,
        // dialer_id: prospectData.dialer_id,
        appointment_id: prospectData.appointments[0]?.id,
      };
      const method = "PUT";
      const res = await fetch(url, {
        method,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(bodyData),
      });
      // console.log("res", res)
      const data = await res.json();
      // console.log("data", data)
      if (data.status === "success") {
        toast.success(data.message);
        // const newData =
        //   appointments &&
        //   appointments.map((data) =>
        //     data.id === appointmentData.appointment.id
        //       ? {
        //           ...data,
        //           data: {
        //             ...data.data,
        //             appointment: {
        //               ...data.data.appointment,
        //               ...values
        //             },
        //           },
        //           resourceId: values?.appointment_rep_user_name
        //         }
        //       : data
        //   );

        // // Filter out appointments where confirmation_status is "cancel"
        // const filteredData = newData.filter(
        //   (appointment) =>
        //     appointment?.data?.appointment?.confirmation_status?.toLowerCase() !==
        //     "cancel"
        // );

        // // Update the state
        // setAppointments(filteredData);
        button.click();
        window.location.reload();
        // navigate("/event-portal?page=appointments&eventId=" + eventId);
      } else {
        toast.error(data.message);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      toast.error("Something went wrong!");
    }
  };

  // console.log(appointments2);

  return (
    <>
      <div id="editAppointmentModal" className="modal fade" tabindex="-1">
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          style={{ maxWidth: "700px" }}
        >
          <div className="modal-content">
            <Formik
              initialValues={initialData}
              // validationSchema={validationSchema}
              onSubmit={handleSubmit}
              id="creationsuccess"
              enableReinitialize={true}
            >
              {({ errors, values, setValues, resetForm, isSubmitting }) => (
                <Form>
                  <div
                    className="modal-header d-flex flex-column align-items-stretch"
                    style={{
                      borderBottom: isDarkMode
                        ? "1px solid #495057"
                        : "1px solid #dee2e6",
                    }}
                  >
                    {/* First div with close button aligned to the end */}

                    {/* Second div with heading and another close button justified between */}
                    <div className="d-flex  justify-content-between">
                      <div className="d-flex flex-col w-100">
                        <div>
                          {/* <h3 style={{ color: "#000000" }}>
                        {translations.appointments.appointmentDetails[language]}
                      </h3> */}
                          <h3 className="darkthead">
                            <i
                              className="bi bi-person-fill me-2"
                              style={{ color: "#32DBB4 !important" }}
                            ></i>
                            {appointmentData?.appointment?.prospective
                              ?.first_name +
                              " " +
                              appointmentData?.appointment?.prospective
                                ?.last_name}
                          </h3>
                          <div className="mb-2">
                            <div className="d-flex mb-1 fs-5">
                              {/* <!-- Appointment Date, Appointment Time from appointment Table --> */}
                              <i
                                className="bi bi-calendar"
                                style={{ color: "#32DBB4" }}
                              ></i>
                              &nbsp;
                              {appointmentData?.appointment?.appointment_date &&
                              appointmentData?.appointment?.appointment_date !==
                                null
                                ? appointmentData.appointment.appointment_date
                                : ""}{" "}
                              &nbsp;&nbsp;
                              {/* format(
                                    appointmentData?.appointment
                                      ?.appointment_date,
                                    "dd/MM/yyyy"
                                  ) */}
                              <i
                                className="bi bi-clock-fill me-2"
                                style={{ color: "#32DBB4" }}
                              ></i>
                              {appointmentData?.start &&
                              appointmentData?.start !== null
                                ? format(appointmentData?.start, "hh:mm aa")
                                : ""}
                            </div>
                            {appointmentData?.rep_name !== null && (
                              <div className="d-flex mb-1 fs-5">
                                {/* <!-- Appointment Representative from appointment Table --> */}
                                {/* <!-- You can use a better icon here maybe a tie or a person with a suite-->  */}
                                <i
                                  className="bi bi-suitcase-lg-fill me-2"
                                  style={{ color: "#32DBB4" }}
                                ></i>
                                {appointmentData?.rep_name}
                              </div>
                            )}

                            {prospectData?.make !== null &&
                              prospectData?.model !== null &&
                              prospectData?.year !== null && (
                                <div className="d-flex mb-1 fs-5">
                                  {/* Make,Model, and Year from Prospect Table */}
                                  <i
                                    className="bi bi-car-front-fill me-2"
                                    style={{ color: "#32DBB4" }}
                                  ></i>
                                  {prospectData?.make +
                                    " " +
                                    prospectData?.model +
                                    " " +
                                    prospectData?.year}
                                </div>
                              )}
                          </div>
                          <div className="d-flex">
                            <button
                              className="btn btn-light w-50 me-2 d-flex align-items-center"
                              onClick={() => {
                                document
                                  .querySelectorAll(".modal-backdrop")
                                  .forEach((backdrop) => {
                                    backdrop.remove();
                                  });
                                setDisplayComponent("edit-appointment");
                                //   setEditEventID(event.id);
                                window.history.pushState(
                                  {
                                    id: "gallery",
                                    randomData: window.Math.random(),
                                  },
                                  "title",
                                  "/event-portal?page=edit-appointment&event_id=" +
                                    eventId +
                                    `&client_id=${appointmentData.appointment?.prospective?.id}`
                                );
                              }}
                            >
                              <i className="bi bi-person-circle me-2"></i>
                              {
                                translations.createProspective.prospective[
                                  language
                                ]
                              }
                            </button>
                            <button
                              className="btn admin-btn w-50 me-2 d-flex align-items-center"
                              data-bs-toggle="modal"
                              data-bs-target="#send2RepModal"
                              type="button"
                            >
                              <i className="bi bi-send-fill me-2"></i>Send2Rep
                            </button>
                          </div>
                        </div>
                      </div>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        id="closeEditAppointmentModal"
                        onClick={() => {
                          document
                            .querySelectorAll(".modal-backdrop")
                            .forEach((backdrop) => {
                              backdrop.remove();
                            });
                        }}
                        style={{
                          filter: isDarkMode ? "invert(1)" : "none",
                          color: isDarkMode
                            ? "#ffffff"
                            : "rgb(255, 255, 255) !important",
                        }}
                      ></button>
                    </div>
                  </div>

                  <div
                    className="modal-body"
                    style={{ height: "350px", overflowY: "scroll" }}
                  >
                    {/* <div className="row">
                    <div className="col">
                      <div className="mb-3">
                        <label for="annual-sales-count" className="form-label">
                          {translations.commonFields.firstName[language]}
                        </label>
                        <Field
                          type="text"
                          name="first_name"
                          className="form-control"
                          style={{
                            backgroundColor: isDarkMode ? "transparent" : "",
                            color: isDarkMode ? "#ffffff" : "#000000",
                            border: isDarkMode
                              ? "1px solid #495057"
                              : "1px solid #dee2e6",
                          }}
                        />

                        <ErrorMessage
                          name="first_name"
                          component="div"
                          className="error text-danger"
                        />
                      </div>
                    </div>
                    <div className="col">
                      <div className="mb-3">
                        <label for="annual-sales-count" className="form-label">
                          {translations.commonFields.lastName[language]}
                        </label>
                        <Field
                          type="text"
                          name="last_name"
                          className="form-control"
                          style={{
                            backgroundColor: isDarkMode ? "transparent" : "",
                            color: isDarkMode ? "#ffffff" : "#000000",
                            border: isDarkMode
                              ? "1px solid #495057"
                              : "1px solid #dee2e6",
                          }}
                        />
                        <ErrorMessage
                          name="last_name"
                          component="div"
                          className="error text-danger"
                        />
                      </div>
                    </div>
                  </div> */}
                    <div className="row">
                      <div className="mb-3 col">
                        <div
                          className="input-group d-flex align-items-center"
                          style={{
                            border: isDarkMode
                              ? "1px solid #495057"
                              : "1px solid #dee2e6",
                            borderRadius: ".45rem",
                          }}
                        >
                          <span
                            // for="annual-sales-count"
                            className="px-2 bg-transparent"
                            style={{
                              width: "30%",
                              border: "none",
                              backgroundColor: "#2b3035",
                            }}
                          >
                            {
                              translations.createProspective.confirmation[
                                language
                              ]
                            }
                          </span>
                          <Field
                            name="confirmation_status"
                            style={{ width: "70%" }}
                            render={({ field, form }) => (
                              <Select
                                className="selectpicker w-70"
                                aria-labelledby="dealership-name-quick"
                                // isClearable
                                options={confirmationStatusOptions}
                                placeholder={
                                  translations["dropdown"].select[language]
                                }
                                isSearchable
                                value={
                                  (confirmationStatusOptions &&
                                    confirmationStatusOptions.length > 0 &&
                                    confirmationStatusOptions.find(
                                      (option) =>
                                        // true
                                        option?.value?.toLowerCase() ===
                                        field?.value?.toLowerCase()
                                    )) || {
                                    value: "",
                                    label:
                                      translations["dropdown"].select[language],
                                  }
                                }
                                onChange={(selectedOption) => {
                                  form.setFieldValue(
                                    field.name,
                                    selectedOption.value
                                  );
                                  changeDropdown({
                                    confirmation_status: selectedOption.value,
                                  });
                                }}
                                maxMenuHeight={"120px"}
                                styles={{
                                  container: (provided) => ({
                                    ...provided,
                                    width: "70%", // Set the container width to 70%
                                  }),
                                  input: (provided) => ({
                                    ...provided,
                                    width: "100%",
                                    color: isDarkMode ? "#ffffff" : "black", // Color of the text when typing
                                  }),
                                  control: (provided, state) => ({
                                    ...provided,
                                    backgroundColor: isDarkMode
                                      ? "transparent"
                                      : "white",
                                    border: "none",
                                    borderLeft: state.isHovered
                                      ? "none"
                                      : state.isFocused
                                      ? "1px solid #80bdff"
                                      : isDarkMode
                                      ? "1px solid #495057"
                                      : "1px solid #dee2e6", // Adjust the border color for focus
                                    boxShadow: state.isFocused
                                      ? "0 0 0 0.2rem rgba(0, 123, 255, 0.25)"
                                      : "none", // Bootstrap's box shadow for focus
                                    // borderRadius: ".45rem",
                                  }),
                                  valueContainer: (provided) => ({
                                    ...provided,
                                    padding: "0.18rem 0.55rem", // Bootstrap's padding for the value container
                                  }),
                                  indicatorSeparator: (provided) => ({
                                    ...provided,
                                    display: "none", // Hide the indicator separator
                                  }),
                                  dropdownIndicator: (provided) => ({
                                    ...provided,
                                    padding: "0.375rem 0.75rem", // Bootstrap's padding for the dropdown indicator
                                    color: isDarkMode ? "#ffffff" : "#868686",
                                    display: "block",
                                  }),
                                  menu: (provided) => ({
                                    ...provided,
                                    color: isDarkMode ? "#ffffff" : "black",
                                    backgroundColor: isDarkMode
                                      ? "#1f2023"
                                      : "white",
                                    margin: "0", // Remove default margin
                                  }),
                                  placeholder: (provided) => ({
                                    ...provided,
                                    color: isDarkMode ? "#ffffff" : "#333333", // Specify your desired color for the placeholder
                                    // Additional styles for placeholder
                                  }),
                                  singleValue: (provided) => ({
                                    ...provided,
                                    color: isDarkMode ? "#ffffff" : "black",
                                  }),
                                  option: (provided, state) => ({
                                    ...provided,
                                    color: isDarkMode ? "#ffffff" : "black",
                                    backgroundColor: state.isHovered
                                      ? "#80bdff"
                                      : isDarkMode
                                      ? "#1f2023"
                                      : "white",
                                    ":hover": {
                                      backgroundColor: isDarkMode
                                        ? "#34DBB4"
                                        : "#34DBB4",
                                    },
                                  }),
                                }}
                              />
                            )}
                          />

                          <ErrorMessage
                            name="confirmation_status"
                            component="div"
                            className="error text-danger"
                          />
                        </div>
                        <div className="d-flex align-items-center gap-2 py-2">
                          <p className="fw-bold darkthead">
                            {values.confirmation_agent_name
                              ? `${translations.commonWords.by[language]}: ${values.confirmation_agent_name}`
                              : ""}
                          </p>
                          <p className="fw-bold darkthead">
                            {values.confirmation_status_entry_date
                              ? `${
                                  translations.commonWords.on[language]
                                }: ${format(
                                  values.confirmation_status_entry_date,
                                  "dd/MM/yyyy"
                                )}`
                              : ""}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <div className="mb-3">
                          <label
                            for="annual-sales-count"
                            className="form-label"
                          >
                            {
                              translations.createProspective.confirmationNotes[
                                language
                              ]
                            }
                          </label>
                          <Field
                            as="textarea"
                            type="text"
                            name="confirmation_notes"
                            className="form-control"
                            // rows={5}
                            style={{
                              backgroundColor: isDarkMode ? "transparent" : "",
                              color: isDarkMode ? "#ffffff" : "#000000",
                              border: isDarkMode
                                ? "1px solid #495057"
                                : "1px solid #dee2e6",
                            }}
                          />

                          <ErrorMessage
                            name="confirmation_notes"
                            component="div"
                            className="error text-danger"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="mb-3 col">
                        <div
                          className="input-group d-flex align-items-center"
                          style={{
                            border: isDarkMode
                              ? "1px solid #495057"
                              : "1px solid #dee2e6",
                            borderRadius: ".45rem",
                          }}
                        >
                          <span
                            // for="annual-sales-count"
                            className="px-2 bg-transparent"
                            style={{
                              width: "30%",
                              border: "none",
                              backgroundColor: "#2b3035",
                            }}
                          >
                            {translations.createProspective.visit[language]}
                          </span>

                          <Field
                            name="visit_status"
                            render={({ field, form }) => (
                              <Select
                                className="selectpicker"
                                // isClearable
                                options={visitStatusOptions}
                                placeholder={
                                  translations["dropdown"].select[language]
                                }
                                isSearchable
                                value={
                                  (visitStatusOptions &&
                                    visitStatusOptions.length > 0 &&
                                    visitStatusOptions.find(
                                      (option) => option.value === field.value
                                    )) || {
                                    value: "",
                                    label:
                                      translations["dropdown"].select[language],
                                  }
                                }
                                onChange={(selectedOption) => {
                                  form.setFieldValue(
                                    field.name,
                                    selectedOption.value
                                  );
                                  changeDropdown({
                                    visit_status: selectedOption.value,
                                  });
                                }}
                                maxMenuHeight={"85px"}
                                styles={{
                                  container: (provided) => ({
                                    ...provided,
                                    width: "70%", // Set the container width to 70%
                                  }),
                                  input: (provided) => ({
                                    ...provided,
                                    color: isDarkMode ? "#ffffff" : "black", // Color of the text when typing
                                  }),
                                  control: (provided, state) => ({
                                    ...provided,
                                    backgroundColor: isDarkMode
                                      ? "transparent"
                                      : "white",
                                    border: "none",
                                    borderLeft: state.isHovered
                                      ? "none"
                                      : state.isFocused
                                      ? "1px solid #80bdff"
                                      : isDarkMode
                                      ? "1px solid #495057"
                                      : "1px solid #dee2e6", // Adjust the border color for focus
                                    boxShadow: state.isFocused
                                      ? "0 0 0 0.2rem rgba(0, 123, 255, 0.25)"
                                      : "none", // Bootstrap's box shadow for focus
                                    // borderRadius: ".45rem",
                                  }),
                                  valueContainer: (provided) => ({
                                    ...provided,
                                    padding: "0.18rem 0.55rem", // Bootstrap's padding for the value container
                                  }),
                                  indicatorSeparator: (provided) => ({
                                    ...provided,
                                    display: "none", // Hide the indicator separator
                                  }),
                                  dropdownIndicator: (provided) => ({
                                    ...provided,
                                    padding: "0.375rem 0.75rem", // Bootstrap's padding for the dropdown indicator
                                    color: isDarkMode ? "#ffffff" : "#868686",
                                  }),
                                  menu: (provided) => ({
                                    ...provided,
                                    color: isDarkMode ? "#ffffff" : "black",
                                    backgroundColor: isDarkMode
                                      ? "#1f2023"
                                      : "white",
                                    margin: "0", // Remove default margin
                                  }),
                                  placeholder: (provided) => ({
                                    ...provided,
                                    color: isDarkMode ? "#ffffff" : "#333333", // Specify your desired color for the placeholder
                                    // Additional styles for placeholder
                                  }),
                                  singleValue: (provided) => ({
                                    ...provided,
                                    color: isDarkMode ? "#ffffff" : "black",
                                  }),
                                  option: (provided, state) => ({
                                    ...provided,
                                    color: isDarkMode ? "#ffffff" : "black",
                                    backgroundColor: state.isHovered
                                      ? "#80bdff"
                                      : isDarkMode
                                      ? "#1f2023"
                                      : "white",
                                    ":hover": {
                                      backgroundColor: isDarkMode
                                        ? "#34DBB4"
                                        : "#34DBB4",
                                    },
                                  }),
                                }}
                              />
                            )}
                          />
                          <ErrorMessage
                            name="visit_status"
                            component="div"
                            className="error text-danger"
                          />
                        </div>
                        <div className="d-flex align-items-center gap-2 py-2">
                          <p className="fw-bold darkthead">
                            {values.visit_status_user_name
                              ? `${translations.commonWords.by[language]}: ${values.visit_status_user_name}`
                              : ""}
                          </p>
                          <p className="fw-bold darkthead">
                            {values.visit_status_entry_date
                              ? `${
                                  translations.commonWords.on[language]
                                }: ${format(
                                  values.visit_status_entry_date,
                                  "dd/MM/yyyy"
                                )}`
                              : ""}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="mb-3 col">
                        <label for="annual-sales-count" className="form-label">
                          {translations.createProspective.visitNotes[language]}
                        </label>
                        <Field
                          as="textarea"
                          type="text"
                          name="visit_notes"
                          className="form-control"
                          style={{
                            backgroundColor: isDarkMode ? "transparent" : "",
                            color: isDarkMode ? "#ffffff" : "#000000",
                            border: isDarkMode
                              ? "1px solid #495057"
                              : "1px solid #dee2e6",
                          }}
                        />
                        <ErrorMessage
                          name="visit_notes"
                          component="div"
                          className="error text-danger"
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="mb-3 col">
                        <div
                          className="input-group d-flex align-items-center"
                          style={{
                            border: isDarkMode
                              ? "1px solid #495057"
                              : "1px solid #dee2e6",
                            borderRadius: ".45rem",
                          }}
                        >
                          <span
                            // for="annual-sales-count"
                            className="px-2 bg-transparent"
                            style={{
                              width: "30%",
                              border: "none",
                              backgroundColor: "#2b3035",
                            }}
                          >
                            {
                              translations.createProspective.noShowSatus[
                                language
                              ]
                            }
                          </span>
                          <Field
                            name="no_show_status"
                            render={({ field, form }) => (
                              <Select
                                className="selectpicker"
                                // isClearable
                                options={noShowOptions}
                                placeholder={
                                  translations["dropdown"].select[language]
                                }
                                isSearchable
                                value={
                                  (noShowOptions &&
                                    noShowOptions.length > 0 &&
                                    noShowOptions.find(
                                      (option) => option.value === field.value
                                    )) || {
                                    value: "",
                                    label:
                                      translations["dropdown"].select[language],
                                  }
                                }
                                onChange={(selectedOption) => {
                                  form.setFieldValue(
                                    field.name,
                                    selectedOption.value
                                  );
                                  changeDropdown({
                                    no_show_status: selectedOption.value,
                                  });
                                }}
                                maxMenuHeight={"85px"}
                                styles={{
                                  container: (provided) => ({
                                    ...provided,
                                    width: "70%", // Set the container width to 70%
                                  }),
                                  input: (provided) => ({
                                    ...provided,
                                    color: isDarkMode ? "#ffffff" : "black", // Color of the text when typing
                                  }),
                                  control: (provided, state) => ({
                                    ...provided,
                                    backgroundColor: isDarkMode
                                      ? "transparent"
                                      : "white",
                                    border: "none",
                                    borderLeft: state.isHovered
                                      ? "none"
                                      : state.isFocused
                                      ? "1px solid #80bdff"
                                      : isDarkMode
                                      ? "1px solid #495057"
                                      : "1px solid #dee2e6", // Adjust the border color for focus
                                    boxShadow: state.isFocused
                                      ? "0 0 0 0.2rem rgba(0, 123, 255, 0.25)"
                                      : "none", // Bootstrap's box shadow for focus
                                    // borderRadius: ".45rem",
                                  }),
                                  valueContainer: (provided) => ({
                                    ...provided,
                                    padding: "0.18rem 0.55rem", // Bootstrap's padding for the value container
                                  }),
                                  indicatorSeparator: (provided) => ({
                                    ...provided,
                                    display: "none", // Hide the indicator separator
                                  }),
                                  dropdownIndicator: (provided) => ({
                                    ...provided,
                                    padding: "0.375rem 0.75rem", // Bootstrap's padding for the dropdown indicator
                                    color: isDarkMode ? "#ffffff" : "#868686",
                                  }),
                                  menu: (provided) => ({
                                    ...provided,
                                    color: isDarkMode ? "#ffffff" : "black",
                                    backgroundColor: isDarkMode
                                      ? "#1f2023"
                                      : "white",
                                    margin: "0", // Remove default margin
                                  }),
                                  placeholder: (provided) => ({
                                    ...provided,
                                    color: isDarkMode ? "#ffffff" : "#333333", // Specify your desired color for the placeholder
                                    // Additional styles for placeholder
                                  }),
                                  singleValue: (provided) => ({
                                    ...provided,
                                    color: isDarkMode ? "#ffffff" : "black",
                                  }),
                                  option: (provided, state) => ({
                                    ...provided,
                                    color: isDarkMode ? "#ffffff" : "black",
                                    backgroundColor: state.isHovered
                                      ? "#80bdff"
                                      : isDarkMode
                                      ? "#1f2023"
                                      : "white",
                                    ":hover": {
                                      backgroundColor: isDarkMode
                                        ? "#34DBB4"
                                        : "#34DBB4",
                                    },
                                  }),
                                }}
                              />
                            )}
                          />
                          <ErrorMessage
                            name="no_show_status"
                            component="div"
                            className="error text-danger"
                          />
                        </div>
                        <div className="d-flex align-items-center gap-2 py-2">
                          <p className="fw-bold darkthead">
                            {values.no_show_user_name
                              ? `${translations.commonWords.by[language]}: ${values.no_show_user_name}`
                              : ""}
                          </p>
                          <p className="fw-bold darkthead">
                            {values.no_show_status_entry_date
                              ? `${
                                  translations.commonWords.on[language]
                                }: ${format(
                                  values.no_show_status_entry_date,
                                  "dd/MM/yyyy"
                                )}`
                              : ""}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="mb-3 col">
                        <label for="annual-sales-count" className="form-label">
                          {translations.createProspective.noShowNotes[language]}
                        </label>
                        <Field
                          as="textarea"
                          type="text"
                          name="no_show_notes"
                          className="form-control"
                          style={{
                            backgroundColor: isDarkMode ? "transparent" : "",
                            color: isDarkMode ? "#ffffff" : "#000000",
                            border: isDarkMode
                              ? "1px solid #495057"
                              : "1px solid #dee2e6",
                          }}
                        />
                        <ErrorMessage
                          name="no_show_notes"
                          component="div"
                          className="error text-danger"
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="mb-4 col">
                        <div
                          className="input-group d-flex align-items-center"
                          style={{
                            border: isDarkMode
                              ? "1px solid #495057"
                              : "1px solid #dee2e6",
                            borderRadius: ".45rem",
                          }}
                        >
                          <span
                            // for="annual-sales-count"
                            className="px-2 bg-transparent"
                            style={{
                              width: "30%",
                              border: "none",
                              backgroundColor: "#2b3035",
                            }}
                          >
                            {
                              translations.createProspective
                                .appointmentRepresentative[language]
                            }
                          </span>

                          <Field
                            name="appointment_rep_user_name"
                            render={({ field, form }) => (
                              <Select
                                className="selectpicker"
                                // isClearable
                                options={repOptions}
                                placeholder={
                                  translations["dropdown"].select[language]
                                }
                                isSearchable
                                value={
                                  (repOptions &&
                                    repOptions.length > 0 &&
                                    repOptions.find(
                                      (option) => option.label === field.value
                                    )) || {
                                    value: "",
                                    label:
                                      translations["dropdown"].select[language],
                                  }
                                }
                                onChange={(selectedOption) => {
                                  form.setFieldValue(
                                    field.name,
                                    selectedOption.label
                                  );
                                }}
                                maxMenuHeight={"85px"}
                                styles={{
                                  container: (provided) => ({
                                    ...provided,
                                    width: "70%", // Set the container width to 70%
                                  }),
                                  input: (provided) => ({
                                    ...provided,
                                    color: isDarkMode ? "#ffffff" : "black", // Color of the text when typing
                                  }),
                                  control: (provided, state) => ({
                                    ...provided,
                                    backgroundColor: isDarkMode
                                      ? "transparent"
                                      : "white",
                                    border: "none",
                                    borderLeft: state.isHovered
                                      ? "none"
                                      : state.isFocused
                                      ? "1px solid #80bdff"
                                      : isDarkMode
                                      ? "1px solid #495057"
                                      : "1px solid #dee2e6", // Adjust the border color for focus
                                    boxShadow: state.isFocused
                                      ? "0 0 0 0.2rem rgba(0, 123, 255, 0.25)"
                                      : "none", // Bootstrap's box shadow for focus
                                    // borderRadius: ".45rem",
                                  }),
                                  valueContainer: (provided) => ({
                                    ...provided,
                                    padding: "0.18rem 0.55rem", // Bootstrap's padding for the value container
                                  }),
                                  indicatorSeparator: (provided) => ({
                                    ...provided,
                                    display: "none", // Hide the indicator separator
                                  }),
                                  dropdownIndicator: (provided) => ({
                                    ...provided,
                                    padding: "0.375rem 0.75rem", // Bootstrap's padding for the dropdown indicator
                                    color: isDarkMode ? "#ffffff" : "#868686",
                                  }),
                                  menu: (provided) => ({
                                    ...provided,
                                    color: isDarkMode ? "#ffffff" : "black",
                                    backgroundColor: isDarkMode
                                      ? "#1f2023"
                                      : "white",
                                    margin: "0", // Remove default margin
                                  }),
                                  placeholder: (provided) => ({
                                    ...provided,
                                    color: isDarkMode ? "#ffffff" : "#333333", // Specify your desired color for the placeholder
                                    // Additional styles for placeholder
                                  }),
                                  singleValue: (provided) => ({
                                    ...provided,
                                    color: isDarkMode ? "#ffffff" : "black",
                                  }),
                                  option: (provided, state) => ({
                                    ...provided,
                                    color: isDarkMode ? "#ffffff" : "black",
                                    backgroundColor: state.isHovered
                                      ? "#80bdff"
                                      : isDarkMode
                                      ? "#1f2023"
                                      : "white",
                                    ":hover": {
                                      backgroundColor: isDarkMode
                                        ? "#34DBB4"
                                        : "#34DBB4",
                                    },
                                  }),
                                }}
                              />
                            )}
                          />

                          <ErrorMessage
                            name="appointment_rep_user_name"
                            component="div"
                            className="error text-danger"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="mb-4 col">
                        <div
                          className="input-group d-flex align-items-center py-2"
                          style={{
                            border: isDarkMode
                              ? "1px solid #495057"
                              : "1px solid #dee2e6",
                            borderRadius: ".45rem",
                          }}
                        >
                          <span
                            // for="annual-sales-count"
                            className="px-2 bg-transparent"
                            style={{
                              width: "30%",
                              border: "none",
                              backgroundColor: "#2b3035",
                            }}
                          >
                            {
                              translations.clients.table.appointmentDate[
                                language
                              ]
                            }
                          </span>

                          <Field
                            type="date"
                            name="appointment_date"
                            className={`form-control ${
                              isDarkMode ? "custom-date" : ""
                            }`}
                            style={{
                              backgroundColor: isDarkMode ? "transparent" : "",
                              color: isDarkMode ? "#ffffff" : "#000000",
                              border: "none",
                              borderLeft: isDarkMode
                                ? "1px solid #495057"
                                : "1px solid #dee2e6", // Adjust the border color for focus
                            }}
                            onChange={(e) => {
                              setValues({
                                ...values,
                                appointment_date: e.target.value,
                              });
                            }}
                          />

                          <ErrorMessage
                            name="appointment_date"
                            component="div"
                            className="error text-danger"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="mb-4 col">
                        <div
                          className="input-group d-flex align-items-center py-2"
                          style={{
                            border: isDarkMode
                              ? "1px solid #495057"
                              : "1px solid #dee2e6",
                            borderRadius: ".45rem",
                          }}
                        >
                          <span
                            // for="annual-sales-count"
                            className="px-2 bg-transparent"
                            style={{
                              width: "30%",
                              border: "none",
                              backgroundColor: "#2b3035",
                            }}
                          >
                            {
                              translations.clients.table.appointmentTime[
                                language
                              ]
                            }
                          </span>

                          <Field
                            type="time"
                            name="appointment_time"
                            className={`form-control ${
                              isDarkMode ? "custom-date" : ""
                            }`}
                            style={{
                              backgroundColor: isDarkMode ? "transparent" : "",
                              color: isDarkMode ? "#ffffff" : "#000000",
                              border: "none",
                              borderLeft: isDarkMode
                                ? "1px solid #495057"
                                : "1px solid #dee2e6", // Adjust the border color for focus
                            }}
                            onChange={(e) => {
                              setValues({
                                ...values,
                                appointment_time: e.target.value,
                              });
                            }}
                          />

                          <ErrorMessage
                            name="appointment_time"
                            component="div"
                            className="error text-danger"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="modal-footer"
                    id="modalfooter"
                    style={{
                      borderTop: isDarkMode
                        ? "1px solid #495057"
                        : "1px solid #dee2e6",
                    }}
                  >
                    <div className="row">
                      <div className="col-sm" style={{ padding: 7 }}>
                        <button
                          type="submit"
                          className="client-btn btn-success mx-2"
                          id="add-customer-button"
                          disabled={isSubmitting}
                        >
                          <i className="bi bi-save"></i>{" "}
                          {translations["commonFields"].submitBtn[language]}
                        </button>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
      <Send2Rep
        isDarkMode={isDarkMode}
        appointmentData={appointmentData}
        eventId={eventId}
        setIsLoading={setIsLoading}
        repOptions={repOptions}
      />
    </>
  );
};

export default EditAppointmenModal;
