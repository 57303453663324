import { useEffect, useState } from "react";
import * as Yup from "yup";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import SendFormModal from "./SendFormModal";
import { ApiUrl } from "./ApiUrl";
import translations from "./translation.json";
import { useLanguage } from "./languageContext";
import AddWeekModal from "./addWeekModal";
import SendPdfModal from "./sendPdfModal";
import { internalUsers } from "./constants/userTypes";

const EditClientForm = ({ setIsLoading, setDisplayComponent, isDarkMode }) => {
  const [isOpenAll, setIsOpenAll] = useState(false);
  const [submitPressed, setSubmitPressed] = useState(false);
  const token = localStorage.getItem("token");
  const [formValues, setFormValues] = useState();
  const userType = localStorage.getItem("userType");
  const [eventId, setEventId] = useState("");
  const [week, setWeek] = useState("");
  const [showDate, setShowDate] = useState(true);
  const [documents, setDocuments] = useState();
  const [historyData, setHistoryData] = useState(null);
  const [editClientID, setEditClientID] = useState();
  const [eventID, setEventID] = useState();
  const [formType, setFormType] = useState(null);
  const [formUrl, setFormUrl] = useState(null);
  const [driveLink, setDriveLink] = useState(null);
  const { language } = useLanguage();
  const pageTranslations = translations["forms"];

  const validationSchema = Yup.object().shape({
    contact_tp_email: Yup.string().email(
      translations.usersPage.createUser.validationText.email.invalid[language]
    ),
    contact_list_email: Yup.string().email(
      translations.usersPage.createUser.validationText.email.invalid[language]
    ),
    responsible_payable:
      userType && !internalUsers.includes(userType)
        ? Yup.string().email(
            
          ).required(translations.forms.validationTranslations.responsible_payable[
            language
          ])
        : Yup.string().email(),
  });
  useEffect(() => {
    // Get the current URL
    var currentUrl = window.location.href; // url example: http://localhost:3000/event-portal?page=edit-client&client_id=41&p=27
    // Check if '=' is found and extract the text after it
    const equalsIndex = currentUrl.indexOf("="); // =edit-client&client_id=41&p=27

    if (equalsIndex !== -1) {
      const secondEqualsIndex = currentUrl.indexOf("=", equalsIndex + 1);

      if (secondEqualsIndex !== -1) {
        const ampersandIndex = currentUrl.indexOf("&", secondEqualsIndex + 1); //=41&p=27

        if (ampersandIndex !== -1) {
          const editClientIdValue = currentUrl.substring(
            secondEqualsIndex + 1,
            ampersandIndex
          ); //=41
          setEditClientID(editClientIdValue);

          const thirdEqualsIndex = currentUrl.indexOf("=", ampersandIndex + 1); // =27

          if (thirdEqualsIndex !== -1) {
            const eventIDValue = currentUrl.substring(thirdEqualsIndex + 1);
            setEventID(eventIDValue);
            setEventId(eventID);
          }
        } else {
          // If no '&' found after the second '=', extract until the end of the URL
          const editClientIdValue = currentUrl.substring(secondEqualsIndex + 1);
          setEditClientID(editClientIdValue);
        }
      }
    }
  });

  const handleCopy = async (event_id, week) => {
    try {
      setIsLoading(true);
      const res = await fetch(ApiUrl + "/api/user/convert-clientform", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          client_form_id: editClientID,
          week: week,
        }),
      });
      const data = await res.json();
      if (data.status === "success") {
        toast.success("Form converted successfully");
        setDisplayComponent("edit-event");
        window.history.pushState(
          {
            id: "gallery",
            randomData: window.Math.random(),
          },
          "title",
          "/event-portal?page=edit-event&parent_id=" + data.event_url
        );
      } else {
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      toast.error("Something went wrong");
    }
  };

  const handleToggleAll = () => {
    setIsOpenAll((prevIsOpenAll) => !prevIsOpenAll);
    setShowDate(false);
  };

  const handleSubmit = async (values, { resetForm }, error) => {
    setIsLoading(true);
    try {
      const res = await fetch(ApiUrl + "/api/user/update-submittion-data", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(values),
      });
      setFormValues(values);
      const data = await res.json();
      if (data.status === "success") {
        toast.success(data.message);
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      toast.error("Something went wrong!");
    } finally {
      setIsLoading(false);
    }
  };

  const saveChanges = async (formType, formData) => {
    try {
      const res = await fetch(ApiUrl + "/api/user/update-submittion-data", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(formData),
      });
      setFormValues(formData);
      const data = await res.json();
      // console.log("data", data)
      if (data.status === "success") {
        // toast.success(data.message);
        toast.success("Changes Saved Successfully");
        if (formType) handleFormDownload(formType);
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      toast.error("Something went wrong!");
    }
  };

  useEffect(() => {
    setIsLoading(true);
    const fetchFormData = async () => {
      try {
        const res = await fetch(ApiUrl + "/api/user/get-submittion-data", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            id: editClientID,
            parent_id: eventID,
          }),
        });
        // console.log("res Get Form data", res)
        const data = await res.json();
        // console.log("data get ", data)
        if (data.status === "success") {
          // getClientForms();
          fetchDocuments();
          // toast.success(data.message)
          const data2 = data.client_form_data[0];
          if (
            data.client_form_data[0] !== undefined &&
            data.client_form_data[0] != null
          ) {
            for (const key in data2) {
              if (data2[key] === null || data2[key] === "null") {
                data2[key] = "";
              }
            }
          }

          const history = data.parent_form_data[0];
          if (
            data.parent_form_data[0] !== undefined &&
            data.parent_form_data[0] != null
          ) {
            for (const key in history) {
              if (
                history[key] === null ||
                history[key] === "null" ||
                history[key] === ""
              ) {
                history[key] = "";
              }
            }
          }

          setFormValues({...data2, appointment_time_slots:"60"});
          // setFormValues(data.client_form_data[0]);
          // setHistoryData(data.parent_form_data[0]);
          setHistoryData(history);
        } else {
          toast.error(data.message);
        }
        setIsLoading(false);
      } catch (error) {
        toast.error("Something went wrong!");
      }
    };

    editClientID && fetchFormData();
  }, [editClientID]);

  // fetch documents
  const fetchDocuments = async () => {
    try {
      const res = await fetch(ApiUrl + "/api/user/get-documents-client", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          client_form_id: editClientID,
        }),
      });
      // console.log("res Get Form data", res)
      const data = await res.json();
      // console.log("data get ", data)
      if (data.status === "success") {
        setDocuments(data.event_list);
        // console.log(data.event_list)
        // console.log(data);
        // toast.success(data.message)
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      toast.error("Something went wrong!");
    }
  };

  const sendDocument = async (file_type, drive_link, week, dealer_name) => {
    setIsLoading(true);
    try {
      const res = await fetch(
        ApiUrl + "/api/user/send-mail-to-admins-submisstion-docs",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            file_type: file_type,
            drive_link: drive_link,
            week: week,
            dealer_name: dealer_name,
          }),
        }
      );
      const data = await res.json();

      if (data.status === "success") {
        // console.log(data)
        setIsLoading(false);
        toast.success(`${data.message}`);
      } else {
        toast.success(data.message);
        // console.log(data.message)
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      toast.error("Something went wrong!");
    }
  };

  const handleFormDownload = async (api) => {
    setFormUrl(null);
    try {
      const res = await fetch(ApiUrl + "/api/user/" + api, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          parent_id: eventId,
          client_form_id: editClientID,
        }),
      });
      const data = await res.json();

      if (data.status === "success") {
        // console.log(data)
        setFormUrl(data.pdf_url);
        setDriveLink(data.drive_response);

        fetchDocuments();
      } else {
        toast.success(data.message);
        // console.log(data.message)
      }
    } catch (error) {
      toast.error("Something went wrong!");
    }
  };

  return (
    <div className="create-event">
      <div class="container maincont">
        <div id="app" class="appcountainer2">
          {editClientID && formValues && (
            <Formik
              initialValues={formValues}
              onSubmit={handleSubmit}
              validationSchema={validationSchema}
            >
              {({ errors, values }) => (
                <Form>
                  {/* Display a toast for each field with an error */}
                  {(() => {
                    let stopLoop = false;
                    submitPressed &&
                      Object.keys(errors).length > 0 &&
                      Object.keys(errors).some((field, index, array) => {
                        if (stopLoop) return;
                        toast.error(errors[field]);

                        if (index === array.length - 1) {
                          setSubmitPressed(false);
                          stopLoop = true;
                        }

                        return stopLoop;
                      });
                  })()}
                  <div class="edit-form-form">
                    <div class="headersec">
                      <nav class="navbar navbar-expand-md navbar-light">
                        <div class="container-fluid">
                          <h2 className={`${isDarkMode ? "darkthead" : ""}`}>
                            {pageTranslations.editClientTitle[language]}
                          </h2>

                          <button
                            class="navbar-toggler"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#navbarNav"
                            aria-controls="navbarNav"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                          >
                            <span class="navbar-toggler-icon"></span>
                          </button>
                          <div
                            class="collapse navbar-collapse justify-content-end"
                            id="navbarNav"
                          >
                            <ul class="navbar-nav">
                              <li class="nav-item">
                                <button
                                  id="dateBtn"
                                  class="admin-btn btn-primary"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  href="#multiCollapseDate"
                                  role="button"
                                  aria-expanded="false"
                                  aria-controls="multiCollapseDate"
                                >
                                  <i class="bi bi-calendar"></i>{" "}
                                  {pageTranslations.datesBtn[language]}
                                </button>
                              </li>
                              {/* <li class="nav-item">
                                <button class="admin-btn btn-primary" type="button" data-bs-toggle="collapse" data-bs-target="#multiCollapseOptions" aria-expanded="false" aria-controls="multiCollapseOptions"><i class="bi bi-people-fill"></i> Conquest</button>
                              </li> */}
                              <li class="nav-item">
                                <button
                                  class="admin-btn btn-primary"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#multiCollapseInfosvente"
                                  aria-expanded="false"
                                  aria-controls="multiCollapseInfosvente"
                                >
                                  <i class="bi bi-info-circle"></i>{" "}
                                  {pageTranslations.salesInfoBtn[language]}
                                </button>
                              </li>
                              <li class="nav-item">
                                <button
                                  class="admin-btn btn-primary"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#multiCollapseInfoscontact"
                                  aria-expanded="false"
                                  aria-controls="multiCollapseInfoscontact"
                                >
                                  <i class="bi bi-person-lines-fill"></i>{" "}
                                  {pageTranslations.contactBtn[language]}
                                </button>
                              </li>
                              <li class="nav-item">
                                <button
                                  class="admin-btn btn-primary"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#multiCollapseInfosequipe"
                                  aria-expanded="false"
                                  aria-controls="multiCollapseInfosequipe"
                                >
                                  <i class="bi bi-person-badge"></i>{" "}
                                  {pageTranslations.teamBtn[language]}
                                </button>
                              </li>
                              <li class="nav-item">
                                <button
                                  class="admin-btn btn-primary"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#multiCollapseInfospromos"
                                  aria-expanded="false"
                                  aria-controls="multiCollapseInfospromos"
                                >
                                  <i class="bi bi-stars"></i>{" "}
                                  {pageTranslations.promosBtn[language]}
                                </button>
                              </li>
                              <li class="nav-item">
                                <button
                                  className="admin-btn btn-primary"
                                  type="button"
                                  onClick={handleToggleAll}
                                >
                                  <i className="bi bi-folder2-open"></i>{" "}
                                  {isOpenAll
                                    ? pageTranslations.closeAllBtn[language]
                                    : pageTranslations.openAllBtn[language]}
                                </button>
                                {/* <button class="admin-btn btn-primary" type="button" data-bs-toggle="collapse" data-bs-target=".multi-collapse" aria-expanded="false" aria-controls="multiCollapseDate multiCollapseOptions multiCollapseInfosvente multiCollapseInfoscontact multiCollapseInfosequipe multiCollapseInfospromos"><i class="bi bi-folder2-open"></i> Open All</button> */}
                              </li>
                            </ul>
                          </div>
                        </div>
                      </nav>
                    </div>
                    <div
                      class="bodysectab"
                      style={{ height: "calc(88vh - 150px)" }}
                    >
                      <div class="row">
                        <div class="col">
                          <div class="mb-3">
                            <label for="event-1-ID" class="form-label">
                              ID
                            </label>
                            <Field
                              type="number"
                              class="form-control"
                              id="event-1-ID"
                              name="id"
                              readOnly
                              disabled
                              style={{
                                backgroundColor: isDarkMode
                                  ? "rgba(79,87,89,0.5)"
                                  : "",
                                color: isDarkMode ? "#ffffff" : "#000000",
                                border: isDarkMode
                                  ? "1px solid #495057"
                                  : "1px solid #dee2e6",
                              }}
                            />
                            <ErrorMessage
                              name="id"
                              component="div"
                              className="error text-danger"
                            />
                          </div>
                        </div>
                        <div class="col">
                          <div class="mb-3">
                            <label for="dealership-name2" class="form-label">
                              {pageTranslations.formFields.dealerDrop[language]}
                            </label>
                            <Field
                              type="text"
                              class="form-control"
                              name="dealershipName"
                              readOnly
                              disabled
                              id="dealership-name2"
                              style={{
                                backgroundColor: isDarkMode
                                  ? "rgba(79,87,89,0.5)"
                                  : "",
                                color: isDarkMode ? "#ffffff" : "#000000",
                                border: isDarkMode
                                  ? "1px solid #495057"
                                  : "1px solid #dee2e6",
                              }}
                            />
                            <ErrorMessage
                              name="dealershipName"
                              component="div"
                              className="error text-danger"
                            />
                          </div>
                        </div>
                        <div class="col">
                          <div class="mb-3">
                            <label for="package" class="form-label">
                              {pageTranslations.formFields.package[language]}
                            </label>
                            <Field
                              type="text"
                              name="package"
                              class="form-control"
                              id="package"
                              readOnly
                              disabled
                              style={{
                                backgroundColor: isDarkMode
                                  ? "rgba(79,87,89,0.5)"
                                  : "",
                                color: isDarkMode ? "#ffffff" : "#000000",
                                border: isDarkMode
                                  ? "1px solid #495057"
                                  : "1px solid #dee2e6",
                              }}
                            />
                            <ErrorMessage
                              name="package"
                              component="div"
                              className="error text-danger"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col">
                          <div class="mb-3">
                            <label class="form-label">
                              {
                                pageTranslations.formFields
                                  .sales_representative[language]
                              }
                            </label>
                            <Field
                              as="select"
                              style={{
                                backgroundColor: isDarkMode
                                  ? "rgba(79,87,89,0.5)"
                                  : "",
                                color: isDarkMode ? "#ffffff" : "#000000",
                                border: isDarkMode
                                  ? "1px solid #495057"
                                  : "1px solid #dee2e6",
                              }}
                              class={` form-control`}
                              name="sales_representative"
                              aria-label={
                                pageTranslations.formFields
                                  .sales_representative[language]
                              }
                              id="rep-name"
                              readOnly
                              disabled
                            >
                              <option
                                style={{
                                  background: isDarkMode ? "#1B1F22" : "",
                                  color: isDarkMode ? "#ffffff" : "",
                                }}
                              >
                                {
                                  pageTranslations.formFields
                                    .sales_representative[language]
                                }
                              </option>
                              <option
                                style={{
                                  background: isDarkMode ? "#1B1F22" : "",
                                  color: isDarkMode ? "#ffffff" : "",
                                }}
                              >
                                François Mercier
                              </option>
                              <option
                                style={{
                                  background: isDarkMode ? "#1B1F22" : "",
                                  color: isDarkMode ? "#ffffff" : "",
                                }}
                              >
                                Jean-Francois Coulombe
                              </option>
                              <option
                                style={{
                                  background: isDarkMode ? "#1B1F22" : "",
                                  color: isDarkMode ? "#ffffff" : "",
                                }}
                              >
                                Serge Beaulé
                              </option>
                              <option
                                style={{
                                  background: isDarkMode ? "#1B1F22" : "",
                                  color: isDarkMode ? "#ffffff" : "",
                                }}
                              >
                                Vincent Mercier
                              </option>
                              <option
                                style={{
                                  background: isDarkMode ? "#1B1F22" : "",
                                  color: isDarkMode ? "#ffffff" : "",
                                }}
                              >
                                Yves Chiasson
                              </option>
                            </Field>
                            <ErrorMessage
                              name="sales_representative"
                              component="div"
                              className="error text-danger"
                            />
                          </div>
                        </div>
                        <div class="col">
                          <div class="mb-3">
                            <label for="last-sale-date" class="form-label">
                              {
                                pageTranslations.formFields.lastSaleDate[
                                  language
                                ]
                              }
                            </label>
                            <Field
                              type="date"
                              name="last_sale_date"
                              class={`form-control ${
                                isDarkMode ? "custom-date" : ""
                              }`}
                              id="last-sale-date"
                              readOnly
                              disabled
                              style={{
                                backgroundColor: isDarkMode
                                  ? "rgba(79,87,89,0.5)"
                                  : "",
                                color: isDarkMode ? "#ffffff" : "#000000",
                                border: isDarkMode
                                  ? "1px solid #495057"
                                  : "1px solid #dee2e6",
                              }}
                            />
                            <ErrorMessage
                              name="last_sale_date"
                              component="div"
                              className="error text-danger"
                            />
                          </div>
                        </div>
                        <div class="col">
                          <div class="mb-3">
                            <label for="annual-sales-count" class="form-label">
                              {pageTranslations.formFields.digital[language]}
                            </label>

                            {historyData.digital !== formValues.digital && (
                              <div className="client-tooltip ">
                                <div
                                  style={{ backgroundColor: "#34DBB4" }}
                                  className="mx-1 rounded d-flex justify-content-center  align-items-center "
                                >
                                  <i
                                    className="bi bi-clock-history rounded-circle px-1 "
                                    style={{ fontSize: "12px" }}
                                  ></i>
                                </div>
                                <span className="client-tooltiptext">
                                  {translations.commonWords.oldData[language]}{" "}
                                  {historyData.digital === null ||
                                  historyData.digital === "null" ||
                                  historyData.digital === ""
                                    ? "blank"
                                    : historyData.digital}
                                </span>
                              </div>
                            )}

                            {/* <Field type="text" name="digital" class="form-control" id="annual-sales-count" fdprocessedid="g00vec" /> */}
                            <Field
                              as="select"
                              class={`form-select ${
                                isDarkMode ? "custom-select" : ""
                              }`}
                              name="digital"
                              aria-label="Digital"
                              style={{
                                backgroundColor: isDarkMode
                                  ? "transparent"
                                  : "",
                                color: isDarkMode ? "#ffffff" : "#000000",
                                border: isDarkMode
                                  ? "1px solid #495057"
                                  : "1px solid #dee2e6",
                              }}
                            >
                              <option
                                value=""
                                selected=""
                                style={{
                                  background: isDarkMode ? "#1B1F22" : "",
                                  color: isDarkMode ? "#ffffff" : "",
                                }}
                              >
                                {translations["dropdown"].select[language]}
                              </option>
                              <option
                                value={"Yes"}
                                style={{
                                  background: isDarkMode ? "#1B1F22" : "",
                                  color: isDarkMode ? "#ffffff" : "",
                                }}
                              >
                                {" "}
                                {translations["dropdown"].yes[language]}
                              </option>
                              <option
                                value={"No"}
                                style={{
                                  background: isDarkMode ? "#1B1F22" : "",
                                  color: isDarkMode ? "#ffffff" : "",
                                }}
                              >
                                {translations["dropdown"].no[language]}
                              </option>
                            </Field>
                            <ErrorMessage
                              name="digital"
                              component="div"
                              className="error text-danger"
                            />
                          </div>
                        </div>
                      </div>

                      <div
                        class="row align-middle"
                        style={{ paddingBottom: 10 }}
                      >
                        <div
                          class={`collapse multi-collapse ${
                            isOpenAll || showDate ? "show" : ""
                          }`}
                          id="multiCollapseDate"
                        >
                          <div
                            class="card card-body"
                            style={{
                              border: isDarkMode
                                ? "1px solid #495057"
                                : "1px solid #dee2e6",
                            }}
                          >
                            <h2 className={`${isDarkMode ? "darkthead" : ""}`}>
                              {
                                pageTranslations.formFields.datesSection.dates[
                                  language
                                ]
                              }
                            </h2>
                            <div class="row">
                              <div class="col-2 align-middle">
                                <div class="mb-3">
                                  <p>
                                    {
                                      pageTranslations.formFields.datesSection
                                        .title[language]
                                    }
                                  </p>
                                </div>
                              </div>
                              <div class="col-2">
                                <div class="mb-3">
                                  <p>
                                    1
                                    <sup>
                                      {
                                        pageTranslations.formFields.datesSection
                                          .st[language]
                                      }
                                    </sup>{" "}
                                    {
                                      pageTranslations.formFields.datesSection
                                        .day[language]
                                    }
                                  </p>
                                </div>
                              </div>

                              <div class="col-1">
                                <div class="mb-3">
                                  <p>
                                    {
                                      pageTranslations.formFields.datesSection
                                        .days[language]
                                    }
                                  </p>
                                </div>
                              </div>

                              <div class="col-5">
                                <div class="mb-3">
                                  <p>
                                    {
                                      pageTranslations.formFields.datesSection
                                        .EventsDates[language]
                                    }
                                  </p>
                                </div>
                              </div>
                              <div class="col-2">
                                <div class="mb-3">
                                  <p>
                                    {pageTranslations.formFields.week[language]}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div class="row align-middle">
                              <div class="col-2 align-middle">
                                <div class="mb-3">
                                  <p>
                                    1
                                    <sup>
                                      {
                                        pageTranslations.formFields.datesSection
                                          .st[language]
                                      }
                                    </sup>{" "}
                                    {
                                      translations["EventsPortal"].title.events[
                                        language
                                      ]
                                    }
                                  </p>
                                </div>
                              </div>

                              <div class="col-2">
                                <div class="mb-3">
                                  <Field
                                    value={
                                      values.first_day
                                        ? values.first_day.split(" ")[0]
                                        : ""
                                    }
                                    // value={
                                    //   values.first_day
                                    //     ? new Date(values.first_day)
                                    //         .toISOString()
                                    //         .split("T")[0]
                                    //     : ""
                                    // }
                                    type="text"
                                    name="first_day"
                                    style={{
                                      backgroundColor: isDarkMode
                                        ? "rgba(79,87,89,0.5)"
                                        : "",
                                      color: isDarkMode ? "#ffffff" : "#000000",
                                      border: isDarkMode
                                        ? "1px solid #495057"
                                        : "1px solid #dee2e6",
                                    }}
                                    class={`form-control ${
                                      isDarkMode ? "custom-date" : ""
                                    }`}
                                    id="date_day_1"
                                    readOnly
                                    disabled
                                  />
                                  <ErrorMessage
                                    name="first_day"
                                    component="div"
                                    className="error text-danger"
                                  />
                                </div>
                              </div>

                              <div class="col-1">
                                <Field
                                  as="select"
                                  class={`form-control`}
                                  style={{
                                    backgroundColor: isDarkMode
                                      ? "rgba(79,87,89,0.5)"
                                      : "",
                                    color: isDarkMode ? "#ffffff" : "#000000",
                                    border: isDarkMode
                                      ? "1px solid #495057"
                                      : "1px solid #dee2e6",
                                  }}
                                  name="days"
                                  aria-label="Default select example"
                                  id="nb_day_1"
                                  /*onchange="datesCalculator()"*/ readOnly
                                  disabled
                                >
                                  <option
                                    value=""
                                    selected=""
                                    style={{
                                      background: isDarkMode ? "#1B1F22" : "",
                                      color: isDarkMode ? "#ffffff" : "",
                                    }}
                                  >
                                    {
                                      pageTranslations.formFields.datesSection
                                        .numDays[language]
                                    }
                                  </option>
                                  <option
                                    value="1"
                                    style={{
                                      background: isDarkMode ? "#1B1F22" : "",
                                      color: isDarkMode ? "#ffffff" : "",
                                    }}
                                  >
                                    1
                                  </option>
                                  <option
                                    value="2"
                                    style={{
                                      background: isDarkMode ? "#1B1F22" : "",
                                      color: isDarkMode ? "#ffffff" : "",
                                    }}
                                  >
                                    2
                                  </option>
                                  <option
                                    value="3"
                                    style={{
                                      background: isDarkMode ? "#1B1F22" : "",
                                      color: isDarkMode ? "#ffffff" : "",
                                    }}
                                  >
                                    3
                                  </option>
                                  <option
                                    value="4"
                                    style={{
                                      background: isDarkMode ? "#1B1F22" : "",
                                      color: isDarkMode ? "#ffffff" : "",
                                    }}
                                  >
                                    4
                                  </option>
                                  <option
                                    value="5"
                                    style={{
                                      background: isDarkMode ? "#1B1F22" : "",
                                      color: isDarkMode ? "#ffffff" : "",
                                    }}
                                  >
                                    5
                                  </option>
                                  <option
                                    value="6"
                                    style={{
                                      background: isDarkMode ? "#1B1F22" : "",
                                      color: isDarkMode ? "#ffffff" : "",
                                    }}
                                  >
                                    6
                                  </option>
                                  <option
                                    value="7"
                                    style={{
                                      background: isDarkMode ? "#1B1F22" : "",
                                      color: isDarkMode ? "#ffffff" : "",
                                    }}
                                  >
                                    7
                                  </option>
                                  <option
                                    value="8"
                                    style={{
                                      background: isDarkMode ? "#1B1F22" : "",
                                      color: isDarkMode ? "#ffffff" : "",
                                    }}
                                  >
                                    8
                                  </option>
                                  <option
                                    value="9"
                                    style={{
                                      background: isDarkMode ? "#1B1F22" : "",
                                      color: isDarkMode ? "#ffffff" : "",
                                    }}
                                  >
                                    9
                                  </option>
                                  <option
                                    value="10"
                                    style={{
                                      background: isDarkMode ? "#1B1F22" : "",
                                      color: isDarkMode ? "#ffffff" : "",
                                    }}
                                  >
                                    10
                                  </option>
                                  <option
                                    value="11"
                                    style={{
                                      background: isDarkMode ? "#1B1F22" : "",
                                      color: isDarkMode ? "#ffffff" : "",
                                    }}
                                  >
                                    11
                                  </option>
                                  <option
                                    value="12"
                                    style={{
                                      background: isDarkMode ? "#1B1F22" : "",
                                      color: isDarkMode ? "#ffffff" : "",
                                    }}
                                  >
                                    12
                                  </option>
                                  <option
                                    value="13"
                                    style={{
                                      background: isDarkMode ? "#1B1F22" : "",
                                      color: isDarkMode ? "#ffffff" : "",
                                    }}
                                  >
                                    13
                                  </option>
                                  <option
                                    value="14"
                                    style={{
                                      background: isDarkMode ? "#1B1F22" : "",
                                      color: isDarkMode ? "#ffffff" : "",
                                    }}
                                  >
                                    14
                                  </option>
                                  <option
                                    value="15"
                                    style={{
                                      background: isDarkMode ? "#1B1F22" : "",
                                      color: isDarkMode ? "#ffffff" : "",
                                    }}
                                  >
                                    15
                                  </option>
                                </Field>
                                <ErrorMessage
                                  name="days"
                                  component="div"
                                  className="error text-danger"
                                />
                              </div>
                              <div class="col-5">
                                <div class="mb-3">
                                  <Field
                                    as="textarea"
                                    class="form-control"
                                    name="event_dates"
                                    id="date_event_1"
                                    rows="2"
                                    readOnly
                                    disabled
                                    style={{
                                      backgroundColor: isDarkMode
                                        ? "rgba(79,87,89,0.5)"
                                        : "",
                                      color: isDarkMode ? "#ffffff" : "#000000",
                                      border: isDarkMode
                                        ? "1px solid #495057"
                                        : "1px solid #dee2e6",
                                    }}
                                  ></Field>
                                  <ErrorMessage
                                    name="event_dates"
                                    component="div"
                                    className="error text-danger"
                                  />
                                </div>
                              </div>
                              <div class="col-2">
                                <div class="mb-3">
                                  <Field
                                    type="text"
                                    name="week"
                                    class="form-control"
                                    id="semaine-input"
                                    readOnly
                                    disabled
                                    style={{
                                      backgroundColor: isDarkMode
                                        ? "rgba(79,87,89,0.5)"
                                        : "",
                                      color: isDarkMode ? "#ffffff" : "#000000",
                                      border: isDarkMode
                                        ? "1px solid #495057"
                                        : "1px solid #dee2e6",
                                    }}
                                  />
                                  <ErrorMessage
                                    name="week"
                                    component="div"
                                    className="error text-danger"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row" style={{ paddingBottom: 10 }}>
                        <div
                          class={`collapse multi-collapse ${
                            isOpenAll ? "show" : ""
                          }`}
                          id="multiCollapseInfosvente"
                        >
                          <div
                            class="card card-body"
                            style={{
                              border: isDarkMode
                                ? "1px solid #495057"
                                : "1px solid #dee2e6",
                            }}
                          >
                            <h2 className={`${isDarkMode ? "darkthead" : ""}`}>
                              {
                                pageTranslations.formFields.salesInfoSection
                                  .title[language]
                              }
                            </h2>
                            <div class="row">
                              <div class="col">
                                <div class="mb-3">
                                  <label
                                    for="titre-de-la-vente"
                                    data-bs-toggle="tooltip"
                                    title="Sale Title"
                                    class="form-label"
                                  >
                                    {
                                      pageTranslations.formFields
                                        .salesInfoSection.saletitle[language]
                                    }
                                  </label>
                                  {historyData.sale_title !==
                                    formValues.event_title && (
                                    <div className="client-tooltip ">
                                      <div
                                        style={{ backgroundColor: "#34DBB4" }}
                                        className="mx-1 rounded d-flex justify-content-center  align-items-center "
                                      >
                                        <i
                                          className="bi bi-clock-history rounded-circle px-1 "
                                          style={{ fontSize: "12px" }}
                                        ></i>
                                      </div>
                                      <span className="client-tooltiptext">
                                        {
                                          translations.commonWords.oldData[
                                            language
                                          ]
                                        }{" "}
                                        {historyData.sale_title === null ||
                                        historyData.sale_title === "null" ||
                                        historyData.sale_title === ""
                                          ? "blank"
                                          : historyData.sale_title}
                                      </span>
                                    </div>
                                  )}
                                  <Field
                                    type="text"
                                    name="event_title"
                                    class="form-control"
                                    id="titre-de-la-vente"
                                    style={{
                                      backgroundColor: isDarkMode
                                        ? "transparent"
                                        : "",
                                      color: isDarkMode ? "#ffffff" : "#000000",
                                      border: isDarkMode
                                        ? "1px solid #495057"
                                        : "1px solid #dee2e6",
                                    }}
                                  />
                                  <ErrorMessage
                                    name="event_title"
                                    component="div"
                                    className="error text-danger"
                                  />
                                </div>
                              </div>

                              <div class="col">
                                <div class="mb-3">
                                  <label
                                    for="vehicule-a-avant"
                                    data-bs-toggle="tooltip"
                                    title="Include the year and model. For microsites, web, banners, letters, etc."
                                    class="form-label"
                                  >
                                    {
                                      pageTranslations.formFields
                                        .salesInfoSection.featuredVehicle[
                                        language
                                      ]
                                    }
                                  </label>
                                  {historyData.featured_vehicle !==
                                    formValues.featured_vehicle && (
                                    <div className="client-tooltip ">
                                      <div
                                        style={{ backgroundColor: "#34DBB4" }}
                                        className="mx-1 rounded d-flex justify-content-center  align-items-center "
                                      >
                                        <i
                                          className="bi bi-clock-history rounded-circle px-1 "
                                          style={{ fontSize: "12px" }}
                                        ></i>
                                      </div>
                                      <span className="client-tooltiptext">
                                        {
                                          translations.commonWords.oldData[
                                            language
                                          ]
                                        }{" "}
                                        {historyData.featured_vehicle ===
                                          null ||
                                        historyData.featured_vehicle ===
                                          "null" ||
                                        historyData.featured_vehicle === ""
                                          ? "blank"
                                          : historyData.featured_vehicle}
                                      </span>
                                    </div>
                                  )}
                                  <Field
                                    type="text"
                                    name="featured_vehicle"
                                    class="form-control"
                                    id="vehicule-a-avant"
                                    style={{
                                      backgroundColor: isDarkMode
                                        ? "transparent"
                                        : "",
                                      color: isDarkMode ? "#ffffff" : "#000000",
                                      border: isDarkMode
                                        ? "1px solid #495057"
                                        : "1px solid #dee2e6",
                                    }}
                                  />
                                  <ErrorMessage
                                    name="featured_vehicle"
                                    component="div"
                                    className="error text-danger"
                                  />
                                </div>
                              </div>

                              <div class="col">
                                <div class="mb-3">
                                  <label
                                    for="coup-de-coeur"
                                    data-bs-toggle="tooltip"
                                    title="Favorites for vehicles to be featured"
                                    class="form-label"
                                  >
                                    {
                                      pageTranslations.formFields
                                        .salesInfoSection.favorites[language]
                                    }
                                  </label>
                                  {historyData.favorites !==
                                    formValues.favorites && (
                                    <div className="client-tooltip ">
                                      <div
                                        style={{ backgroundColor: "#34DBB4" }}
                                        className="mx-1 rounded d-flex justify-content-center  align-items-center "
                                      >
                                        <i
                                          className="bi bi-clock-history rounded-circle px-1 "
                                          style={{ fontSize: "12px" }}
                                        ></i>
                                      </div>
                                      <span className="client-tooltiptext">
                                        {
                                          translations.commonWords.oldData[
                                            language
                                          ]
                                        }{" "}
                                        {historyData.favorites === null ||
                                        historyData.favorites === "null" ||
                                        historyData.favorites === ""
                                          ? "blank"
                                          : historyData.favorites}
                                      </span>
                                    </div>
                                  )}
                                  <Field
                                    type="text"
                                    name="favorites"
                                    class="form-control"
                                    id="coup-de-coeur"
                                    style={{
                                      backgroundColor: isDarkMode
                                        ? "transparent"
                                        : "",
                                      color: isDarkMode ? "#ffffff" : "#000000",
                                      border: isDarkMode
                                        ? "1px solid #495057"
                                        : "1px solid #dee2e6",
                                    }}
                                  />
                                  <ErrorMessage
                                    name="favorites"
                                    component="div"
                                    className="error text-danger"
                                  />
                                </div>
                              </div>
                              <div class="col">
                                <div class="mb-3">
                                  <label
                                    for="langue-des-visuelles"
                                    data-bs-toggle="tooltip"
                                    title="Visual Language"
                                    class="form-label"
                                  >
                                    {
                                      pageTranslations.formFields
                                        .salesInfoSection.visualLanguage[
                                        language
                                      ]
                                    }
                                  </label>
                                  {historyData.visual_language !==
                                    formValues.visual_language && (
                                    <div className="client-tooltip ">
                                      <div
                                        style={{ backgroundColor: "#34DBB4" }}
                                        className="mx-1 rounded d-flex justify-content-center  align-items-center "
                                      >
                                        <i
                                          className="bi bi-clock-history rounded-circle px-1 "
                                          style={{ fontSize: "12px" }}
                                        ></i>
                                      </div>
                                      <span className="client-tooltiptext">
                                        {
                                          translations.commonWords.oldData[
                                            language
                                          ]
                                        }{" "}
                                        {historyData.visual_language === null ||
                                        historyData.visual_language ===
                                          "null" ||
                                        historyData.visual_language === ""
                                          ? "blank"
                                          : historyData.visual_language}
                                      </span>
                                    </div>
                                  )}

                                  <Field
                                    as="select"
                                    class={`form-select ${
                                      isDarkMode ? "custom-select" : ""
                                    }`}
                                    name="visual_language"
                                    aria-label="Default select example"
                                    id="langue-des-visuelles"
                                    style={{
                                      backgroundColor: isDarkMode
                                        ? "transparent"
                                        : "",
                                      color: isDarkMode ? "#ffffff" : "#000000",
                                      border: isDarkMode
                                        ? "1px solid #495057"
                                        : "1px solid #dee2e6",
                                    }}
                                  >
                                    <option
                                      value=""
                                      selected=""
                                      style={{
                                        background: isDarkMode ? "#1B1F22" : "",
                                        color: isDarkMode ? "#ffffff" : "",
                                      }}
                                    >
                                      {
                                        translations["dropdown"].select[
                                          language
                                        ]
                                      }
                                    </option>
                                    <option
                                      value="French"
                                      style={{
                                        background: isDarkMode ? "#1B1F22" : "",
                                        color: isDarkMode ? "#ffffff" : "",
                                      }}
                                    >
                                      {
                                        translations.commonWords.french[
                                          language
                                        ]
                                      }
                                    </option>
                                    <option
                                      value="English"
                                      style={{
                                        background: isDarkMode ? "#1B1F22" : "",
                                        color: isDarkMode ? "#ffffff" : "",
                                      }}
                                    >
                                      {
                                        translations.commonWords.english[
                                          language
                                        ]
                                      }
                                    </option>
                                    <option
                                      value="Bilingual"
                                      style={{
                                        background: isDarkMode ? "#1B1F22" : "",
                                        color: isDarkMode ? "#ffffff" : "",
                                      }}
                                    >
                                      {
                                        translations.commonWords.bilangual[
                                          language
                                        ]
                                      }
                                    </option>
                                  </Field>
                                  <ErrorMessage
                                    name="visual_language"
                                    component="div"
                                    className="error text-danger"
                                  />
                                </div>
                              </div>
                            </div>
                            <hr />

                            <div class="row">
                              {
                                false &&

                              <div class="col">
                                <div class="mb-3">
                                  <label
                                    for="plage-hor-des-rdv"
                                    data-bs-toggle="tooltip"
                                    title="Appointment Time Slots"
                                    class="form-label"
                                  >
                                    {
                                      pageTranslations.formFields
                                        .salesInfoSection.appointmentTS[
                                        language
                                      ]
                                    }
                                  </label>
                                  {historyData.appointment_time_slots !==
                                    formValues.appointment_time_slots && (
                                    <div className="client-tooltip ">
                                      <div
                                        style={{ backgroundColor: "#34DBB4" }}
                                        className="mx-1 rounded d-flex justify-content-center  align-items-center "
                                      >
                                        <i
                                          className="bi bi-clock-history rounded-circle px-1 "
                                          style={{ fontSize: "12px" }}
                                        ></i>
                                      </div>
                                      <span className="client-tooltiptext">
                                        {
                                          translations.commonWords.oldData[
                                            language
                                          ]
                                        }{" "}
                                        {historyData.appointment_time_slots ===
                                          null ||
                                        historyData.appointment_time_slots ===
                                          "null" ||
                                        historyData.appointment_time_slots ===
                                          ""
                                          ? "blank"
                                          : historyData.appointment_time_slots}
                                      </span>
                                    </div>
                                  )}
                                  <Field
                                    as="select"
                                    class={`form-select ${
                                      isDarkMode ? "custom-select" : ""
                                    }`}
                                    style={{
                                      backgroundColor: isDarkMode
                                        ? "transparent"
                                        : "",
                                      color: isDarkMode ? "#ffffff" : "#000000",
                                      border: isDarkMode
                                        ? "1px solid #495057"
                                        : "1px solid #dee2e6",
                                    }}
                                    name="appointment_time_slots"
                                    aria-label="Default select example"
                                    id="plage-hor-des-rdv"
                                  >
                                    <option
                                      value=""
                                      selected=""
                                      style={{
                                        background: isDarkMode ? "#1B1F22" : "",
                                        color: isDarkMode ? "#ffffff" : "",
                                      }}
                                    >
                                      {
                                        translations["dropdown"].select[
                                          language
                                        ]
                                      }
                                    </option>
                                    <option
                                      value="30"
                                      style={{
                                        background: isDarkMode ? "#1B1F22" : "",
                                        color: isDarkMode ? "#ffffff" : "",
                                      }}
                                    >
                                      30
                                    </option>
                                    <option
                                      value="60"
                                      style={{
                                        background: isDarkMode ? "#1B1F22" : "",
                                        color: isDarkMode ? "#ffffff" : "",
                                      }}
                                    >
                                      60
                                    </option>
                                    {/* <option
                                      value="90"
                                      style={{
                                        background: isDarkMode ? "#1B1F22" : "",
                                        color: isDarkMode ? "#ffffff" : "",
                                      }}
                                    >
                                      90
                                    </option> */}
                                    <option
                                      value="120"
                                      style={{
                                        background: isDarkMode ? "#1B1F22" : "",
                                        color: isDarkMode ? "#ffffff" : "",
                                      }}
                                    >
                                      120
                                    </option>
                                  </Field>
                                  <ErrorMessage
                                    name="appointment_time_slots"
                                    component="div"
                                    className="error text-danger"
                                  />
                                </div>
                              </div>
                              }

                              <div class="col">
                                <div class="mb-3">
                                  <label
                                    for="plage-hor-des-rdv"
                                    data-bs-toggle="tooltip"
                                    title="Number of Appointments per Time Slot"
                                    class="form-label"
                                  >
                                    {
                                      pageTranslations.formFields
                                        .salesInfoSection.appointmentsPTS[
                                        language
                                      ]
                                    }
                                  </label>
                                  {historyData.appointments_per_time_slot !==
                                    formValues.appointments_per_time_slot && (
                                    <div className="client-tooltip ">
                                      <div
                                        style={{ backgroundColor: "#34DBB4" }}
                                        className="mx-1 rounded d-flex justify-content-center  align-items-center "
                                      >
                                        <i
                                          className="bi bi-clock-history rounded-circle px-1 "
                                          style={{ fontSize: "12px" }}
                                        ></i>
                                      </div>
                                      <span className="client-tooltiptext">
                                        {
                                          translations.commonWords.oldData[
                                            language
                                          ]
                                        }{" "}
                                        {historyData.appointments_per_time_slot ===
                                          null ||
                                        historyData.appointments_per_time_slot ===
                                          "null" ||
                                        historyData.appointments_per_time_slot ===
                                          ""
                                          ? "blank"
                                          : historyData.appointments_per_time_slot}
                                      </span>
                                    </div>
                                  )}
                                  <Field
                                    as="select"
                                    class={`form-select ${
                                      isDarkMode ? "custom-select" : ""
                                    }`}
                                    name="appointments_per_time_slot"
                                    aria-label="Default select example"
                                    id="rdv-par-plage-hor"
                                    style={{
                                      backgroundColor: isDarkMode
                                        ? "transparent"
                                        : "",
                                      color: isDarkMode ? "#ffffff" : "#000000",
                                      border: isDarkMode
                                        ? "1px solid #495057"
                                        : "1px solid #dee2e6",
                                    }}
                                  >
                                    <option
                                      value=""
                                      selected=""
                                      style={{
                                        background: isDarkMode ? "#1B1F22" : "",
                                        color: isDarkMode ? "#ffffff" : "",
                                      }}
                                    >
                                      {
                                        translations["dropdown"].select[
                                          language
                                        ]
                                      }
                                    </option>
                                    <option
                                      value="1"
                                      style={{
                                        background: isDarkMode ? "#1B1F22" : "",
                                        color: isDarkMode ? "#ffffff" : "",
                                      }}
                                    >
                                      1
                                    </option>
                                    <option
                                      value="2"
                                      style={{
                                        background: isDarkMode ? "#1B1F22" : "",
                                        color: isDarkMode ? "#ffffff" : "",
                                      }}
                                    >
                                      2
                                    </option>
                                    <option
                                      value="3"
                                      style={{
                                        background: isDarkMode ? "#1B1F22" : "",
                                        color: isDarkMode ? "#ffffff" : "",
                                      }}
                                    >
                                      3
                                    </option>
                                    <option
                                      value="4"
                                      style={{
                                        background: isDarkMode ? "#1B1F22" : "",
                                        color: isDarkMode ? "#ffffff" : "",
                                      }}
                                    >
                                      4
                                    </option>
                                    <option
                                      value="5"
                                      style={{
                                        background: isDarkMode ? "#1B1F22" : "",
                                        color: isDarkMode ? "#ffffff" : "",
                                      }}
                                    >
                                      5
                                    </option>
                                    <option
                                      value="6"
                                      style={{
                                        background: isDarkMode ? "#1B1F22" : "",
                                        color: isDarkMode ? "#ffffff" : "",
                                      }}
                                    >
                                      6
                                    </option>
                                    <option
                                      value="7"
                                      style={{
                                        background: isDarkMode ? "#1B1F22" : "",
                                        color: isDarkMode ? "#ffffff" : "",
                                      }}
                                    >
                                      7
                                    </option>
                                    <option
                                      value="8"
                                      style={{
                                        background: isDarkMode ? "#1B1F22" : "",
                                        color: isDarkMode ? "#ffffff" : "",
                                      }}
                                    >
                                      8
                                    </option>
                                    <option
                                      value="9"
                                      style={{
                                        background: isDarkMode ? "#1B1F22" : "",
                                        color: isDarkMode ? "#ffffff" : "",
                                      }}
                                    >
                                      9
                                    </option>
                                    <option
                                      value="10"
                                      style={{
                                        background: isDarkMode ? "#1B1F22" : "",
                                        color: isDarkMode ? "#ffffff" : "",
                                      }}
                                    >
                                      10
                                    </option>
                                    <option
                                      value="11"
                                      style={{
                                        background: isDarkMode ? "#1B1F22" : "",
                                        color: isDarkMode ? "#ffffff" : "",
                                      }}
                                    >
                                      11
                                    </option>
                                    <option
                                      value="12"
                                      style={{
                                        background: isDarkMode ? "#1B1F22" : "",
                                        color: isDarkMode ? "#ffffff" : "",
                                      }}
                                    >
                                      12
                                    </option>
                                    <option
                                      value="13"
                                      style={{
                                        background: isDarkMode ? "#1B1F22" : "",
                                        color: isDarkMode ? "#ffffff" : "",
                                      }}
                                    >
                                      13
                                    </option>
                                    <option
                                      value="14"
                                      style={{
                                        background: isDarkMode ? "#1B1F22" : "",
                                        color: isDarkMode ? "#ffffff" : "",
                                      }}
                                    >
                                      14
                                    </option>
                                    <option
                                      value="15"
                                      style={{
                                        background: isDarkMode ? "#1B1F22" : "",
                                        color: isDarkMode ? "#ffffff" : "",
                                      }}
                                    >
                                      15
                                    </option>
                                    <option
                                      value="16"
                                      style={{
                                        background: isDarkMode ? "#1B1F22" : "",
                                        color: isDarkMode ? "#ffffff" : "",
                                      }}
                                    >
                                      16
                                    </option>
                                    <option
                                      value="17"
                                      style={{
                                        background: isDarkMode ? "#1B1F22" : "",
                                        color: isDarkMode ? "#ffffff" : "",
                                      }}
                                    >
                                      17
                                    </option>
                                    <option
                                      value="18"
                                      style={{
                                        background: isDarkMode ? "#1B1F22" : "",
                                        color: isDarkMode ? "#ffffff" : "",
                                      }}
                                    >
                                      18
                                    </option>
                                    <option
                                      value="19"
                                      style={{
                                        background: isDarkMode ? "#1B1F22" : "",
                                        color: isDarkMode ? "#ffffff" : "",
                                      }}
                                    >
                                      19
                                    </option>
                                    <option
                                      value="20"
                                      style={{
                                        background: isDarkMode ? "#1B1F22" : "",
                                        color: isDarkMode ? "#ffffff" : "",
                                      }}
                                    >
                                      20
                                    </option>
                                  </Field>
                                  <ErrorMessage
                                    name="appointments_per_time_slot"
                                    component="div"
                                    className="error text-danger"
                                  />
                                </div>
                              </div>

                              <div class="col">
                                <div class="mb-3">
                                  <label
                                    for="heures-ouverture"
                                    data-bs-toggle="tooltip"
                                    title="Indicate the opening and closing hours of the dealership and the last appointment time for an entire week, from Monday to Sunday. Example: Monday - Friday: 10 am - 8 pm (LTA: 7 pm) | Saturday-Sunday: 10 am - 4 pm (LTA: 3 pm)"
                                    class="form-label"
                                  >
                                    {
                                      pageTranslations.formFields
                                        .salesInfoSection.openingHoursWLA[
                                        language
                                      ]
                                    }
                                  </label>
                                  {historyData.opening_hours !==
                                    formValues.opening_hours && (
                                    <div className="client-tooltip ">
                                      <div
                                        style={{ backgroundColor: "#34DBB4" }}
                                        className="mx-1 rounded d-flex justify-content-center  align-items-center "
                                      >
                                        <i
                                          className="bi bi-clock-history rounded-circle px-1 "
                                          style={{ fontSize: "12px" }}
                                        ></i>
                                      </div>
                                      <span className="client-tooltiptext">
                                        {
                                          translations.commonWords.oldData[
                                            language
                                          ]
                                        }{" "}
                                        {historyData.opening_hours === null ||
                                        historyData.opening_hours === "null" ||
                                        historyData.opening_hours === ""
                                          ? "blank"
                                          : historyData.opening_hours}
                                      </span>
                                    </div>
                                  )}
                                  <Field
                                    type="text"
                                    name="opening_hours"
                                    class="form-control"
                                    id="heures-ouverture"
                                    style={{
                                      backgroundColor: isDarkMode
                                        ? "transparent"
                                        : "",
                                      color: isDarkMode ? "#ffffff" : "#000000",
                                      border: isDarkMode
                                        ? "1px solid #495057"
                                        : "1px solid #dee2e6",
                                    }}
                                  />
                                  <ErrorMessage
                                    name="opening_hours"
                                    component="div"
                                    className="error text-danger"
                                  />
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-6">
                                <div class="mb-3">
                                  <label
                                    for="ach-vec-occ"
                                    data-bs-toggle="tooltip"
                                    title="Are you a buyer of used vehicles without a new purchase or lease from the customer?"
                                    class="form-label"
                                  >
                                    {
                                      pageTranslations.formFields
                                        .salesInfoSection.buyerOfUsedVehicles[
                                        language
                                      ]
                                    }
                                  </label>
                                  {historyData.new_purchase_or_lease !==
                                    formValues.new_purchase_or_lease && (
                                    <div className="client-tooltip ">
                                      <div
                                        style={{ backgroundColor: "#34DBB4" }}
                                        className="mx-1 rounded d-flex justify-content-center  align-items-center "
                                      >
                                        <i
                                          className="bi bi-clock-history rounded-circle px-1 "
                                          style={{ fontSize: "12px" }}
                                        ></i>
                                      </div>
                                      <span className="client-tooltiptext">
                                        {
                                          translations.commonWords.oldData[
                                            language
                                          ]
                                        }{" "}
                                        {historyData.new_purchase_or_lease ===
                                          null ||
                                        historyData.new_purchase_or_lease ===
                                          "null" ||
                                        historyData.new_purchase_or_lease === ""
                                          ? "blank"
                                          : historyData.new_purchase_or_lease}
                                      </span>
                                    </div>
                                  )}
                                  <Field
                                    as="select"
                                    class={`form-select ${
                                      isDarkMode ? "custom-select" : ""
                                    }`}
                                    name="new_purchase_or_lease"
                                    aria-label="Default select example"
                                    id="ach-vec-occ"
                                    style={{
                                      backgroundColor: isDarkMode
                                        ? "transparent"
                                        : "",
                                      color: isDarkMode ? "#ffffff" : "#000000",
                                      border: isDarkMode
                                        ? "1px solid #495057"
                                        : "1px solid #dee2e6",
                                    }}
                                  >
                                    <option
                                      value=""
                                      selected=""
                                      style={{
                                        background: isDarkMode ? "#1B1F22" : "",
                                        color: isDarkMode ? "#ffffff" : "",
                                      }}
                                    >
                                      {
                                        translations["dropdown"].select[
                                          language
                                        ]
                                      }
                                    </option>
                                    <option
                                      value="Yes"
                                      style={{
                                        background: isDarkMode ? "#1B1F22" : "",
                                        color: isDarkMode ? "#ffffff" : "",
                                      }}
                                    >
                                      {translations["dropdown"].yes[language]}
                                    </option>
                                    <option
                                      value="No"
                                      style={{
                                        background: isDarkMode ? "#1B1F22" : "",
                                        color: isDarkMode ? "#ffffff" : "",
                                      }}
                                    >
                                      {translations["dropdown"].no[language]}
                                    </option>
                                  </Field>
                                  <ErrorMessage
                                    name="new_purchase_or_lease"
                                    component="div"
                                    className="error text-danger"
                                  />
                                </div>
                              </div>
                              <div class="col-6">
                                <div class="mb-3">
                                  <label
                                    for="ass-rdv"
                                    data-bs-toggle="tooltip"
                                    title="Do you want to assign your appointments to the customer's representative?"
                                    class="form-label"
                                  >
                                    {
                                      pageTranslations.formFields
                                        .salesInfoSection.appointmentABS[
                                        language
                                      ]
                                    }
                                  </label>
                                  {historyData.assignment_by_seller !==
                                    formValues.assignment_by_seller && (
                                    <div className="client-tooltip ">
                                      <div
                                        style={{ backgroundColor: "#34DBB4" }}
                                        className="mx-1 rounded d-flex justify-content-center  align-items-center "
                                      >
                                        <i
                                          className="bi bi-clock-history rounded-circle px-1 "
                                          style={{ fontSize: "12px" }}
                                        ></i>
                                      </div>
                                      <span className="client-tooltiptext">
                                        {
                                          translations.commonWords.oldData[
                                            language
                                          ]
                                        }{" "}
                                        {historyData.assignment_by_seller ===
                                          null ||
                                        historyData.assignment_by_seller ===
                                          "null" ||
                                        historyData.assignment_by_seller === ""
                                          ? "blank"
                                          : historyData.assignment_by_seller}
                                      </span>
                                    </div>
                                  )}
                                  <Field
                                    as="select"
                                    class={`form-select ${
                                      isDarkMode ? "custom-select" : ""
                                    }`}
                                    name="assignment_by_seller"
                                    aria-label="Default select example"
                                    id="ass-rdv"
                                    style={{
                                      backgroundColor: isDarkMode
                                        ? "transparent"
                                        : "",
                                      color: isDarkMode ? "#ffffff" : "#000000",
                                      border: isDarkMode
                                        ? "1px solid #495057"
                                        : "1px solid #dee2e6",
                                    }}
                                  >
                                    <option
                                      value=""
                                      selected=""
                                      style={{
                                        background: isDarkMode ? "#1B1F22" : "",
                                        color: isDarkMode ? "#ffffff" : "",
                                      }}
                                    >
                                      {
                                        translations["dropdown"].select[
                                          language
                                        ]
                                      }
                                    </option>
                                    <option
                                      value="Assignment"
                                      style={{
                                        background: isDarkMode ? "#1B1F22" : "",
                                        color: isDarkMode ? "#ffffff" : "",
                                      }}
                                    >
                                      {
                                        pageTranslations.formFields
                                          .salesInfoSection.assignment[language]
                                      }
                                    </option>
                                    <option
                                      value="No Assignment"
                                      style={{
                                        background: isDarkMode ? "#1B1F22" : "",
                                        color: isDarkMode ? "#ffffff" : "",
                                      }}
                                    >
                                      {
                                        pageTranslations.formFields
                                          .salesInfoSection.noAssignment[
                                          language
                                        ]
                                      }
                                    </option>
                                  </Field>
                                  <ErrorMessage
                                    name="assignment_by_seller"
                                    component="div"
                                    className="error text-danger"
                                  />
                                </div>
                              </div>
                            </div>
                            <hr />
                            <div class="row">
                              <div class="col">
                                <div class="mb-3">
                                  <label
                                    for="accueille-les-clients"
                                    data-bs-toggle="tooltip"
                                    title="Who greets customers?"
                                    class="form-label"
                                  >
                                    {
                                      pageTranslations.formFields
                                        .salesInfoSection.greetsCustomers[
                                        language
                                      ]
                                    }
                                  </label>
                                  {historyData.greets_customers !==
                                    formValues.greets_customers && (
                                    <div className="client-tooltip ">
                                      <div
                                        style={{ backgroundColor: "#34DBB4" }}
                                        className="mx-1 rounded d-flex justify-content-center  align-items-center "
                                      >
                                        <i
                                          className="bi bi-clock-history rounded-circle px-1 "
                                          style={{ fontSize: "12px" }}
                                        ></i>
                                      </div>
                                      <span className="client-tooltiptext">
                                        {
                                          translations.commonWords.oldData[
                                            language
                                          ]
                                        }{" "}
                                        {historyData.greets_customers ===
                                          null ||
                                        historyData.greets_customers ===
                                          "null" ||
                                        historyData.greets_customers === ""
                                          ? "blank"
                                          : historyData.greets_customers}
                                      </span>
                                    </div>
                                  )}
                                  <Field
                                    as="select"
                                    class={`form-select ${
                                      isDarkMode ? "custom-select" : ""
                                    }`}
                                    name="greets_customers"
                                    aria-label="Default select example"
                                    id="accueille-les-clients"
                                    style={{
                                      backgroundColor: isDarkMode
                                        ? "transparent"
                                        : "",
                                      color: isDarkMode ? "#ffffff" : "#000000",
                                      border: isDarkMode
                                        ? "1px solid #495057"
                                        : "1px solid #dee2e6",
                                    }}
                                  >
                                    <option
                                      value=""
                                      selected=""
                                      style={{
                                        background: isDarkMode ? "#1B1F22" : "",
                                        color: isDarkMode ? "#ffffff" : "",
                                      }}
                                    >
                                      {
                                        translations["dropdown"].select[
                                          language
                                        ]
                                      }
                                    </option>
                                    <option
                                      value="Event Director"
                                      style={{
                                        background: isDarkMode ? "#1B1F22" : "",
                                        color: isDarkMode ? "#ffffff" : "",
                                      }}
                                    >
                                      {
                                        translations["dropdown"].eventDirector[
                                          language
                                        ]
                                      }
                                    </option>
                                    <option
                                      value="Dealership Employee"
                                      style={{
                                        background: isDarkMode ? "#1B1F22" : "",
                                        color: isDarkMode ? "#ffffff" : "",
                                      }}
                                    >
                                      {
                                        translations["dropdown"]
                                          .dealershipEmployee[language]
                                      }
                                    </option>
                                    <option
                                      value="Reception"
                                      style={{
                                        background: isDarkMode ? "#1B1F22" : "",
                                        color: isDarkMode ? "#ffffff" : "",
                                      }}
                                    >
                                      {
                                        translations["dropdown"].Reception[
                                          language
                                        ]
                                      }
                                    </option>
                                  </Field>
                                  <ErrorMessage
                                    name="greets_customers"
                                    component="div"
                                    className="error text-danger"
                                  />
                                </div>
                              </div>

                              <div class="col">
                                <div class="mb-3">
                                  <label
                                    for="nom-personne-accueil"
                                    data-bs-toggle="tooltip"
                                    title="The name of the person at the reception"
                                    class="form-label"
                                  >
                                    {
                                      pageTranslations.formFields
                                        .salesInfoSection
                                        .nameOfpersonatReception[language]
                                    }
                                  </label>
                                  {historyData.receptionist !==
                                    formValues.receptionist && (
                                    <div className="client-tooltip ">
                                      <div
                                        style={{ backgroundColor: "#34DBB4" }}
                                        className="mx-1 rounded d-flex justify-content-center  align-items-center "
                                      >
                                        <i
                                          className="bi bi-clock-history rounded-circle px-1 "
                                          style={{ fontSize: "12px" }}
                                        ></i>
                                      </div>
                                      <span className="client-tooltiptext">
                                        {
                                          translations.commonWords.oldData[
                                            language
                                          ]
                                        }{" "}
                                        {historyData.receptionist === null ||
                                        historyData.receptionist === "null" ||
                                        historyData.receptionist === ""
                                          ? "blank"
                                          : historyData.receptionist}
                                      </span>
                                    </div>
                                  )}
                                  <Field
                                    type="text"
                                    name="receptionist"
                                    className="form-control"
                                    id="nom-personne-accueil"
                                    style={{
                                      backgroundColor: isDarkMode
                                        ? "transparent"
                                        : "",
                                      color: isDarkMode ? "#ffffff" : "#000000",
                                      border: isDarkMode
                                        ? "1px solid #495057"
                                        : "1px solid #dee2e6",
                                    }}
                                  />
                                  <ErrorMessage
                                    name="receptionist"
                                    component="div"
                                    className="error text-danger"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row" style={{ paddingBottom: 10 }}>
                        <div
                          class={`collapse multi-collapse ${
                            isOpenAll ? "show" : ""
                          }`}
                          id="multiCollapseInfoscontact"
                        >
                          <div
                            class="card card-body"
                            style={{
                              border: isDarkMode
                                ? "1px solid #495057"
                                : "1px solid #dee2e6",
                            }}
                          >
                            <h2 className={`${isDarkMode ? "darkthead" : ""}`}>
                              Contact
                            </h2>

                            <div class="row">
                              <h4>
                                {
                                  pageTranslations.formFields.contactSection
                                    .themePromos[language]
                                }
                              </h4>

                              <div class="col">
                                <div class="mb-3">
                                  <label
                                    for="rep-theme-promos-nom"
                                    data-bs-toggle="tooltip"
                                    title="Name of the person in charge of the Theme and Promotions"
                                    class="form-label"
                                  >
                                    {
                                      translations["commonFields"].name[
                                        language
                                      ]
                                    }
                                  </label>
                                  {historyData.contact_tp_name !==
                                    formValues.contact_tp_name && (
                                    <div className="client-tooltip ">
                                      <div
                                        style={{ backgroundColor: "#34DBB4" }}
                                        className="mx-1 rounded d-flex justify-content-center  align-items-center "
                                      >
                                        <i
                                          className="bi bi-clock-history rounded-circle px-1 "
                                          style={{ fontSize: "12px" }}
                                        ></i>
                                      </div>
                                      <span className="client-tooltiptext">
                                        {
                                          translations.commonWords.oldData[
                                            language
                                          ]
                                        }{" "}
                                        {historyData.contact_tp_name === null ||
                                        historyData.contact_tp_name ===
                                          "null" ||
                                        historyData.contact_tp_name === ""
                                          ? "blank"
                                          : historyData.contact_tp_name}
                                      </span>
                                    </div>
                                  )}
                                  <Field
                                    type="text"
                                    name="contact_tp_name"
                                    class="form-control"
                                    id="rep-theme-promos-nom"
                                    style={{
                                      backgroundColor: isDarkMode
                                        ? "transparent"
                                        : "",
                                      color: isDarkMode ? "#ffffff" : "#000000",
                                      border: isDarkMode
                                        ? "1px solid #495057"
                                        : "1px solid #dee2e6",
                                    }}
                                  />
                                  <ErrorMessage
                                    name="contact_tp_name"
                                    component="div"
                                    className="error text-danger"
                                  />
                                </div>
                              </div>

                              <div class="col">
                                <div class="mb-3">
                                  <label
                                    for="rep-theme-promos-courriel"
                                    data-bs-toggle="tooltip"
                                    title="Email of the person in charge of the Theme and Promotions"
                                    class="form-label"
                                  >
                                    {
                                      translations["commonFields"].email[
                                        language
                                      ]
                                    }
                                  </label>
                                  {historyData.contact_tp_email !==
                                    formValues.contact_tp_email && (
                                    <div className="client-tooltip ">
                                      <div
                                        style={{ backgroundColor: "#34DBB4" }}
                                        className="mx-1 rounded d-flex justify-content-center  align-items-center "
                                      >
                                        <i
                                          className="bi bi-clock-history rounded-circle px-1 "
                                          style={{ fontSize: "12px" }}
                                        ></i>
                                      </div>
                                      <span className="client-tooltiptext">
                                        {
                                          translations.commonWords.oldData[
                                            language
                                          ]
                                        }{" "}
                                        {historyData.contact_tp_email ===
                                          null ||
                                        historyData.contact_tp_email ===
                                          "null" ||
                                        historyData.contact_tp_email === ""
                                          ? "blank"
                                          : historyData.contact_tp_email}
                                      </span>
                                    </div>
                                  )}
                                  <Field
                                    type="text"
                                    name="contact_tp_email"
                                    class="form-control"
                                    id="rep-theme-promos-courriel"
                                    style={{
                                      backgroundColor: isDarkMode
                                        ? "transparent"
                                        : "",
                                      color: isDarkMode ? "#ffffff" : "#000000",
                                      border: isDarkMode
                                        ? "1px solid #495057"
                                        : "1px solid #dee2e6",
                                    }}
                                  />
                                  <ErrorMessage
                                    name="contact_tp_email"
                                    component="div"
                                    className="error text-danger"
                                  />
                                </div>
                              </div>
                              <div class="col">
                                <div class="mb-3">
                                  <label
                                    for="rep-theme-promos-tel"
                                    data-bs-toggle="tooltip"
                                    title="Phone of the person in charge of the Theme and Promotions"
                                    class="form-label"
                                  >
                                    {
                                      translations["commonFields"].phone[
                                        language
                                      ]
                                    }
                                  </label>
                                  {historyData.contact_tp_phone !==
                                    formValues.contact_tp_phone && (
                                    <div className="client-tooltip ">
                                      <div
                                        style={{ backgroundColor: "#34DBB4" }}
                                        className="mx-1 rounded d-flex justify-content-center  align-items-center "
                                      >
                                        <i
                                          className="bi bi-clock-history rounded-circle px-1 "
                                          style={{ fontSize: "12px" }}
                                        ></i>
                                      </div>
                                      <span className="client-tooltiptext">
                                        {
                                          translations.commonWords.oldData[
                                            language
                                          ]
                                        }{" "}
                                        {historyData.contact_tp_phone ===
                                          null ||
                                        historyData.contact_tp_phone ===
                                          "null" ||
                                        historyData.contact_tp_phone === ""
                                          ? "blank"
                                          : historyData.contact_tp_phone}
                                      </span>
                                    </div>
                                  )}
                                  <Field
                                    type="text"
                                    name="contact_tp_phone"
                                    class="form-control"
                                    id="rep-theme-promos-tel"
                                    style={{
                                      backgroundColor: isDarkMode
                                        ? "transparent"
                                        : "",
                                      color: isDarkMode ? "#ffffff" : "#000000",
                                      border: isDarkMode
                                        ? "1px solid #495057"
                                        : "1px solid #dee2e6",
                                    }}
                                  />
                                  <ErrorMessage
                                    name="contact_tp_phone"
                                    component="div"
                                    className="error text-danger"
                                  />
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <h4>
                                {
                                  pageTranslations.formFields.contactSection
                                    .lists[language]
                                }
                              </h4>

                              <div class="col">
                                <div class="mb-3">
                                  <label
                                    for="rep-listes-nom"
                                    data-bs-toggle="tooltip"
                                    title="Name of the person in charge of the Lists"
                                    class="form-label"
                                  >
                                    {
                                      translations["commonFields"].name[
                                        language
                                      ]
                                    }
                                  </label>
                                  {historyData.contact_list_name !==
                                    formValues.contact_list_name && (
                                    <div className="client-tooltip ">
                                      <div
                                        style={{ backgroundColor: "#34DBB4" }}
                                        className="mx-1 rounded d-flex justify-content-center  align-items-center "
                                      >
                                        <i
                                          className="bi bi-clock-history rounded-circle px-1 "
                                          style={{ fontSize: "12px" }}
                                        ></i>
                                      </div>
                                      <span className="client-tooltiptext">
                                        {
                                          translations.commonWords.oldData[
                                            language
                                          ]
                                        }{" "}
                                        {historyData.contact_list_name ===
                                          null ||
                                        historyData.contact_list_name ===
                                          "null" ||
                                        historyData.contact_list_name === ""
                                          ? "blank"
                                          : historyData.contact_list_name}
                                      </span>
                                    </div>
                                  )}
                                  <Field
                                    type="text"
                                    name="contact_list_name"
                                    class="form-control"
                                    id="rep-listes-nom"
                                    style={{
                                      backgroundColor: isDarkMode
                                        ? "transparent"
                                        : "",
                                      color: isDarkMode ? "#ffffff" : "#000000",
                                      border: isDarkMode
                                        ? "1px solid #495057"
                                        : "1px solid #dee2e6",
                                    }}
                                  />
                                  <ErrorMessage
                                    name="contact_list_name"
                                    component="div"
                                    className="error text-danger"
                                  />
                                </div>
                              </div>

                              <div class="col">
                                <div class="mb-3">
                                  <label
                                    for="rep-listes-courriel"
                                    data-bs-toggle="tooltip"
                                    title="Email of the person in charge of the Lists"
                                    class="form-label"
                                  >
                                    {
                                      translations["commonFields"].email[
                                        language
                                      ]
                                    }
                                  </label>
                                  {historyData.contact_list_email !==
                                    formValues.contact_list_email && (
                                    <div className="client-tooltip ">
                                      <div
                                        style={{ backgroundColor: "#34DBB4" }}
                                        className="mx-1 rounded d-flex justify-content-center  align-items-center "
                                      >
                                        <i
                                          className="bi bi-clock-history rounded-circle px-1 "
                                          style={{ fontSize: "12px" }}
                                        ></i>
                                      </div>
                                      <span className="client-tooltiptext">
                                        {
                                          translations.commonWords.oldData[
                                            language
                                          ]
                                        }{" "}
                                        {historyData.contact_list_email ===
                                          null ||
                                        historyData.contact_list_email ===
                                          "null" ||
                                        historyData.contact_list_email === ""
                                          ? "blank"
                                          : historyData.contact_list_email}
                                      </span>
                                    </div>
                                  )}
                                  <Field
                                    type="text"
                                    name="contact_list_email"
                                    class="form-control"
                                    id="rep-listes-courriel"
                                    style={{
                                      backgroundColor: isDarkMode
                                        ? "transparent"
                                        : "",
                                      color: isDarkMode ? "#ffffff" : "#000000",
                                      border: isDarkMode
                                        ? "1px solid #495057"
                                        : "1px solid #dee2e6",
                                    }}
                                  />
                                  <ErrorMessage
                                    name="contact_list_email"
                                    component="div"
                                    className="error text-danger"
                                  />
                                </div>
                              </div>
                              <div class="col">
                                <div class="mb-3">
                                  <label
                                    for="rep-listes-tel"
                                    data-bs-toggle="tooltip"
                                    title="Phone of the person in charge of the Lists"
                                    class="form-label"
                                  >
                                    {
                                      translations["commonFields"].phone[
                                        language
                                      ]
                                    }
                                  </label>
                                  {historyData.contact_list_phone !==
                                    formValues.contact_list_phone && (
                                    <div className="client-tooltip ">
                                      <div
                                        style={{ backgroundColor: "#34DBB4" }}
                                        className="mx-1 rounded d-flex justify-content-center  align-items-center "
                                      >
                                        <i
                                          className="bi bi-clock-history rounded-circle px-1 "
                                          style={{ fontSize: "12px" }}
                                        ></i>
                                      </div>
                                      <span className="client-tooltiptext">
                                        {
                                          translations.commonWords.oldData[
                                            language
                                          ]
                                        }{" "}
                                        {historyData.contact_list_phone ===
                                          null ||
                                        historyData.contact_list_phone ===
                                          "null" ||
                                        historyData.contact_list_phone === ""
                                          ? "blank"
                                          : historyData.contact_list_phone}
                                      </span>
                                    </div>
                                  )}
                                  <Field
                                    type="text"
                                    name="contact_list_phone"
                                    class="form-control"
                                    id="rep-listes-tel"
                                    style={{
                                      backgroundColor: isDarkMode
                                        ? "transparent"
                                        : "",
                                      color: isDarkMode ? "#ffffff" : "#000000",
                                      border: isDarkMode
                                        ? "1px solid #495057"
                                        : "1px solid #dee2e6",
                                    }}
                                  />
                                  <ErrorMessage
                                    name="contact_list_phone"
                                    component="div"
                                    className="error text-danger"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row" style={{ paddingBottom: 10 }}>
                        <div
                          class={`collapse multi-collapse ${
                            isOpenAll ? "show" : ""
                          }`}
                          id="multiCollapseInfosequipe"
                        >
                          <div
                            class="card card-body"
                            style={{
                              border: isDarkMode
                                ? "1px solid #495057"
                                : "1px solid #dee2e6",
                            }}
                          >
                            <h2 className={`${isDarkMode ? "darkthead" : ""}`}>
                              {
                                pageTranslations.formFields.teamSection.title[
                                  language
                                ]
                              }
                            </h2>

                            <div class="row">
                              <div class="col">
                                <div class="mb-3">
                                  <label
                                    for="equipe-directeurs"
                                    data-bs-toggle="tooltip"
                                    title="Name of the Director(s)"
                                    class="form-label"
                                  >
                                    {
                                      pageTranslations.formFields.teamSection
                                        .directors[language]
                                    }
                                  </label>
                                  {historyData.directors !==
                                    formValues.directors && (
                                    <div className="client-tooltip ">
                                      <div
                                        style={{ backgroundColor: "#34DBB4" }}
                                        className="mx-1 rounded d-flex justify-content-center  align-items-center "
                                      >
                                        <i
                                          className="bi bi-clock-history rounded-circle px-1 "
                                          style={{ fontSize: "12px" }}
                                        ></i>
                                      </div>
                                      <span className="client-tooltiptext">
                                        {
                                          translations.commonWords.oldData[
                                            language
                                          ]
                                        }{" "}
                                        {historyData.directors === null ||
                                        historyData.directors === "null" ||
                                        historyData.directors === ""
                                          ? "blank"
                                          : historyData.directors}
                                      </span>
                                    </div>
                                  )}
                                  <Field
                                    type="text"
                                    name="directors"
                                    class="form-control"
                                    id="equipe-directeurs"
                                    style={{
                                      backgroundColor: isDarkMode
                                        ? "transparent"
                                        : "",
                                      color: isDarkMode ? "#ffffff" : "#000000",
                                      border: isDarkMode
                                        ? "1px solid #495057"
                                        : "1px solid #dee2e6",
                                    }}
                                  />
                                  <ErrorMessage
                                    name="directors"
                                    component="div"
                                    className="error text-danger"
                                  />
                                </div>
                              </div>

                              <div class="col">
                                <div class="mb-3">
                                  <label
                                    for="equipe-rep"
                                    data-bs-toggle="tooltip"
                                    title="Name of the Representatives"
                                    class="form-label"
                                  >
                                    {
                                      pageTranslations.formFields.teamSection
                                        .representative[language]
                                    }
                                  </label>
                                  {historyData.representatives !==
                                    formValues.representatives && (
                                    <div className="client-tooltip ">
                                      <div
                                        style={{ backgroundColor: "#34DBB4" }}
                                        className="mx-1 rounded d-flex justify-content-center  align-items-center "
                                      >
                                        <i
                                          className="bi bi-clock-history rounded-circle px-1 "
                                          style={{ fontSize: "12px" }}
                                        ></i>
                                      </div>
                                      <span className="client-tooltiptext">
                                        {
                                          translations.commonWords.oldData[
                                            language
                                          ]
                                        }{" "}
                                        {historyData.representatives === null ||
                                        historyData.representatives ===
                                          "null" ||
                                        historyData.representatives === ""
                                          ? "blank"
                                          : historyData.representatives}
                                      </span>
                                    </div>
                                  )}
                                  <Field
                                    type="text"
                                    name="representatives"
                                    class="form-control"
                                    id="equipe-rep"
                                    style={{
                                      backgroundColor: isDarkMode
                                        ? "transparent"
                                        : "",
                                      color: isDarkMode ? "#ffffff" : "#000000",
                                      border: isDarkMode
                                        ? "1px solid #495057"
                                        : "1px solid #dee2e6",
                                    }}
                                  />
                                  <ErrorMessage
                                    name="representatives"
                                    component="div"
                                    className="error text-danger"
                                  />
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              {userType &&
                                !internalUsers.includes(userType) && (
                                  <div class="col-6">
                                    <div class="mb-3">
                                      <label
                                        for="equipe-directeurs"
                                        data-bs-toggle="tooltip"
                                        title="Name of the Director(s)"
                                        class="form-label"
                                      >
                                        {
                                          pageTranslations.formFields
                                            .teamSection.responsible_payable[
                                            language
                                          ]
                                        }
                                      </label>
                                      {historyData.responsible_payable !==
                                        formValues.responsible_payable && (
                                        <div className="client-tooltip ">
                                          <div
                                            style={{
                                              backgroundColor: "#34DBB4",
                                            }}
                                            className="mx-1 rounded d-flex justify-content-center  align-items-center "
                                          >
                                            <i
                                              className="bi bi-clock-history rounded-circle px-1 "
                                              style={{ fontSize: "12px" }}
                                            ></i>
                                          </div>
                                          <span className="client-tooltiptext">
                                            {
                                              translations.commonWords.oldData[
                                                language
                                              ]
                                            }{" "}
                                            {historyData.responsible_payable ===
                                              null ||
                                            historyData.responsible_payable ===
                                              "null" ||
                                            historyData.responsible_payable ===
                                              ""
                                              ? "blank"
                                              : historyData.responsible_payable}
                                          </span>
                                        </div>
                                      )}
                                      <Field
                                        type="email"
                                        name="responsible_payable"
                                        class="form-control"
                                        id="equipe-directeurs"
                                        style={{
                                          backgroundColor: isDarkMode
                                            ? "transparent"
                                            : "",
                                          color: isDarkMode
                                            ? "#ffffff"
                                            : "#000000",
                                          border: isDarkMode
                                            ? "1px solid #495057"
                                            : "1px solid #dee2e6",
                                        }}
                                      />
                                      <ErrorMessage
                                        name="responsible_payable"
                                        component="div"
                                        className="error text-danger"
                                      />
                                    </div>
                                  </div>
                                )}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row" style={{ paddingBottom: 10 }}>
                        <div
                          class={`collapse multi-collapse ${
                            isOpenAll ? "show" : ""
                          }`}
                          id="multiCollapseInfospromos"
                        >
                          <div
                            class="card card-body"
                            style={{
                              border: isDarkMode
                                ? "1px solid #495057"
                                : "1px solid #dee2e6",
                            }}
                          >
                            <h2 className={`${isDarkMode ? "darkthead" : ""}`}>
                              {
                                pageTranslations.formFields
                                  .promoAndRequestSection.title[language]
                              }
                            </h2>

                            <div class="row">
                              <div class="col">
                                <div class="mb-3">
                                  <label
                                    for="event-promos"
                                    data-bs-toggle="tooltip"
                                    title="Promotions for the event"
                                    class="form-label"
                                  >
                                    {
                                      pageTranslations.formFields
                                        .promoAndRequestSection.promotions[
                                        language
                                      ]
                                    }
                                  </label>
                                  {historyData.promotions.replace(
                                    /[\r\n]/g,
                                    ""
                                  ) !==
                                    formValues.promotions.replace(
                                      /[\r\n]/g,
                                      ""
                                    ) && (
                                    <div className="client-tooltip ">
                                      <div
                                        style={{ backgroundColor: "#34DBB4" }}
                                        className="mx-1 rounded d-flex justify-content-center  align-items-center "
                                      >
                                        <i
                                          className="bi bi-clock-history rounded-circle px-1 "
                                          style={{ fontSize: "12px" }}
                                        ></i>
                                      </div>
                                      <span className="client-tooltiptext">
                                        {
                                          translations.commonWords.oldData[
                                            language
                                          ]
                                        }{" "}
                                        {historyData.promotions === null ||
                                        historyData.promotions === "null" ||
                                        historyData.promotions === ""
                                          ? "blank"
                                          : historyData.promotions}
                                      </span>
                                    </div>
                                  )}
                                  <Field
                                    as="textarea"
                                    type="text"
                                    name="promotions"
                                    class="form-control"
                                    id="event-promos"
                                    style={{
                                      backgroundColor: isDarkMode
                                        ? "transparent"
                                        : "",
                                      color: isDarkMode ? "#ffffff" : "#000000",
                                      border: isDarkMode
                                        ? "1px solid #495057"
                                        : "1px solid #dee2e6",
                                    }}
                                  ></Field>
                                  <ErrorMessage
                                    name="promotions"
                                    component="div"
                                    className="error text-danger"
                                  />
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col">
                                <div class="mb-3">
                                  <label
                                    for="event-comm"
                                    data-bs-toggle="tooltip"
                                    title="Comments for the event"
                                    class="form-label"
                                  >
                                    {
                                      pageTranslations.formFields
                                        .promoAndRequestSection.comments[
                                        language
                                      ]
                                    }
                                  </label>
                                  {historyData.comments.replace(
                                    /[\r\n]/g,
                                    ""
                                  ) !==
                                    formValues.comments.replace(
                                      /[\r\n]/g,
                                      ""
                                    ) && (
                                    <div className="client-tooltip ">
                                      <div
                                        style={{ backgroundColor: "#34DBB4" }}
                                        className="mx-1 rounded d-flex justify-content-center  align-items-center "
                                      >
                                        <i
                                          className="bi bi-clock-history rounded-circle px-1 "
                                          style={{ fontSize: "12px" }}
                                        ></i>
                                      </div>
                                      <span className="client-tooltiptext">
                                        {
                                          translations.commonWords.oldData[
                                            language
                                          ]
                                        }{" "}
                                        {historyData.comments === null ||
                                        historyData.comments === "null" ||
                                        historyData.comments === ""
                                          ? "blank"
                                          : historyData.comments}
                                      </span>
                                    </div>
                                  )}
                                  <Field
                                    as="textarea"
                                    type="text"
                                    name="comments"
                                    class="form-control"
                                    id="event-comm"
                                    style={{
                                      backgroundColor: isDarkMode
                                        ? "transparent"
                                        : "",
                                      color: isDarkMode ? "#ffffff" : "#000000",
                                      border: isDarkMode
                                        ? "1px solid #495057"
                                        : "1px solid #dee2e6",
                                    }}
                                  ></Field>
                                  <ErrorMessage
                                    name="comments"
                                    component="div"
                                    className="error text-danger"
                                  />
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col">
                                <div class="mb-3">
                                  <label
                                    for="modifSelection"
                                    data-bs-toggle="tooltip"
                                    title=""
                                    class="form-label"
                                  >
                                    {
                                      pageTranslations.formFields
                                        .promoAndRequestSection.q1[language]
                                    }
                                  </label>
                                  {historyData.modifications_instructions.replace(
                                    /[\r\n]/g,
                                    ""
                                  ) !==
                                    formValues.modifications_instructions.replace(
                                      /[\r\n]/g,
                                      ""
                                    ) && (
                                    <div className="client-tooltip ">
                                      <div
                                        style={{ backgroundColor: "#34DBB4" }}
                                        className="mx-1 rounded d-flex justify-content-center  align-items-center "
                                      >
                                        <i
                                          className="bi bi-clock-history rounded-circle px-1 "
                                          style={{ fontSize: "12px" }}
                                        ></i>
                                      </div>
                                      <span className="client-tooltiptext">
                                        {
                                          translations.commonWords.oldData[
                                            language
                                          ]
                                        }{" "}
                                        {historyData.modifications_instructions ===
                                          null ||
                                        historyData.modifications_instructions ===
                                          "null" ||
                                        historyData.modifications_instructions ===
                                          ""
                                          ? "blank"
                                          : historyData.modifications_instructions}
                                      </span>
                                    </div>
                                  )}
                                  <Field
                                    as="textarea"
                                    type="text"
                                    name="modifications_instructions"
                                    class="form-control"
                                    id="modifSelection"
                                    style={{
                                      backgroundColor: isDarkMode
                                        ? "transparent"
                                        : "",
                                      color: isDarkMode ? "#ffffff" : "#000000",
                                      border: isDarkMode
                                        ? "1px solid #495057"
                                        : "1px solid #dee2e6",
                                    }}
                                  />
                                  <ErrorMessage
                                    name="modifications_instructions"
                                    component="div"
                                    className="error text-danger"
                                  />
                                </div>
                              </div>
                            </div>

                            <div class="row">
                              <div class="col-8">
                                <div class="mb-3">
                                  <label
                                    for="modeleAnnPrio"
                                    data-bs-toggle="tooltip"
                                    title="Do you have specific models or years that you would like us to prioritize in the selection?"
                                    class="form-label"
                                  >
                                    {
                                      pageTranslations.formFields
                                        .promoAndRequestSection.q2[language]
                                    }
                                  </label>
                                  {historyData.models_or_years_selection !==
                                    formValues.models_or_years_selection && (
                                    <div className="client-tooltip ">
                                      <div
                                        style={{ backgroundColor: "#34DBB4" }}
                                        className="mx-1 rounded d-flex justify-content-center  align-items-center "
                                      >
                                        <i
                                          className="bi bi-clock-history rounded-circle px-1 "
                                          style={{ fontSize: "12px" }}
                                        ></i>
                                      </div>
                                      <span className="client-tooltiptext">
                                        {
                                          translations.commonWords.oldData[
                                            language
                                          ]
                                        }{" "}
                                        {historyData.models_or_years_selection ===
                                          null ||
                                        historyData.models_or_years_selection ===
                                          "null" ||
                                        historyData.models_or_years_selection ===
                                          ""
                                          ? "blank"
                                          : historyData.models_or_years_selection}
                                      </span>
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div class="col-4">
                                <div class="mb-3">
                                  <Field
                                    as="select"
                                    class={`form-select ${
                                      isDarkMode ? "custom-select" : ""
                                    }`}
                                    name="models_or_years_selection"
                                    aria-label="Default select example"
                                    id="modeleAnnPrio"
                                    style={{
                                      backgroundColor: isDarkMode
                                        ? "transparent"
                                        : "",
                                      color: isDarkMode ? "#ffffff" : "#000000",
                                      border: isDarkMode
                                        ? "1px solid #495057"
                                        : "1px solid #dee2e6",
                                    }}
                                  >
                                    <option
                                      value=""
                                      selected=""
                                      style={{
                                        background: isDarkMode ? "#1B1F22" : "",
                                        color: isDarkMode ? "#ffffff" : "",
                                      }}
                                    >
                                      {
                                        translations["dropdown"].select[
                                          language
                                        ]
                                      }
                                    </option>
                                    <option
                                      value="Yes"
                                      style={{
                                        background: isDarkMode ? "#1B1F22" : "",
                                        color: isDarkMode ? "#ffffff" : "",
                                      }}
                                    >
                                      {translations["dropdown"].yes[language]}
                                    </option>
                                    <option
                                      value="No"
                                      style={{
                                        background: isDarkMode ? "#1B1F22" : "",
                                        color: isDarkMode ? "#ffffff" : "",
                                      }}
                                    >
                                      {translations["dropdown"].no[language]}
                                    </option>
                                  </Field>
                                  <ErrorMessage
                                    name="models_or_years_selection"
                                    component="div"
                                    className="error text-danger"
                                  />
                                </div>
                              </div>
                            </div>
                            {values.models_or_years_selection === "Yes" && (
                              <div class="row">
                                <div class="col">
                                  <div class="mb-3">
                                    <label
                                      for="modeleAnnPrioOui"
                                      data-bs-toggle="tooltip"
                                      title=""
                                      class="form-label"
                                    >
                                      {
                                        pageTranslations.formFields
                                          .promoAndRequestSection.q3[language]
                                      }
                                    </label>
                                    {historyData.prioriti_reason.replace(
                                      /[\r\n]/g,
                                      ""
                                    ) !==
                                      formValues.prioriti_reason.replace(
                                        /[\r\n]/g,
                                        ""
                                      ) && (
                                      <div className="client-tooltip ">
                                        <div
                                          style={{ backgroundColor: "#34DBB4" }}
                                          className="mx-1 rounded d-flex justify-content-center  align-items-center "
                                        >
                                          <i
                                            className="bi bi-clock-history rounded-circle px-1 "
                                            style={{ fontSize: "12px" }}
                                          ></i>
                                        </div>
                                        <span className="client-tooltiptext">
                                          {
                                            translations.commonWords.oldData[
                                              language
                                            ]
                                          }{" "}
                                          {historyData.prioriti_reason ===
                                            null ||
                                          historyData.prioriti_reason ===
                                            "null" ||
                                          historyData.prioriti_reason === ""
                                            ? "blank"
                                            : historyData.prioriti_reason}
                                        </span>
                                      </div>
                                    )}
                                    <Field
                                      as="textarea"
                                      type="text"
                                      name="prioriti_reason"
                                      class="form-control"
                                      id="modeleAnnPrioOui"
                                      style={{
                                        backgroundColor: isDarkMode
                                          ? "transparent"
                                          : "",
                                        color: isDarkMode
                                          ? "#ffffff"
                                          : "#000000",
                                        border: isDarkMode
                                          ? "1px solid #495057"
                                          : "1px solid #dee2e6",
                                      }}
                                    />
                                    <ErrorMessage
                                      name="prioriti_reason"
                                      component="div"
                                      className="error text-danger"
                                    />
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div
                        style={{ marginBottom: "10px !important" }}
                        class="row"
                      >
                        <div
                          style={{ margin: "10px 0", paddingBottom: "10px" }}
                          class=""
                          id=""
                        >
                          <div class="card card-body docsection">
                            <h3 className={`${isDarkMode ? "darkthead" : ""}`}>
                              Documents
                            </h3>
                            <table class="table table-hover text-center align-middle table-sm">
                              <thead>
                                <tr>
                                  <th
                                    scope="col"
                                    className={`${
                                      isDarkMode ? "darkthead" : ""
                                    }`}
                                  >
                                    #
                                  </th>
                                  <th
                                    scope="col"
                                    className={`${
                                      isDarkMode ? "darkthead" : ""
                                    }`}
                                  >
                                    {pageTranslations.formFields.week[language]}
                                  </th>
                                  <th
                                    scope="col"
                                    className={`${
                                      isDarkMode ? "darkthead" : ""
                                    }`}
                                  >
                                    Type
                                  </th>
                                  <th
                                    scope="col"
                                    className={`${
                                      isDarkMode ? "darkthead" : ""
                                    }`}
                                  >
                                    {
                                      pageTranslations.formFields
                                        .documentSection.creationDate[language]
                                    }
                                  </th>
                                  <th
                                    scope="col"
                                    className={`${
                                      isDarkMode ? "darkthead" : ""
                                    }`}
                                  >
                                    {
                                      pageTranslations.formFields
                                        .documentSection.createdBy[language]
                                    }
                                  </th>
                                  <th scope="col"></th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>
                              <tbody id="searchTableResults" class="">
                                {documents &&
                                  documents.map((document) => (
                                    <tr class="result-box">
                                      <td class="docIdTable" scope="row">
                                        {document.id}
                                      </td>
                                      <td class="docWeekTable">
                                        {document.week}{" "}
                                      </td>
                                      <td class="docTypeTable">
                                        {document.type}
                                      </td>
                                      <td class="docCreatedDateTable">
                                        {document?.formatted_created_at}
                                      </td>
                                      <td class="docCreatedByTable">
                                        {document?.name}
                                      </td>
                                      <td>
                                        <a
                                          type="button"
                                          href={document.filelink}
                                          target="”_blank”"
                                          class="admin-btn btn-primary doc-download-table"
                                          id=""
                                        >
                                          <i class="bi bi-file-earmark-arrow-down"></i>{" "}
                                          {
                                            pageTranslations.formFields
                                              .documentSection.download[
                                              language
                                            ]
                                          }
                                        </a>
                                      </td>
                                      <td>
                                        <button
                                          type="button"
                                          class="admin-btn btn-primary doc-send-table"
                                          id="FDD1816"
                                          data-docidtablecreated="1816"
                                          onClick={() => {
                                            sendDocument(
                                              document.type
                                                .toLowerCase()
                                                .replace(/\s/g, "_"),
                                              document.filelink,
                                              document.week,
                                              formValues.dealershipName
                                            );
                                          }}
                                        >
                                          <i class="bi bi-send"></i>{" "}
                                          {
                                            pageTranslations.formFields
                                              .documentSection.send[language]
                                          }{" "}
                                        </button>
                                      </td>
                                    </tr>
                                  ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="footersec" style={{ marginTop: 10 }}>
                      <div class="row">
                        <div class="col-sm mt-4">
                          <button
                            style={{ margin: "0 10px" }}
                            type="submit"
                            class="client-btn btn btn-success"
                            id="save-changes"
                            onClick={() => setSubmitPressed(true)}
                          >
                            <i class="bi bi-save"></i>{" "}
                            {translations["commonFields"].saveBtn[language]}
                          </button>
                          <button
                            type="button"
                            class="btn btn-danger"
                            id="cancel-changes"
                            onClick={() => window.history.back()}
                          >
                            <i class="bi bi-x-circle"></i>{" "}
                            {translations["commonFields"].returnBtn[language]}{" "}
                          </button>
                          <div
                            style={{ margin: "0 10px" }}
                            class="btn-group"
                            role="group"
                          >
                            <button
                              type="button"
                              class="admin-btn btn btn-primary"
                              id="prod-form-FP"
                              data-bs-toggle="modal"
                              data-bs-target="#sendPdfModal"
                              onClick={() => {
                                setFormType("pre_form");
                                // handleFormDownload("edit-pre-form",values);
                                setWeek(values.week);
                                saveChanges("edit-pre-form", values);
                              }}
                            >
                              <i class="bi bi-file-earmark-text"></i> Form Pre
                            </button>
                            <button
                              type="button"
                              class="btn btn-light"
                              id="prod-form-DG"
                              data-bs-toggle="modal"
                              data-bs-target="#sendPdfModal"
                              onClick={() => {
                                setFormType("gd_form");
                                // handleFormDownload("edit-gd-form",values);
                                setWeek(values.week);
                                saveChanges("edit-gd-form", values);
                              }}
                            >
                              <i class="bi bi-file-earmark-text"></i> Form DG
                            </button>
                            <button
                              type="button"
                              class="admin-btn btn btn-primary"
                              id="prod-form-CC"
                              data-bs-toggle="modal"
                              data-bs-target="#sendPdfModal"
                              onClick={() => {
                                setFormType("ca_form");
                                // handleFormDownload("edit-ca-form",values);
                                setWeek(values.week);
                                saveChanges("edit-ca-form", values);
                              }}
                            >
                              <i class="bi bi-file-earmark-text"></i> Form CA
                            </button>
                          </div>
                          {/* <button type="button" class="client-btn btn btn-success" id="send-form-modal" data-bs-toggle="modal" data-bs-target="#sendFormModal"><i class="bi bi-send"></i> Send</button> */}
                          <a
                            type="button"
                            target="_blank"
                            class="btn btn-light drive-button"
                            id="evt-drive-link"
                            href={values.drive_link}
                          >
                            <i class="bi bi-folder"></i> Drive
                          </a>
                          <button
                            style={{ margin: "0 10px" }}
                            type="button"
                            className="btn admin-btn btn-primary"
                            id="convert-to-main"
                            data-customer-id="29"
                            data-bs-toggle="modal"
                            data-bs-target="#copyEvent"
                            onClick={() => {
                              setEventId(formValues.id);
                              saveChanges("", values);
                            }}
                          >
                            <i className="bi bi-file-earmark-text"></i>{" "}
                            {translations["commonFields"].convertBtn[language]}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          )}

          <AddWeekModal
            handleCopy={handleCopy}
            eventId={eventId}
            week={week}
            setWeek={setWeek}
            setEventId={setEventId}
            setDisplayComponent={setDisplayComponent}
            isDarkMode={isDarkMode}
          />

          <SendPdfModal
            token={token}
            EventID={eventId}
            formType={formType}
            formUrl={formUrl}
            week={week}
            driveLink={driveLink}
            setFormUrl={setFormUrl}
            dealer_name={formValues}
            isDarkMode={isDarkMode}
          />

          {/*<div id="myModal2" class="modal fade" tabindex="-1">
            <div class="modal-dialog modal-dialog-centered">
              <div class="modal-content">
                <div id="creationsuccess">
                  <div class="modal-header">
                    <h3 style={{ color: "#000000" }}>🎊 🎉 Document created</h3>
                    <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                  </div>
                  <div class="modal-body">
                    <p>Your document has been created successfully. You can download it or send it by email by clicking on the appropriate button below (please select the recipient first).</p>
                    <div class="row">
                      <div class="mb-3">
                        <select class="form-select" id="conq-email">
                          <option></option>
                          <option>mmazraani@fidgi.ca</option>
                          <option>Sylvia.Dimitriou@data-axle.com</option>
                          <option>Sylvia.Dimitriou@data-axle.com</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div style={{ height: "71px" }} class="modal-footer" id="modalfooter">
                    <a type="button" href="" target="”_blank”" class="btn btn-primary" id="doc-download-conq">Download</a>
                    <button type="submit" class="btn btn-primary" id="doc-send-conq">
                      <span id="spinner-doc-send" class="visually-hidden">Loading...</span>
                      Submit the form
                    </button>
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <div class="row" style={{ width: "100%" }}>
                      <svg xmlns="http://www.w3.org/2000/svg" style={{ display: "none" }}>
                        <symbol id="check-circle-fill" fill="currentColor" viewBox="0 0 16 16">
                          <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"></path>
                        </symbol>
                        <symbol id="info-fill" fill="currentColor" viewBox="0 0 16 16">
                          <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"></path>
                        </symbol>
                        <symbol id="exclamation-triangle-fill" fill="currentColor" viewBox="0 0 16 16">
                          <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"></path>
                        </symbol>
                      </svg>
                      <div class="alert alert-danger d-flex align-items-center invisible" id="conqerroralert" role="alert">
                        <div id="conqerroralerttext">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default EditClientForm;
