import React, { useEffect, useState } from "react";
import { useLanguage } from "../../languageContext";
import translations from "../../translation.json";
import { useNavigate } from "react-router-dom";
import { ApiUrl } from "../../ApiUrl";
import { toast } from "react-toastify";
import TableView from "./components/tableView";
import CalendarView from "./components/calendarView";
import Loader from "../../loader";
import * as XLSX from "xlsx";
import { format } from "date-fns";
import CalendarView2 from "./components/calendarView2";
import AppointmentsFilterbar from "./components/appointmentsFilterbar";

const Appointments = ({
  isDarkMode,
  setDisplayComponent,
  setPrevDisplay,
  setIsLoading,
  users,
}) => {
  const { language } = useLanguage();
  const navigate = useNavigate();
  const [showTable, setShowTable] = useState(false);
  const [deleteStates, setDeleteStates] = useState();
  const [appointments, setAppointments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [eventId, setEventId] = useState(null);
  const token = localStorage.getItem("token");
  const [selectedAppointments, setSelectedAppointments] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [checkedFields, setCheckedFields] = useState([]);
  const [calendarData, setCalendarData] = useState(null);
  const [repOptions, setRepOptions] = useState([]);
  const [clients, setClients] = useState([]);
  const [filtersData, setFiltersData] = useState([]);
  const [dealershipDetail, setDealershipDetail] = useState("");
  const [eventDates, setEventDates] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [myEvents, setMyEvents] = useState(null);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [timeRange, setTimeRange] = useState({
    start_time: null,
    end_time: null,
  });

  const allfields = [
    {
      content: translations.commonFields.firstName[language],
      name: "first_name",
      type: "text",
      filterType: "",
      value: "",
    },
    {
      content: translations.commonFields.lastName[language],
      name: "last_name",
      type: "text",
      filterType: "",
      value: "",
    },
    {
      content:
        translations.createProspective.appointmentRepresentative[language],
      name: "appointment_rep_user_id",
      type: "dropdown",
      filterType: "",
      value: "",
    },
    {
      content: translations.createProspective.appointmentAgent[language],
      name: "appointment_agent_name",
      type: "text",
      filterType: "",
      value: "",
    },
    {
      content: translations.clients.table.appointmentEntryDate[language],
      name: "appointment_entry_date",
      type: "date",
      filterType: "",
      value: "",
    },
    {
      content: translations.clients.table.appointmentDate[language],
      name: "appointment_date",
      type: "date",
      filterType: "",
      value: "",
    },
    {
      content: translations.clients.table.appointmentTime[language],
      name: "appointment_time",
      type: "time",
      filterType: "",
      value: "",
    },

    {
      content: translations.createProspective.appointmentComment[language],
      name: "appointment_comment",
      type: "text",
      filterType: "",
      value: "",
    },
    {
      content: translations.commonFields.phone[language],
      name: "phone",
      type: "text",
      filterType: "",
      value: "",
    },
    {
      content: translations.createProspective.officeNumber[language],
      name: "office_phone",
      type: "text",
      filterType: "",
      value: "",
    },
    {
      content: translations.commonFields.mobileNumber[language],
      name: "mobile_phone",
      type: "text",
      filterType: "",
      value: "",
    },
    {
      content: translations.createProspective.confirmationStatus[language],
      name: "confirmation_status",
      type: "dropdown",
      filterType: "",
      value: "",
    },
    {
      content: translations.createProspective.confirmationAgent[language],
      name: "confirmation_agent_name",
      type: "text",
      filterType: "",
      value: "",
    },

    {
      content: translations.createProspective.confirmationNotes[language],
      name: "confirmation_notes",
      type: "text",
      filterType: "",
      value: "",
    },
    {
      content: translations.createProspective.confirmationStatusDate[language],
      name: "confirmation_status_entry_date",
      type: "date",
      filterType: "",
      value: "",
    },
    {
      content: translations.createProspective.visitStatus[language],
      name: "visit_status",
      type: "dropdown",
      filterType: "",
      value: "",
    },
    {
      content: translations.createProspective.visitStatusDate[language],
      name: "visit_status_entry_date",
      type: "date",
      filterType: "",
      value: "",
    },
    {
      content: translations.createProspective.prospective[language],
      name: "prospective_id",
      type: "dropdown",
      filterType: "",
      value: "",
    },
    {
      content: translations.createProspective.noShowSatus[language],
      name: "no_show_status",
      type: "dropdown",
      filterType: "",
      value: "",
    },
    {
      content:
        translations.createProspective.no_show_status_entry_date[language],
      name: "no_show_status_entry_date",
      type: "date",
      filterType: "",
      value: "",
    },
    {
      content: translations.createProspective.visitStatusUser[language],
      name: "visit_status_user_name",
      type: "text",
      filterType: "",
      value: "",
    },
    {
      content: translations.appointments.appointment[language],
      name: "no_show_user_name",
      type: "text",
      filterType: "",
      value: "",
    },
    {
      content: translations.createProspective.visitNotes[language],
      name: "visit_notes",
      type: "text",
      filterType: "",
      value: "",
    },
    {
      content: translations.createProspective.noShowNotes[language],
      name: "no_show_notes",
      type: "text",
      filterType: "",
      value: "",
    },
  ];
  const textOptions = [
    {
      label: translations.commonFields.queryFilters.contain[language],
      value: "like",
    },
    {
      label: translations.commonFields.queryFilters.notContain[language],
      value: "not like",
    },
    {
      label: translations.commonFields.queryFilters.equal[language],
      value: "=",
    },
    {
      label: translations.commonFields.queryFilters.notEqual[language],
      value: "!=",
    },
  ];
  const confirmationStatusOptions = [
    {
      label:
        translations.createProspective.confirmationSatusOptions.opt1[language],
      value: "CONFIRMED",
    },
    {
      label:
        translations.createProspective.confirmationSatusOptions.opt2[language],
      value: "WANTCB",
    },
    {
      label:
        translations.createProspective.confirmationSatusOptions.opt3[language],
      value: "MSG LEFT",
    },
    {
      label:
        translations.createProspective.confirmationSatusOptions.opt4[language],
      value: "NO ANSWER",
    },
    {
      label:
        translations.createProspective.confirmationSatusOptions.opt5[language],
      value: "SAVED",
    },
    {
      label:
        translations.createProspective.confirmationSatusOptions.opt6[language],
      value: "CANCEL",
    },
  ];
  const visitStatusOptions = [
    {
      label: translations.createProspective.visitStatusOptions.opt1[language],
      value: translations.createProspective.visitStatusOptions.opt1["en"],
    },
    {
      label: translations.createProspective.visitStatusOptions.opt2[language],
      value: translations.createProspective.visitStatusOptions.opt2["en"],
    },
    {
      label: translations.createProspective.visitStatusOptions.opt3[language],
      value: translations.createProspective.visitStatusOptions.opt3["en"],
    },
    {
      label: translations.createProspective.visitStatusOptions.opt4[language],
      value: translations.createProspective.visitStatusOptions.opt4["en"],
    },
    {
      label: translations.createProspective.visitStatusOptions.opt5[language],
      value: translations.createProspective.visitStatusOptions.opt5["en"],
    },
    {
      label: translations.createProspective.visitStatusOptions.opt6[language],
      value: translations.createProspective.visitStatusOptions.opt6["en"],
    },
  ];
  const noShowOptions = [
    {
      label: translations.createProspective.noShowStatusOptions.opt1[language],
      value: translations.createProspective.noShowStatusOptions.opt1["en"],
    },
    {
      label: translations.createProspective.noShowStatusOptions.opt2[language],
      value: translations.createProspective.noShowStatusOptions.opt2["en"],
    },
    {
      label: translations.createProspective.noShowStatusOptions.opt3[language],
      value: translations.createProspective.noShowStatusOptions.opt3["en"],
    },
    {
      label: translations.createProspective.noShowStatusOptions.opt4[language],
      value: translations.createProspective.noShowStatusOptions.opt4["en"],
    },
    {
      label: translations.createProspective.noShowStatusOptions.opt5[language],
      value: translations.createProspective.noShowStatusOptions.opt5["en"],
    },
    {
      label: translations.createProspective.noShowStatusOptions.opt6[language],
      value: translations.createProspective.noShowStatusOptions.opt6["en"],
    },
  ];

  const exportToExcel = () => {
    // Filter the selected appointments based on their IDs
    const selectedData = appointments
      .filter((appointment) => selectedAppointments.includes(appointment.id)) // Only include selected appointments
      .map((appointment) => ({
        Event_ID: appointment?.prospective?.private_event_id || "Null",
        // Event_Name: appointment.private_event.nom_vp || "Null",
        Appointment_ID: appointment?.id || "Null",
        First_Name: appointment.prospective?.first_name || "Null",
        Last_Name: appointment.prospective?.last_name || "Null",
        Phone: appointment.prospective?.phone || "Null",
        Make: appointment.prospective?.make || "Null",
        Model: appointment.prospective?.model || "Null",
        Year: appointment.prospective?.year || "Null",
        Appointment_Date: appointment?.appointment_date || "Null",
        Appointment_Time: appointment?.appointment_time || "Null",
        Appointment_Representative:
          appointment?.appointment_rep_user_name || "Null",
        Appointment_Comment: appointment?.appointment_comment || "Null",
        Confirmation_Status: appointment?.confirmation_status || "Null",
        Confirmation_Notes: appointment?.confirmation_notes || "Null",
        Confirmation_Notes: appointment?.confirmation_notes || "Null",
        // Visit_Status: appointment.appointments[0]?.visit_status || "Null",
        // Visit_Status_Entry_Date:
        //   appointment.appointments[0]?.visit_status_entry_date || "Null",
        // Created_At: appointment.appointments[0]?.created_at || "Null",
      }));

    // Generate the Excel sheet
    const worksheet = XLSX.utils.json_to_sheet(selectedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Selected Appointments");

    // Save the file
    XLSX.writeFile(
      workbook,
      `Appointments Report ${format(new Date(), "dd MMMM yyyy")}.xlsx`
    );
  };

  useEffect(() => {
    // Get the current URL
    var currentUrl = window.location.href;
    // Check if '=' is found and extract the text after it
    const equalsIndex = currentUrl.indexOf("=");
    if (equalsIndex !== -1) {
      const secondEqualsIndex = currentUrl.indexOf("=", equalsIndex + 1);

      if (secondEqualsIndex !== -1) {
        const lastParameterValue = currentUrl.substring(secondEqualsIndex + 1);
        setEventId(lastParameterValue);
      }
    }
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const res = await fetch(ApiUrl + "/api/user/appointment-index", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            private_event_id: eventId,
          }),
        });
        const data = await res.json();
        if (data.status === "success") {
          // const newData = data.private_events.data;

          setAppointments(data.appointments);

          setDeleteStates(data.appointments.map(() => false));

          // autoScrollMid();
        } else {
          toast.error(data.message);
        }
      } catch (err) {
        toast.error("Something went wrong");
      } finally {
        setLoading(false);
      }
    };
    const fetchCalendarData = async () => {
      try {
        setLoading(true);
        const res = await fetch(
          ApiUrl + "/api/user/calendar-dashboard/" + eventId,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = await res.json();
        if (data.status === "success") {
          // const newData = data.private_events.data;

          const events =
            data.appointmentsPerRep && data.appointmentsPerRep.length > 0
              ? data.appointmentsPerRep
                  .filter(
                    (item) => item.appointments && item.appointments.length > 0
                  ) // Filter items with non-empty appointments array
                  .flatMap((item) =>
                    item.appointments
                      .filter(
                        (appointment) =>
                          appointment.confirmation_status?.toLowerCase() !==
                          "cancel"
                      )
                      .map((appointment, index) => ({
                        id: appointment.id,
                        title:
                          appointment?.prospective?.first_name +
                          " " +
                          appointment?.prospective?.last_name,
                        start: getFormattedDate(
                          appointment.appointment_date,
                          appointment.appointment_time
                        ),
                        end: getFormattedDate(
                          appointment.appointment_end_date,
                          appointment.appointment_end_time
                        ),
                        data: {
                          appointment,
                        },
                        resourceId: appointment.appointment_rep_user_name,
                      }))
                  )
              : [];

          setCalendarData(data.appointmentsPerRep);
          setTimeRange({
            start_time: data?.start_time,
            end_time: data?.end_time,
          });
          setMyEvents(events);
          setDealershipDetail(data?.dealershipDetails);
        } else {
          toast.error(data.message);
        }
      } catch (err) {
        toast.error("Something went wrong");
      } finally {
        setLoading(false);
      }
    };
    const getFiltersData = async () => {
      try {
        const res = await fetch(ApiUrl + "/api/user/get-fillter", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        const data = await res.json();

        if (data.status === "success") {
          const filteredFields = allfields?.filter(
            (field) => data?.fillter[field.name] === 1
          );

          setCheckedFields(filteredFields);
        } else {
          toast.error(data.message);
        }
      } catch (error) {
        console.error("Error fetching brands", error);
        // Handle error or show a toast if needed
      }
    };
    const getExternalUsers = async () => {
      // setIsLoading(true);
      try {
        const res = await fetch(ApiUrl + "/api/user/external-user/" + eventId, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await res.json();
        if (data.status === "success") {
          const repData = data?.externalUsers?.length
            ? data?.externalUsers?.map((list) => ({
                label: list.appointment_rep_user_name,
                value: list.appointment_rep_user_name,
              }))
            : [];
          setRepOptions(repData);
        } else {
          toast.error(data.message);
        }
      } catch (err) {
        toast.error("Something went wrong");
      } finally {
        setIsLoading(false);
      }
    };
    const fetchClients = async () => {
      try {
        const res = await fetch(ApiUrl + `/api/user/prospective/${eventId}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await res.json();
        if (data.status === "success") {
          setClients(
            data?.prospectives?.map((prospect) => {
              return { label: prospect.first_name, value: prospect.id };
            })
          );
        } else {
          toast.error(data.message);
        }
      } catch (err) {
        toast.error("Something went wrong");
      } finally {
        setIsLoading(false);
      }
    };
    const getDates = async () => {
      try {
        // setIsLoading(true);
        const res = await fetch(ApiUrl + `/api/user/fillter-dates/${eventId}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await res.json();
        if (data.status === "success") {
          const newData =
            data.appointment_dates &&
            data.appointment_dates.length > 0 &&
            data.appointment_dates.map((item) => {
              return { label: item, value: item };
            });
          const currentDate2 =
            newData && newData.length > 0 && newData[0]?.label
              ? newData[0]?.label
              : new Date();
          // setCurrentDate(new Date(currentDate2));
          setCurrentDate(currentDate2);
          setSelectedDate(currentDate2);
          setEventDates(newData);
        } else {
          toast.error(data.message);
        }
      } catch (err) {
        toast.error("Something went wrong");
      } finally {
        setIsLoading(false);
      }
    };
    if (token && eventId) {
      fetchCalendarData();
      fetchData();
      // getFiltersData();
      getExternalUsers();
      fetchClients();
      getDates();
    }
  }, [eventId]);

  function getFormattedDate(appointment_date, appointment_time) {
    // Default time if appointment_time is missing or invalid
    const defaultTime = "09:00:00";
    const timeToUse =
      appointment_time && appointment_time.includes(":")
        ? appointment_time
        : defaultTime;

    // Parse the date and time components
    if (!appointment_date) {
      console.error("Missing appointment date");
      return null;
    }

    const [year, month, day] = appointment_date.split("-").map(Number);
    const [hours, minutes, seconds] = timeToUse.split(":").map(Number);

    // Construct startDate object
    const startDate = new Date(
      year,
      month - 1,
      day,
      hours,
      minutes,
      seconds || 0
    );

    if (isNaN(startDate)) {
      // console.error("Invalid startDate generated");
      return null;
    }

    // console.log("Generated Start Date:", startDate);
    return startDate;
  }

  const getFilteredEvents = async (filterPost) => {
    // setIsLoading(true);
    // setAllowScrollFetch(false);

    try {
      const res = await fetch(ApiUrl + "/api/user/appointment-index", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ private_event_id: eventId, ...filterPost }),
      });
      const data = await res.json();
      if (data.status === "success") {
        toast.success(data.message);
        setAppointments(data.appointments);

        setDeleteStates(data.appointments.map(() => false));
      } else {
        toast.error(data.message);
      }
    } catch (err) {
      // setIsLoading(false);
      toast.error("Something went wrong");
    } finally {
      // setAllowScrollFetch(true);
      setLoading(false);
    }
  };

  return (
    <div>
      {!loading && calendarData ? (
        <div className="container maincont">
          <div id="app" className="appcountainer2">
            <AppointmentsFilterbar
              isDarkMode={isDarkMode}
              allfields={allfields}
              checkedFields={checkedFields}
              setCheckedFields={setCheckedFields}
              textOptions={textOptions}
              repOptions={repOptions}
              confirmationStatusOptions={confirmationStatusOptions}
              visitStatusOptions={visitStatusOptions}
              noShowOptions={noShowOptions}
              clients={clients}
              filtersData={filtersData}
              setFiltersData={setFiltersData}
              // filterOption={filterOption}
              // setFilterOption={setFilterOption}
              // dealerOptions={dealerOptions}
              // repOptions2={repOptions2}
              // managers={managers}
              // packageOptions={packageOptions}
              // animatorOptions={animatorOptions}
              getFilteredEvents={getFilteredEvents}
              // weekOptions={weekOptions}
            />
            <div className="row align-items-center justify-content-between pb-4">
              <div className="col-7 d-flex gap-3 align-items-center">
                <div>
                  <h2 id="h1" className={`${isDarkMode ? "darkthead" : ""}`}>
                    {translations.eventView.buttons.calender[language]}
                    {" - "} {dealershipDetail?.name}
                    {": "}
                    {/* {translations.appointments.title[language]} */}
                    {dealershipDetail?.start_date}{" "}
                    {translations.commonWords.to[language]}{" "}
                    {dealershipDetail?.end_date}
                  </h2>
                </div>
              </div>
              <div className="col-5 gap-1 d-flex align-items-center justify-content-end">
                <div
                  className="btn-group"
                  role="group"
                  aria-label="Basic example"
                >
                  <button
                    type="button"
                    className="btn admin-btn btn-primary d-flex align-items-center gap-1"
                    // data-bs-toggle="modal"
                    // data-bs-target="#modalQuickAdd"
                    // fdprocessedid="2rud2c"
                    onClick={() => {
                      setDisplayComponent("event-view");

                      window.history.pushState(
                        {
                          id: "gallery",
                          randomData: window.Math.random(),
                        },
                        "title",
                        "/event-portal?page=event-view&event_id=" + eventId
                      );
                    }}
                  >
                    <i className="bi bi-speedometer"></i>{" "}
                    {translations.eventView.buttons.dashboard[language]}
                  </button>
                </div>
                <div
                  className="btn-group d-flex align-items-center gap-1"
                  role="group"
                  aria-label="Basic example"
                >
                  <button
                    type="button"
                    className=""
                    style={{
                      background: "#05cc9e",
                      // color: "white",
                      paddingBlock: "0.375rem",
                      paddingInline: "0.75rem",
                      borderRadius: "0.375rem",
                      borderColor: "#05cc9e",
                      borderStyle: "solid",
                    }}
                  >
                    <i className="bi bi-calendar2-range"></i>{" "}
                    {translations.eventView.buttons.calender[language]}
                  </button>
                </div>
                <div
                  className="btn-group d-flex align-items-center gap-1"
                  role="group"
                  aria-label="Basic example"
                >
                  <button
                    type="button"
                    className="btn admin-btn btn-primary"
                    onClick={() => {
                      setDisplayComponent("clients");

                      window.history.pushState(
                        {
                          id: "gallery",
                          randomData: window.Math.random(),
                        },
                        "title",
                        `/event-portal?page=clients&eventId=${eventId}`
                      );
                    }}
                  >
                    <i className="bi bi-people"></i>{" "}
                    {translations.forms.formFields.clients[language]}
                  </button>
                </div>
                <div
                  className="btn-group d-flex align-items-center gap-1"
                  role="group"
                  aria-label="Basic example"
                >
                  <button
                    type="button"
                    className="btn admin-btn btn-primary d-flex align-items-center gap-1"
                    // data-bs-toggle="modal"
                    // data-bs-target="#modalQuickAdd"
                    // fdprocessedid="2rud2c"
                    onClick={() => {
                      setDisplayComponent("copy-client");

                      window.history.pushState(
                        {
                          id: "gallery",
                          randomData: window.Math.random(),
                        },
                        "title",
                        `/event-portal?page=copy-client&eventId=${eventId}`
                      );
                    }}
                  >
                    <i className="bi bi-clipboard-plus"></i>{" "}
                    {translations.forms.formFields.copyClient[language]}
                  </button>
                </div>
                <div
                  className="btn-group d-flex align-items-center gap-1"
                  role="group"
                  aria-label="Basic example"
                >
                  <button
                    type="button"
                    className="btn admin-btn btn-primary"
                    // data-bs-toggle="modal"
                    // data-bs-target="#modalQuickAdd"
                    // fdprocessedid="2rud2c"
                    onClick={() => {
                      setDisplayComponent("eventReport");

                      window.history.pushState(
                        {
                          id: "gallery",
                          randomData: window.Math.random(),
                        },
                        "title",
                        "/event-portal?page=eventReport&event_id=" + eventId
                      );
                    }}
                  >
                    <i className="bi bi-file-earmark-bar-graph"></i>{" "}
                    {translations.viewEventReport.title[language]}
                  </button>
                </div>
              </div>
            </div>

            <div className="row align-items-center justify-content-between pb-4">
              <div className="col-4 d-flex gap-3 align-items-center">
                {!showTable && (
                  <div
                    className="input-group"
                    style={{ width: "calc(100% - 125px)" }}
                  >
                    <span
                      className="input-group-text"
                      style={{
                        backgroundColor: isDarkMode ? "transparent" : "",
                        color: isDarkMode ? "#ffffff" : "#000000",
                        border: isDarkMode
                          ? "1px solid #495057"
                          : "1px solid #dee2e6",
                      }}
                    >
                      Date
                    </span>

                    <select
                      class={`form-select ${isDarkMode ? "custom-select" : ""}`}
                      style={{
                        backgroundColor: isDarkMode ? "transparent" : "",
                        color: isDarkMode ? "#ffffff" : "#000000",
                        border: isDarkMode
                          ? "1px solid #495057"
                          : "1px solid #dee2e6",
                      }}
                      value={selectedDate}
                      onChange={(e) => {
                        setSelectedDate(e.target.value);
                        if (e.target.value && e.target.value !== "") {
                          // const newDate = myEvents.find()
                          // {console.log(e.target.value)}
                          // setCurrentDate(new Date(e.target.value));
                          setCurrentDate(e.target.value);
                        }
                      }}
                    >
                      <option
                        value=""
                        style={{
                          background: isDarkMode ? "#1B1F22" : "",
                          color: isDarkMode ? "#ffffff" : "",
                        }}
                      >
                        {translations.dropdown.select[language]}
                      </option>
                      {eventDates &&
                        eventDates.length > 0 &&
                        eventDates.map((date) => (
                          <option
                            key={date.value}
                            value={date.value}
                            style={{
                              background: isDarkMode ? "#1B1F22" : "",
                              color: isDarkMode ? "#ffffff" : "",
                            }}
                          >
                            {date.label}
                          </option>
                        ))}
                    </select>
                  </div>
                )}
                <div
                  className="row align-items-center"
                  // style={{ minHeight: "44px" }}
                >
                  <div className="col-3">
                    {selectedAppointments &&
                      selectedAppointments.length > 0 &&
                      showTable && (
                        <button
                          type="button"
                          className="client-btn d-flex align-items-center gap-2"
                          onClick={exportToExcel}
                        >
                          <i className="bi bi-upload"></i>
                          {translations.commonWords.export[language]}{" "}
                        </button>
                      )}
                  </div>
                </div>
              </div>
              <div className="col-8 gap-4 d-flex align-items-center justify-content-end">
                <div className="d-flex gap-2 align-items-center">
                  <p
                    style={{
                      fontWeight: showTable ? "" : "bold",
                      color: showTable ? "" : "#05cc9e",
                      fontSize: "24px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setShowTable(!showTable);
                      setSelectAll(false);
                      setSelectedAppointments([]);
                    }}
                  >
                    Cal |{" "}
                  </p>
                  <p
                    style={{
                      fontWeight: showTable ? "bold" : "",
                      color: showTable ? "#05cc9e" : "",
                      fontSize: "24px",
                      cursor: "pointer",
                    }}
                    onClick={() => setShowTable(!showTable)}
                  >
                    List
                  </p>
                </div>
                <div className="d-flex align-items-center gap-2">
                  <div
                    className="btn-group"
                    role="group"
                    aria-label="Basic example"
                  >
                    <button
                      type="button"
                      className="btn admin-btn btn-primary"
                      onClick={() => {
                        setDisplayComponent("add-appointment");

                        window.history.pushState(
                          {
                            id: "gallery",
                            randomData: window.Math.random(),
                          },
                          "title",
                          "/event-portal?page=add-appointment&event_id=" +
                            eventId
                        );
                      }}
                    >
                      <i className={`bi bi-clipboard-plus`}></i>{" "}
                      {translations.commonFields.addBtn[language]}
                    </button>
                  </div>
                  {showTable && (
                    <div
                      className="btn-group"
                      role="group"
                      aria-label="Basic example"
                    >
                      <button
                        id=""
                        type="button"
                        className="btn admin-btn btn-primary"
                        data-bs-target="#appointmentsFiltersidebar"
                        data-bs-toggle="offcanvas"
                        aria-bs-controls="offcanvasmain"
                      >
                        <i className="bi bi-search"></i>{" "}
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>

            {showTable ? (
              <TableView
                isDarkMode={isDarkMode}
                appointments={appointments}
                setDisplayComponent={setDisplayComponent}
                setDeleteStates={setDeleteStates}
                eventId={eventId}
                deleteStates={deleteStates}
                setLoading={setLoading}
                setAppointments={setAppointments}
                selectedAppointments={selectedAppointments}
                setSelectedAppointments={setSelectedAppointments}
                selectAll={selectAll}
                setSelectAll={setSelectAll}
                myEvents={myEvents}
                setMyEvents={setMyEvents}
                calendarData={calendarData}
                setIsLoading={setIsLoading}
                
              />
            ) : (
              // <CalendarView
              //   isDarkMode={isDarkMode}
              //   appointments={appointments}
              //   setAppointments={setAppointments}
              //   eventId={eventId}
              //   setIsLoading={setIsLoading}
              // />
              <>
                {calendarData && (
                  <CalendarView2
                    isDarkMode={isDarkMode}
                    calendarData={calendarData}
                    setCalendarData={setCalendarData}
                    myEvents={myEvents}
                    setMyEvents={setMyEvents}
                    eventId={eventId}
                    setIsLoading={setIsLoading}
                    setDisplayComponent={setDisplayComponent}
                    currentDate={currentDate}
                    setCurrentDate={setCurrentDate}
                    timeRange={timeRange}
                  />
                )}
              </>
            )}
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default Appointments;
